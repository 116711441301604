import { Component, OnInit, OnDestroy } from '@angular/core';
import { SimulatorService } from './simulator.service';
import { UserServiceService } from '../user-service.service';
import * as signalR from '@microsoft/signalr'
import { SimulatorData } from '../hub.interface';
import { environment } from '../../environments/environment.development'

@Component({
  selector: 'app-simulators',
  templateUrl: './simulators.component.html',
  styleUrls: ['./simulators.component.css']
})
export class SimulatorsComponent implements OnInit, OnDestroy {
  private hubConnection: signalR.HubConnection | undefined
  diagnosticDataList: SimulatorData[] = [];
  public userLocations: any;
  public userRoles: any;
  selectedLocation: any;

  public isLoading: boolean;
  constructor(private service: SimulatorService, private userService: UserServiceService) {
    this.isLoading = true

    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(environment.signalRSimHubAddress).build();
  }
    ngOnDestroy(): void {
      this.hubConnection?.invoke("DismissGroup", String(this.selectedLocation?.id), "").catch(err => console.error("Error sending group response:", err));
    }


  ngOnInit(): void {
    this.hubConnection?.start().then(() => {
      console.log("connections made")
      this.listenForMessages()
      /// YOU are now connected to the hub
      this.userService.getUsersRoles().subscribe(x => {
        this.userRoles = x
        console.log("roles", x)
      }, err => { console.log(err) }, () => {
      });
      this.userService.getLocations().subscribe(m => {
        this.userLocations = m
        this.selectedLocation = m[0]
        console.log("going to try and send a group request right now to  the group ", this.selectedLocation.id)
        this.hubConnection?.invoke("SendGroupSyncRequest", String(this.selectedLocation.id), "")
          .catch(err => console.error("Error sending group response:", err));
        console.log("locations", this.userLocations)
      }, err => { console.log(err) }, () => {
      })
      this.isLoading = false
    }).catch(err => console.error(err))
  }


  onLocationChange($event: any) {
    console.log("tryig to change location")
    if (this.selectedLocation.id === Number($event.target.value)) { return }
    this.hubConnection?.invoke("DismissGroup", this.selectedLocation.id, "").catch(err => console.error("Error sending group response:", err));
    let id = $event.target.value
    this.selectedLocation = this.userLocations.find((loc: any) => loc.id == Number(id))
    console.log("sending message", id)
    this.hubConnection?.invoke("SendGroupSyncRequest", id, "").catch(err => console.error("Error sending group response:", err));
  }


  private listenForMessages(): void {
    this.hubConnection?.on('ForwardDiagnosticData', (message: SimulatorData) => {
      console.log("got sim data")
      console.log(message)
      //this.diagnosticDataList.push(message)
      const simulator = this.selectedLocation.simulators.find((sim: any) => sim.id === message.id);
      console.log(simulator)
      if (simulator) {
        simulator.cpuUsage = message.cpuUsage;
        simulator.ramUsage = message.ramUsage;
        // Trigger change detection if necessary
      }
    });
  }

}
