<div class="container p-5" style="width:80vw;height:75vh">
  <a class="m-2" style="float:right">Cancel</a>
  <div class="row">
    <div class="col-12">
      <h5>CREATING MASS COMMUNICATION</h5>
    </div>
  </div>
  <input class="form-control" [(ngModel)]="communicationTitle" type="Text" placeholder="TITLE"/>
  <!-- Toolbar container -->
  <div id="toolbar">
    <!-- Font styling options -->
    <button class="ql-bold"></button>
    <button class="ql-italic"></button>
    <button class="ql-underline"></button>
    <button class="ql-strike"></button>

    <!-- Lists -->
    <button class="ql-list" value="ordered"></button>
    <button class="ql-list" value="bullet"></button>

    <!-- Block Level Styling -->
    <button class="ql-header" value="1"></button>
    <button class="ql-header" value="2"></button>
    <button class="ql-blockquote"></button>
    <button class="ql-code-block"></button>

    <!-- Link and Image -->
    <button class="ql-link"></button>
    <button class="ql-image"></button>

    <!-- Other options -->
    <button class="ql-script" value="sub"></button>
    <button class="ql-script" value="super"></button>
    <button class="ql-indent" value="-1"></button>
    <button class="ql-indent" value="+1"></button>
    <select class="ql-color"></select>
    <select class="ql-background"></select>
    <select class="ql-font"></select>
    <select class="ql-align"></select>
    <button class="ql-clean"></button>
  </div>


  <!-- Editor container -->
  <div id="editor">
    <!-- This is where your editor content will go -->
  </div>
  <button class="m-2 btn btn-sm xbtn-primary" (click)="save()">SAVE</button>
</div>
