import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LeagueDetailsComponent } from '../league-details/league-details.component';
import { EditLeagueEventDialogData } from '../../interfaces';
import { UtilityService } from '../../utility.service';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent {
  leagueEventForm: FormGroup;
  courses: any;
  constructor(private _snackBar: MatSnackBar, private dialogRef: MatDialogRef<LeagueDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditLeagueEventDialogData, private utilityService: UtilityService,
    private formBuilder: FormBuilder) {

    this.utilityService.getCourses().subscribe(x => {
      this.courses = x
    }, err => { console.log(err), () => { } })

    this.leagueEventForm = this.formBuilder.group({
      id: [this.data.id, Validators.required],
      leagueId: [this.data.leagueId, Validators.required],
      title: [this.data.title, Validators.required],
      description: [this.data.description, Validators.required],
      eventCourseId: [this.data.eventCourseId, Validators.required],
      eventCourseHoleList: [this.data.eventCourseHoleList, Validators.required],
      eventDate: [this.data.eventDate, Validators.required],
      eventCutOffDate: [this.data.eventCutOffDate, Validators.required],
      eventType: [this.data.eventType, Validators.required],
      eventOrderOfPlay: [this.data.eventOrderOfPlay, Validators.required],
    });
  }

  onSubmit() {
    this.dialogRef.close()
  }
}
