import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChallengesService } from '../challenges.service';

@Component({
  selector: 'app-create-challenge',
  templateUrl: './create-challenge.component.html',
  styleUrls: ['./create-challenge.component.css']
})
export class CreateChallengeComponent implements OnInit {
  public isLinear = false;
  imageSrcMap: { [key: string]: string | null } = {};
  challengeForm: FormGroup;
  courseSettingForm: FormGroup;
  teesForm: FormGroup;
  calibrationForm: FormGroup;
  dynamicForm: FormGroup;
  public isLoading: boolean;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private service: ChallengesService,
    private dialogRef: MatDialogRef<CreateChallengeComponent>,
    private _snackBar: MatSnackBar) {
    this.isLoading = true
    
    this.challengeForm = this.fb.group({
      challengeId: [0],
      challengeIdentifier: ['', Validators.required],
      title1: ['', Validators.required],
      title2: [''],
      description: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      numberOfShots: [3, [Validators.required, Validators.min(1)]],
      minimumParticipation: [1, [Validators.required, Validators.min(1)]],
      maximumParticipation: [5000, [Validators.required, Validators.min(1), Validators.max(100)]],
      maxAttemptsAllowed: [5000, [Validators.required, Validators.min(1), Validators.max(5000)]],
      holeIndex: [1, [Validators.required, Validators.min(1), Validators.max(5000)]],
      distance: [0, [Validators.required, Validators.min(1)]],
      challengeType: [0, Validators.required],
      challengeSubType: [0, [Validators.required, Validators.min(1)]],
      icon: ['', Validators.required],
    });
    this.courseSettingForm = this.fb.group({
      eventId: [0], // Default to empty string, replace with actual default value as needed
      fieldDistance: [3, [Validators.required]],
      greenDistance: [2, [Validators.required]],
      speedUnit: [3, [Validators.required]],
      swingView: [7, [Validators.required]],
      puttingView: [1, [Validators.required]],
      autoFlyBy: [1, [Validators.required]],
      xPlateGreen: [0, [Validators.required]],
      xPlateField: [0, [Validators.required]],
      autoBallFeed: [2, [Validators.required]],
      puttingPreview: [3, [Validators.required]],
      conceed: [3, [Validators.required]],
      obTee: [2, [Validators.required]],
      playableArea: [1, [Validators.required]],
      doublePar: [2, [Validators.required]],
      windOption: [1, [Validators.required]],
      clubSelectionInTheFridge: [2, [Validators.required]],
      swingInformation: [5, [Validators.required]],
      fieldTrajectory: [2, [Validators.required]],
      greenCondition: [1, [Validators.required]],
      puttingGrid: [2, [Validators.required]],
      rough: [90, [Validators.required]],
      greenSideBunker: [70, [Validators.required]],
      fairwayBunker: [90, [Validators.required]],
    });
    this.teesForm = this.fb.group({
      eventId: [0],
      maleTeeColor: [0, Validators.required],
      femaleTeeColor: [0, Validators.required],
      seniorTeeColor: [0, Validators.required]
    });
    this.calibrationForm = this.fb.group({
      eventId: [0], // Default to empty string, replace with actual default value as needed
      ballSpeed: [103, [Validators.required]], // Nullable int, default to null or ''
      puttingSpeed: [60, [Validators.required]], // Nullable int, default to null or ''
      backSpinOffset: [0, [Validators.required]], // Nullable int, default to null or ''
      sideSpinOffset: [0, [Validators.required]], // Nullable int, default to null or ''
      backSpinGain: [125, [Validators.required]], // Nullable int, default to null or ''
      sideSpinGain: [50, [Validators.required]], // Nullable int, default to null or ''
    });
    this.dynamicForm = this.fb.group({
      eventId: [0], // Validators can be adjusted as needed
      gravity: [100, [Validators.required]], // Assuming 0 is a sane default, adjust as necessary
      rollingResistance: [100, [Validators.required]],
      ballSpeedCompensation: [100, [Validators.required]],
      puttSpeedCompensation: [100, [Validators.required]],
      sliceSpinCompensation: [100, [Validators.required]],
      hookSpinCompensation: [100, [Validators.required]],
      backSpinCompensation: [100, [Validators.required]],
      forwardSpinCompensation: [100, [Validators.required]]
    });
  }

  ngOnInit() {
    //set data if you need to
    this.isLoading = false;
  }

  ngAfterViewInIt() {
    this.cd.detectChanges()
  }

  onFileSelect(event: Event): void {
    const element = event.target as HTMLInputElement;
    let file: File | null = element.files ? element.files[0] : null;
    if (file) {
      // Create an object URL for the selected file
      const imageUrl = URL.createObjectURL(file);
      const controlName = 'icon'; // replace with your actual control name if different

      // Cleanup any existing object URL to avoid memory leaks
      this.cleanupObjectURL(controlName);

      // Store the object URL in the map
      this.imageSrcMap[controlName] = imageUrl;

      // Update the form control with the file object
      this.challengeForm.get(controlName)?.setValue(file);
      this.cd.detectChanges();
    }
  }

  shouldShowSubTypeDiv() {
    const selectedValue = this.challengeForm.get('challengeIdentifier')?.value;
    return selectedValue.includes("Challenge_10_Shot_0");
  }

  onConfirm() {
    
    this.isLoading = true
    this.cd.detectChanges();
    // Extract the value of challengeIdentifier
    const challengeIdentifierValue = this.challengeForm.get('challengeIdentifier')?.value;
    const parts = challengeIdentifierValue.split('_');
    const extractedNumber = parts.pop(); // Get the last part
    const newChallengeIdentifier = parts.join('_'); // Join the remaining parts

    // Update the form values
    this.challengeForm.patchValue({
      challengeIdentifier: newChallengeIdentifier,
      holeIndex: Number(extractedNumber)
    });
    const formData = new FormData();
    const iconFile = this.challengeForm.get('icon')?.value;
    if (iconFile) {
      formData.append('icon', iconFile);
    } else {
      console.error('Icon file is undefined.');
    }
    const challengeDetails = {
      Challenge: this.challengeForm.value,
      CourseSettings: this.courseSettingForm.value,
      Calibration: this.calibrationForm.value,
      Dynamics: this.dynamicForm.value,
      //Tees: this.teesForm.value // Assuming you have a form for the tees
    };
    for (const [key, value] of Object.entries(challengeDetails)) {
      console.log(`${key}: `, value); // Verify the values are correct
      if (value) {
        formData.append(key, JSON.stringify(value));
      } else {
        console.error(`${key} is undefined.`);
      }
    }
    // Append properties of Challenge
    Object.entries(this.challengeForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Challenge.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Challenge.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.courseSettingForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`CourseSettings.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`CourseSettings.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.calibrationForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Calibration.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Calibration.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.dynamicForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Dynamics.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Dynamics.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.teesForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Tees.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Tees.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });
    console.log("trying to create the challenge now")

    this.service.postCreateChallenge(formData).subscribe(x => {
      if (x) {

        this._snackBar.open('Challenge created.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.dialogRef.close(x)
      }
    }, err => {
      console.log(err)
      this._snackBar.open('Error occured while trying to create challenge.', '', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: "failureNotice"
      });
      this.dialogRef.close()
    }, () => { })

     //You need to send 'formData' to your backend using your preferred method (e.g., HttpClient)
  }

  getObjectKeys(obj: any): string[] {

    return Object.keys(obj);
  }

  // Add this method to your Angular component class
  camelCaseToNormal(text: string): string {
    // First, use a regular expression to insert a space before all capital letters
    let result = text.replace(/([A-Z])/g, ' $1');
    // Then, capitalize the first letter of the resulting string
    result = result.charAt(0).toUpperCase() + result.slice(1);
    // Trim any leading spaces that might have been added by the regex replacement
    return result.trim();
  }

  getOptionTextByIdAndValue(selectId: string, value: string | number): string | undefined {
    // Use nativeElement to access the DOM API
    const selectElement: HTMLSelectElement = document.getElementById(selectId) as HTMLSelectElement;
    if (!selectElement) return undefined;

    const optionElement = selectElement.querySelector(`option[value="${value}"]`) as HTMLOptionElement;
    return optionElement ? optionElement.text : undefined;
  }

  isImageFile(controlName: string): boolean {
    const file = this.challengeForm.get(controlName)?.value;
    return file instanceof File && file.type.startsWith('image/');
  }

  cleanupObjectURL(controlName: string): void {
    const existingUrl = this.imageSrcMap[controlName];
    if (existingUrl) {
      URL.revokeObjectURL(existingUrl);
      this.imageSrcMap[controlName] = null; // Reset the URL in the map
    }
  }

  getImageSrc(controlName: string): string | null {
    return this.imageSrcMap[controlName] || null;
  }

  showing10Challenge(): boolean {
    const selectedChallenge = this.courseSettingForm.get('challengeIdentifier')?.value;
    // Check if the selected option contains "Challenge_10_Shot" in its value
    return selectedChallenge.includes('Challenge_10_Shot');
  }


  ngOnDestroy(): void {
    // Cleanup all object URLs when the component is destroyed
    for (const key of Object.keys(this.imageSrcMap)) {
      this.cleanupObjectURL(key);
    }
  }

}
