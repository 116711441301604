import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeriesComponent, } from '../series.component';
import { AppInviteRequest, CreateSeriesCompetitorRequest, CreateSeriesTournamentTeamRequest, XCupSignUpInfo } from '../../interfaces';
import { UserSearchService } from '../../user-search/user-search.service';
import { UserServiceService } from '../../user-service.service';
import { SeriesService } from '../series.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-series-sign-up',
  templateUrl: './series-sign-up.component.html',
  styleUrls: ['./series-sign-up.component.css']
  })
export class SeriesSignUpComponent implements OnInit{
  public isLoading: boolean;
  public emailBlurred: boolean = false;
  form: FormGroup;
  locations: any[]=[];
  selectedTournament: any;
  public tourId: number;
  playerFound: boolean = false;
  emailSearchHappening: boolean = false;
  showDropdown: boolean = false;
  individualPlayerHolder: any;
  previouslySearchedEmails: Set<string> = new Set();
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<SeriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: XCupSignUpInfo, private userSearchService: UserSearchService,
    private userService: UserServiceService, private seriesService: SeriesService,
    private _snackBar: MatSnackBar) {
    this.isLoading = true
    this.form = this.fb.group({
      tournamentId: this.data.focused,
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      userSyncId: ['', [Validators.required]],
      locationId: ['', [Validators.required]]
    });

    this.tourId = this.data.focused
    this.selectedTournament = this.data.selectedTournament;
    this.form.controls['tournamentId'].setValue(this.tourId)
  }

  ngOnInit(): void {
    console.log("my form,", this.form.valid)
    this.userService.getLocations().subscribe(x => {
      this.locations = x
      if (this.locations.length > 1) {
        this.showDropdown = true;
      } else if (this.locations.length == 1){
        this.form.controls['locationId'].setValue(this.locations[0].id)
      }
      this.isLoading = false
    }, err => { console.log(err) }, () => { });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(event: Event) {
    event.preventDefault();
    this.isLoading = true

    if (this.form.valid) {
            if (this.playerFound) {
              const playersEmail = this.form.controls['email'].value;
              //create series individual competitor
              const indy: CreateSeriesCompetitorRequest = {
                id: 0,
                userId: this.individualPlayerHolder ? this.individualPlayerHolder.systemUserProfile.userId : "",
                userSyncId: this.individualPlayerHolder ? this.individualPlayerHolder.oldId : 0,
                seriesId: this.data.seriesId,
                emailAddress: playersEmail,
                locationId: this.form.controls['locationId'].value,
                seriesTournamentId: this.data.selectedTournament.id,
                roundId: this.data.selectedTournament.tournamentRounds[0].id
              }
              this.seriesService.createSeriesCompetitor(indy).subscribe(x => {
                if (x) {
                  this.isLoading = false;
                  this._snackBar.open('X-Cup competitor created.', '', {
                    duration: 3000,
                    verticalPosition: "bottom",
                    horizontalPosition: "right",
                    panelClass: "successNotice"
                  });
                  this.closeDialog()
                }
              }, err => {
                this.isLoading = false
                console.log(err)
                this._snackBar.open('Sorry, an error has occured. Please ensure the email entered is associated with an X-Golf Mobile App account.', '', {
                  duration: 4000,
                  verticalPosition: "top",
                  horizontalPosition: "center",
                  panelClass: "failureNotice"
                });
                this.closeDialog()
              }, () => { })
            }
            else {
              this._snackBar.open('The email entered is not associated with an X-Golf Mobile App account. Please reenter or click Send Email to invite the customer to download and create their X-Golf Mobile App account.', '', {
                duration: 4000,
                verticalPosition: "top",
                horizontalPosition: "center",
                panelClass: "failureNotice"
              });
              this.isLoading = false
              return
            }
          }
          //you should first make sure the entered email is not already part of xcup
  }

  checkEmail(): void {
    this.emailSearchHappening = true
    var email = this.form.controls['email'].value
    if (email && email.trim() !== '') {
      this.userSearchService.getSearchResults(email).subscribe(result => {
        if (result && Object.keys(result).length !== 0) {
          this.playerFound = true;
          this.individualPlayerHolder = result
          this.emailSearchHappening = false
          this.form.controls['userSyncId'].setValue(result.oldId)
        }
        else {
          this.previouslySearchedEmails.add(email)
          this.playerFound = false;
          this.individualPlayerHolder = null;
          this.emailSearchHappening = false
        }
      }, error => {
        this.emailSearchHappening = false;
        this.playerFound = false;
        this.individualPlayerHolder = null;
      });
    }
  }

  wasEmailPreviouslySearchedAndNotFound(email: string): boolean {
    return this.previouslySearchedEmails.has(email);
  }

  displayInviteMessage(): boolean {
    return (!this.playerFound && !this.emailSearchHappening && this.form.controls['email'].valid && this.individualPlayerHolder === undefined)
  }

  sendAppInvite() {
    const playersEmail = this.form.controls['email'].value
    const location = this.form.controls['locationId'].value
    var request: AppInviteRequest = {
        email: playersEmail,
        locationId: location,
        userMakingRequest: ''
    }
    this.userService.postSendAppInvite(request).subscribe(x => {
      if (x) {
        this._snackBar.open('App invite emailed. Please have customer check their emails.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        
      }
    }, err => {
      this._snackBar.open('System had an error. Please try again shortly.', '', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: "failureNotice"
      });
    }, () => { })
  }




}
