<div *ngIf="!isLoading" class="container">
  <div class="row">
    <div class="col-sm-2">
      <a routerLink="/Leagues" class="btn btn-sm xbtn-primary">Back</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="locationLeagueForm" (ngSubmit)="onSubmit()">

        <div class="form-group">
          <label for="locationId">Location<span class="text-danger">*</span></label>
          <div class="input-group">
            <select id="locationId" class="form-control" formControlName="locationId">
              <option *ngFor="let location of locations" [value]="location.id">{{ location.name }}</option>
            </select>
            <div style="margin:6px;" data-toggle="tooltip" data-placement="right" title="Select the location for the league.">
              <div class="info-icon"></div>
            </div>
          </div>
        </div>


        <div class="form-group">
          <label for="title">Title<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" id="title" class="form-control" formControlName="title">
            <div style="margin:6px;" data-toggle="tooltip"
                 data-placement="right" title="Enter the Title of the League. An example might be 'Fall Monday Nigth League.'">
              <div class="info-icon"></div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="description">Description</label>
          <div class="input-group">
            <textarea id="description" class="form-control" formControlName="description"></textarea>
            <div style="margin:6px;" data-toggle="tooltip"
                 data-placement="right" title="Enter a description of the league. Include information that would be benifical to your customers. Number of team members per team, start date, location pro information...">
              <div class="info-icon"></div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="scoringDescription">Scoring Description</label>
          <div class="input-group">
            <textarea id="scoringDescription" class="form-control" formControlName="scoringDescription"></textarea>
            <div style="margin:6px;" data-toggle="tooltip"
                 data-placement="right" title="Enter a scoring description of the league. Use this to describe the scoring of the event to its participants. This information will be accessable to the players during the tournament for clarity into scoring.">
              <div class="info-icon"></div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="totalNumberOfWeeks">Total Number of Weeks<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="number" id="totalNumberOfWeeks" class="form-control" formControlName="totalNumberOfWeeks" required>
            <div style="margin:6px;" data-toggle="tooltip"
                 data-placement="right" title="Enter the total number of weeks for the entire league. Include competition rounds, playoffs, and finals.">
              <div class="info-icon"></div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="startDate">Start Date</label>
          <div class="input-group">
            <input type="datetime-local" id="startDate" class="form-control" formControlName="startDate">
            <div style="margin:6px;" data-toggle="tooltip"
                 data-placement="right" title="Enter the start Date & Time. This will be the date and time of the first event of a league.">
              <div class="info-icon"></div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input type="checkbox" id="isActive" class="form-check-input" formControlName="isActive">
            <label for="isActive" class="form-check-label">Is Active</label>

          </div>
        </div>

        <input type="submit" value="Submit" class="btn btn-md xbtn-primary" />
      </form>
    </div>
  </div>

</div>

<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:15%">
    <div class="col-sm-12">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
