import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeagueTeamListComponent } from '../../league-details/league-team-list/league-team-list.component';
import { CreateLeagueTeamRequest, LeagueData } from '../../../interfaces';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LeagueServiceService } from '../../league-service.service';

@Component({
  selector: 'app-create-league-team',
  templateUrl: './create-league-team.component.html',
  styleUrls: ['./create-league-team.component.css']
})
export class CreateLeagueTeamComponent implements OnInit {
  teamForm: FormGroup;
  team: any;
  // When Creating a Team for a League, the system is going to assume
  // the LeagueId will be passed in.

  constructor(private dialogRef: MatDialogRef<LeagueTeamListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LeagueData, private formBuilder: FormBuilder,
  public service: LeagueServiceService) {
    this.teamForm = this.formBuilder.group({
      teamName: [''],
      userEmails: this.formBuilder.array([])
    });
  }
    ngOnInit(): void {
      this.addUserEmail();
    }

  get userEmailControls() {
    return (this.teamForm.get('userEmails') as FormArray);
  }

  addUserEmail() {
    this.userEmailControls.push(new FormControl(''));
  }

  removeUserEmail(index: number) {
    this.userEmailControls.removeAt(index);
  }

  onSubmit() {
    // Handle form submission here
    const request: CreateLeagueTeamRequest = {
        leagueId: this.data.leagueId,
        teamName: this.teamForm.controls['teamName'].value,
        teamMembers: this.teamForm.controls['userEmails'].value
    }
    this.service.createLeagueTeam(request).subscribe(x => {
      this.team = x
      this.dialogRef.close(this.team)
    }, err => { console.log(err) }, () => { })
    
    
  }

}
