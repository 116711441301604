import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CloseRoundRequest, ScoreValidationRequest, TeamScorecardValidationRequest } from '../../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class RoundService {

  constructor(private http: HttpClient) { }

  getSimIndividualRoundResult(referenceId: number) {
    return this.http.get<any>('/api/round/getDetailedStrokesOfRound', { params: { syncId: referenceId } })
  }

  postValidateIndividualCompetitorsRound(request: ScoreValidationRequest) {
    return this.http.post('/api/tournament/validateIndividualTournamentRound', request)
  }

  postCloseRound(request: CloseRoundRequest) {
    return this.http.post<any>('api/round/closeRound', request)
  }

  postDiscardRound(referenceId: number) {
    const params = new HttpParams().set('referenceId', referenceId.toString());
    return this.http.post('api/round/discard', {}, { params });
  }

  postDiscardTeamRound(teamId: number, tournamentId: number, roundId: number) {
    const params = new HttpParams().set('teamId', teamId).set('tournamentId', tournamentId).set('roundId', roundId);
    return this.http.post('api/round/teamRoundDiscard', {}, { params });
  }

  uploadScoreCardImageForRound(data: FormData) {
    return this.http.post('/api/round/uploadTeamRoundScorecard', data);
  }

  getTeamRoundStrokes_Scramble(roundSyncId: number, gameSessionId: number, userIds: any) {
    let body = {
      roundSyncId: roundSyncId,
      gameSessionId: gameSessionId,
      userIds: userIds
    }
    return this.http.post<any>('/api/round/getTeamScrambleRoundStrokes', body)
  }

  getTeamRoundStrokes_BestBall(roundSyncId: number, gameSessionId: number, userIds: any) {
    let body = {
      roundSyncId: roundSyncId,
      gameSessionId: gameSessionId,
      userIds: userIds
    }
    return this.http.post<any>('/api/round/getTeamBestBallRoundStrokes', body)
  }

  postValidateTeamTournamentRound(request: TeamScorecardValidationRequest) {
    return this.http.post('/api/round/validateTeamRound', request)
  }

  postCreateTournamentRound(request: any) {
    return this.http.post('/api/tournament/createRound', request)
  }

  postUpdateTournamentRound(request: any) {
    return this.http.post('/api/tournament/editRound', request)
  }

}
