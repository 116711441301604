import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { UserServiceService } from '../user-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ChallengesService } from './challenges.service';
import { CreateChallengeComponent } from './create-challenge/create-challenge.component';
import { EditChallengeComponent } from './edit-challenge/edit-challenge.component';


@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.css']
})
export class ChallengesComponent implements OnInit {
  myControl = new FormControl();
  public challenges: any
  public isLoading: boolean = true

  filteredOptions: Observable<any[]> | undefined;
  paginatedChallenges: any[] = [];
  filteredChallenges: any[] | null = null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private cd: ChangeDetectorRef, public app: AppComponent,
    private userService: UserServiceService,
    public dialog: MatDialog,
    private service: ChallengesService) {
  }

  verifyAccess(roleRequired: string) {
    return this.userService.verifyUserRole(roleRequired);
  }

  ngOnInit(): void {
    this.isLoading = true
      this.service.getChallenges().subscribe(x => {
        this.challenges = x
        this.updatePagination()
        this.isLoading = false;
      }, err => { }, () => { })
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''), // Initialize the stream with an empty string
        map(value => this._filter(value)) // Apply the filtering logic
      );
    }


  private _filter(val: string): any[] {
    const filterValue = val.toLowerCase();

    // Ensure this.details.individualCompetitors is an array
    const locs = this.challenges || [];

    if (!val) {
      return locs;
    } else {
      return locs.filter((chal: any) =>
        chal.challenge.title.toLowerCase().includes(filterValue)
      );
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.updatePagination();
      });
    }
  }

  replaceEmptySpacesWithSingleSpace(inputString: string) {
    return inputString.replace(/\s+/g, ' ');
  }

  updatePagination() {
    const currentList = this.filteredChallenges || this.challenges;
    if (this.paginator && currentList) {
      console.log("trying to update the paginator now ", currentList)
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const endIndex = startIndex + this.paginator.pageSize;
      this.paginatedChallenges = currentList.slice(startIndex, endIndex);
      this.cd.markForCheck();
    }
  }

  resetSearch() {
    // Clear the search input
    this.myControl.setValue('');

    // Reset the filtered list
    this.filteredChallenges = null;

    // Reset the pagination to show the full list
    this.updatePagination();

    // If using a paginator, reset it to the first page
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  onNameSelect(event: MatAutocompleteSelectedEvent) {
    const selectedEmail = event.option.value;
    this.filterAndPaginateCompetitors(selectedEmail);
  }

  filterAndPaginateCompetitors(name: string) {
    this.filteredChallenges = this.challenges.filter((chal: any) =>
      chal.challenge.title.toLowerCase() === name.toLowerCase()
    );
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.updatePagination();
  }

  createChallenge() {
    this.isLoading = true;
    const dialogRef = this.dialog.open(CreateChallengeComponent)
    dialogRef.afterClosed().subscribe(x => {
        console.log("received back: ", x)

      if (x) {
        this.challenges.unshift(x)
        this.filteredChallenges?.push(x)
        this.updatePagination();
      }
      this.isLoading = false
    }, err => { console.log(err) }, () => {})

  }

  editChallenge(challenge: any) {
    console.log("editing ,", challenge)
    var dialogRef = this.dialog.open(EditChallengeComponent, { data: challenge })
    dialogRef.afterClosed().subscribe(x => {
      this.isLoading = true;
      if (x) {

        if (this.filteredChallenges) {
          const findex = this.filteredChallenges.findIndex((c: any) => c.challenge.id === x.challengeId);
          console.log("findex", findex)
          if (findex > -1) {
            this.filteredChallenges[findex].challenge = x;
          }
        }

        if (this.paginatedChallenges) {
          console.log("before px ", this.paginatedChallenges)
          const pindex = this.paginatedChallenges.findIndex((c: any) => c.challenge.id === x.challengeId);
          console.log("pindex", pindex)
          if (pindex > -1) {
            console.log("challenge", this.paginatedChallenges[pindex].challenge)
            console.log('x', x);
            this.paginatedChallenges[pindex].challenge.title = x.title1;
            this.paginatedChallenges[pindex].challenge.startDate = x.startDate;
            this.paginatedChallenges[pindex].challenge.endDate = x.endDate;
            this.paginatedChallenges[pindex].challenge.endDate = x.endDate;
            this.paginatedChallenges[pindex].challenge.challengeType = Number(x.challengeType);
          }
        }


      }
     
      //this.updatePagination()
      this.isLoading = false;
    }, err => {
      console.log(err);
    }, () => {

    });
  }

}
