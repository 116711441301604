import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { CreateLeagueTeamRequest } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class LeagueServiceService {
  constructor(private http: HttpClient) { }

  getLocalLeagues(locations: number[]): Observable<any> {
    return this.http.post<any>('/api/localleague/getLocationLeagues', locations)
  }

  createLocalLeague(request: any): Observable<any> {
    return this.http.post<any>('/api/localleague/createLocationLeague', request)
  }

  getLeague(leagueId: number): Observable<any> {
    return this.http.get<any>('/api/localleague/getLocationLeague', { params: {leagueId: leagueId.toString() } })
  }

  createLeagueEvent(request: any): Observable<any> {
    return this.http.post<any>('/api/localleague/createEvent', request)
  }

  updateLocalLeague(request: any): Observable<any> {
    return this.http.post<any>('/api/localleague/updateLocationLeague', request)
  }

  updateLeageEvent(request: any): Observable<any> {
    return this.http.post<any>('/api/localleague/updateEvent', request)
  }

  getLeagueTeams(leagueId: number): Observable<any> {
    return this.http.get<any>('/api/localleague/getLeagueTeams', { params: {leagueId: leagueId.toString() } })
  }

  createLeagueTeam(request: any): Observable<any> {
    return this.http.post<any>('/api/localleague/createLeagueTeam', request)
  }
}
