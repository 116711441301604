 import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { LeagueServiceService } from './league-service.service';
import { UtilityService } from '../utility.service';
import { MatDialog } from '@angular/material/dialog';
import { EditLeagueDialogData, LeagueData } from '../interfaces';
import { EditLeagueComponent } from './edit-league/edit-league.component';
import { LeagueTeamListComponent } from './league-details/league-team-list/league-team-list.component';

@Component({
  selector: 'app-league',
  templateUrl: './league.component.html',
  styleUrls: ['./league.component.css']
})
export class LeagueComponent implements OnInit {
  public isLoading: boolean;
  public localLeaguesAll: any;
  public localLeaguesToDisplay: any;
  public locations: any;
  public selectedLocation: string;
  constructor(public dialog: MatDialog, public app: AppComponent, public service: LeagueServiceService, public utilityService: UtilityService) {
    this.isLoading = true
    this.selectedLocation = ''
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.fetchLocalLeagues();
    }, 250);
  }

  fetchLocalLeagues(): void {
    this.locations = this.app.userProfile.assignedLocations
    const ids = this.locations.map((obj: any) => obj.id);
    this.service.getLocalLeagues(ids).subscribe(
      (x) => {
        this.localLeaguesAll = x;
        if (this.selectedLocation != '') {
          this.localLeaguesToDisplay = this.localLeaguesAll.filter((x: any) => x.locationId == this.selectedLocation);
        } else {
          this.localLeaguesToDisplay = this.localLeaguesAll.filter((x: any) => x.locationId === ids[0]);
        }
        this.isLoading = false;
      },
      err => {
        console.log(err);
        this.isLoading = false; // Handle any error that occurs
      }, () => { }
    );
  }

  onLocationChange($event: any) {
    this.selectedLocation = $event.target.value;

    this.localLeaguesToDisplay = this.localLeaguesAll.filter((x: any) => x.locationId.toString() === $event.target.value)
  }

  getLocalTime(utcDate: string): string {
    return this.utilityService.getLocalDateTime(utcDate);
  }

  openEditDialog(league: any): void {
    const editData: EditLeagueDialogData = {
      id: league.id,
      locationId: league.locationId,
      description: league.description,
      scoringDescription: league.scoringDescription,
      totalNumberOfWeeks: league.totalNumberOfWeeks,
      title: league.title,
      startDate: league.startDate,
      isActive: league.isActive
    }
    const dialogRef = this.dialog.open(EditLeagueComponent, {
      data: editData
    })

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = true
      setTimeout(() => {
        this.fetchLocalLeagues();
      }, 250);
    })
  }

  openLeagueTeamDirectory(league: any): void {
    const leagueData: LeagueData = {
      leagueId: league.id,
      locationId: league.locationId,
      leagueTitle: league.title,
      eventId: 0,
      eventRoundAssignment: ''
    }
    const dialogRef = this.dialog.open(LeagueTeamListComponent, { data: leagueData })
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = true
      setTimeout(() => {
        this.fetchLocalLeagues();
      }, 250);
    })
  }

}
