import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserSearchService } from './user-search.service';
import { MatSnackBar } from '@angular/material/snack-bar';
interface Hole {
  key: number;
  value: number;
}
@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit {
  public resultToDisplay: boolean;
  public searching: boolean;
  public result: any;
  public playerPreviousRounds: any;
  searchForm: FormGroup;
  public estimateTool_Selected: any;
  public estimateTool_Total: number = 0;
  public estimateTool_TotalPar: number = 0;
  public estimateTool_TotalDif: number = 0;
  panelOpenState = false;
  public estimateTool_Adjusted: number = 0;
  public estimateTool_Rate: number = 1;
  public estimateTool_Final: number = 0;
  constructor(public app: AppComponent, private formBuilder: FormBuilder, private service: UserSearchService, private snackBar: MatSnackBar) {
    this.resultToDisplay = false;
    this.searching = false;
    this.searchForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
    this.estimateTool_Selected = []
  }

    ngOnInit(): void {
        
    }

  submitForm(): void {
    this.searching = true;
    if (this.searchForm.valid) {
      // Perform your search logic or submit the form
      this.resultToDisplay = false
      this.result = null

      this.service.getSearchResults(this.searchForm.controls["email"].value).subscribe(x => {
        if (x) {
          this.resultToDisplay = true
          this.result = x
          const filteredRounds = x.previousRounds.filter((round: any) => {
            const holesPlayed = Object.keys(round.roundScores).length;
            return holesPlayed === 9 || holesPlayed === 18;
          });
          this.playerPreviousRounds = filteredRounds
           this.searchForm.controls["email"].setValue(x.email)
        } else {
          this.searchForm.controls["email"].setValue('')
          this.resultToDisplay = false
          this.snackBar.open('No users with that email found in X-Golf system.', '', {
            duration: 3000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
            panelClass: "failureNotice"
          });
        }
        
      }, err => { console.log(err) }, () => {
        this.searching = false;
      })


     



    } else {
      // Handle form validation errors if needed
    }
  }

  updateEstTotal(pRound: any, event: any) {
    if (event.target && event.target.checked) {
      this.estimateTool_Selected.push(pRound)
      this.estimateTool_TotalPar += pRound.roundPlayedParTotal
      this.estimateTool_Total += pRound.roundScoreTotal;
      this.estimateTool_TotalDif = this.estimateTool_Total - this.estimateTool_TotalPar;
      this.estimateTool_Final = this.estimateTool_TotalDif / this.estimateTool_Selected.length
      this.estimateTool_Adjusted = this.estimateTool_Final * (this.estimateTool_Rate / 100);
    } else {
      this.estimateTool_Selected.pop(pRound)
      this.estimateTool_TotalPar -= pRound.roundPlayedParTotal
      this.estimateTool_Total -= pRound.roundScoreTotal;
      this.estimateTool_TotalDif = this.estimateTool_Total - this.estimateTool_TotalPar;
      this.estimateTool_Final = this.estimateTool_TotalDif / this.estimateTool_Selected.length
      this.estimateTool_Adjusted = this.estimateTool_Final * (this.estimateTool_Rate / 100);
    }
  }

  formatLabel(value: number): string {
      return value + '%';
  }

  onInputChange(event: any) {
    var val = event.target.value / 100;
    this.estimateTool_Rate = val;
    this.estimateTool_Adjusted = (this.estimateTool_Final * val);
  }

  sortHoleScores(round: { [key: string]: number }): Hole[] {
    return Object.entries(round)
      .map(([key, value]) => ({ key: +key, value }))
      .sort((a, b) => a.key - b.key);
  }

  getParForHole(holeNumber: number, course: { courseHoles: { holeNumber: number; par: number; }[] }): number {
    const hole = course.courseHoles.find(h => h.holeNumber === holeNumber);
    return hole ? hole.par : 0;
  }

  getLocalTime(created: string) {
    let utcDate = new Date(created.replace(' ', 'T') + 'Z');
    return utcDate.toLocaleString()
  }

  //getTotalPar(course: any): number {
  //  let totalPar = 0;
  //  for (const hole of course.courseHoles) {
  //    totalPar += hole.par;
  //  }
  //  return totalPar;
  //}

  calculateRoll(carry:number, distance:number) {
    return distance - carry;
  }

}
