import { AfterViewInit, Component, ElementRef, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DirectCommunicationRequest } from '../interfaces';
import { CommunicationService } from './communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var Quill: any;

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements AfterViewInit {
  private quill: any
  public details: any
  constructor(private el: ElementRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DirectCommunicationRequest,
    private dialogRef: MatDialogRef<CommunicationComponent>, private service: CommunicationService, private _snackBar: MatSnackBar) {
    this.details = this.data
  }

  ngAfterViewInit() {
    const editorElement = this.el.nativeElement.querySelector('#editor');
    if (editorElement) {
      this.quill = new Quill(editorElement, {
        theme: 'snow'
      });
    } else {
      console.warn("Editor element not found in the DOM.");
    }

  }

  closeWindow() {
    this.dialogRef.close(null)
  }

  sendCommunication() {
    if (this.quill) {
      this.data.message = this.quill.root.innerHTML
      this.service.postSendPlayerMessage(this.data).subscribe(x => {
        if (x) {
          this._snackBar.open('Message sent.', '', {
            duration: 3000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
            panelClass: "successNotice"
          });
          this.dialogRef.close();
        }
        this.dialogRef.close();
      }, err => {
        this._snackBar.open('Sorry, error occured. Please try again later or report if issue continues.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "failureNotice"
        });
        this.dialogRef.close();
      }, () => { })
    } else {
      console.warn("Quill editor instance not found.");
    }
  }

}
