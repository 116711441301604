import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { CommunicationsService } from '../communications.service';
import { UtilityService } from '../../utility.service';
import Quill from 'quill';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-communications-composer',
  templateUrl: './communications-composer.component.html',
  styleUrls: ['./communications-composer.component.css']
})
export class CommunicationsComposerComponent implements OnInit {
  private quill: any;
  public details: any;
  public communicationTitle!: string;

  constructor(private el: ElementRef, private service: CommunicationsService, private utilityService: UtilityService, private router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    const editorElement = this.el.nativeElement.querySelector('#editor');
    if (editorElement) {
      this.quill = new Quill(editorElement, {
        theme: 'snow',
        modules: {
          toolbar: {
            container: '#toolbar',  // Selector for toolbar container
            handlers: {
              image: this.imageHandler.bind(this),
            }
          }
        }
      });
      this.setContent(this.getHtmlFromDatabase());
    } else {
      console.warn("Editor element not found in the DOM.");
    }
  }

  imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    document.body.appendChild(input); // append it to the body

    input.onchange = () => {
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        this.uploadImage(file);
      }
      document.body.removeChild(input); // optionally remove it from the body after getting the file
    };

    input.click();
  }

  uploadImage(file: File) {
    this.utilityService.uploadImageToBlob(file).subscribe(
      (x) => {
        // Get the returned URL and insert image into the editor
        const range = this.quill.getSelection(true);
        console.log(range);
        this.quill.insertEmbed(range.index, 'image', x.url);
      },
      (err) => { console.log(err); },
      () => { }
    );
  }

  private getHtmlFromDatabase(): string {
    // Simulate fetching HTML content from the database
    return "";
  }

  private setContent(htmlContent: string): void {
    if (this.quill) {
      // The 'dangerouslyPasteHTML' method will paste HTML and convert it into Quill's internal format
      this.quill.clipboard.dangerouslyPasteHTML(htmlContent);
      // Alternatively, you can use the 'innerHTML' property
      // this.quill.root.innerHTML = htmlContent;
    }
  }

  save() {
    console.log("the title should be ", this.communicationTitle);
    let rawHtml = this.quill.root.innerHTML;
    console.log(rawHtml);  // This will print the raw HTML content of the editor

    this.service.createPublicCommunication(rawHtml, this.communicationTitle).subscribe(
      (x) => {
        console.log(x);
        this._snackBar.open('Communication created.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.router.navigate(['/']);

      },
      (err) => {
        console.log(err);
        this._snackBar.open('Sorry, error occured. Please try again later or report if issue continues.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "failureNotice"
        });      },
      () => { }
    );
  }
}
