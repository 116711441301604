import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TournamentService } from './tournament.service';

@Component({
  selector: 'app-tournament',
  templateUrl: './tournament.component.html',
  styleUrls: ['./tournament.component.css']
})
export class TournamentComponent implements OnInit {
  public isLoading: boolean;
  details: any;

  constructor(private route: ActivatedRoute, private service: TournamentService) {
    this.isLoading = true
  }

    ngOnInit() {
      this.route.paramMap.subscribe(params => {
        var id = Number(params.get('tournamentId'))
        console.log(id)
        this.service.getTournament(id).subscribe(x => {
          this.details = x
          console.log(this.details)
          this.isLoading = false
        }, err => { console.log(err) }, () => { })

        this.service.getTournamentTeamResults(id).subscribe(x => {
          
          console.log(this.details)
        }, err => { console.log(err) }, () => { })
      }, err => { console.log(err) }, () => { });
  }


}
