import { Component } from '@angular/core';

@Component({
  selector: 'app-league-team',
  templateUrl: './league-team.component.html',
  styleUrls: ['./league-team.component.css']
})
export class LeagueTeamComponent {

}
