import { Component } from '@angular/core';

@Component({
  selector: 'app-card-validation',
  templateUrl: './card-validation.component.html',
  styleUrls: ['./card-validation.component.css']
})
export class CardValidationComponent {

}
