<div *ngIf="isLoading" class="container-fuild">
  <mat-progress-bar style="color:#007041" mode="query"></mat-progress-bar>
</div>

<div [class.loading-opacity]="isLoading" class="container-fluid p-2 morrisContainer" style="overflow-y:scroll;">
  <div class="row center">
    <h1 class="disply-3" style="text-align:center">EDIT CHALLENEGE</h1>
  </div>
  <mat-stepper headerPosition="bottom" #stepper class="m-3">
    <mat-step [stepControl]="challengeForm">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="challengeForm" class="m-2">
            <input type="hidden" formControlName="challengeId" id="challengeId">
            <ng-template matStepLabel>
              CHALLENGE
            </ng-template>
            <div class="form-group m-2">
              <label for="challengeIdentifier">CHALLENGE</label>
              <select formControlName="challengeIdentifier" id="challengeIdentifier" class="form-select">
                <option value="$1039_Challenge_10_Shot_0">
                  10 Shot Challenge Pebble Beach
                </option>
                <option value="$4001_Challenge_10_Shot_0">
                  10 Shot Challenge St Andrews
                </option>
                <option value="$1005_MaunaOcean_15">
                  15 @ Daon Ocean
                </option>
                <option value="$1011_PineBeach_15">
                  15 @ Aran Beach
                </option>
                <option value="$1020_Bethpage_17">
                  17 @ Bethpage
                </option>
                <option value="$1022_HabourTown_14">
                  14 @ Harbour Town
                </option>
                <option value="$1023_BayHill_17">
                  17 @ Bay Hill
                </option>
                <option value="$1025_Pebblebeach_7">
                  7 @ Pebble
                </option>
                <option value="$1028_Innisbrook_17">
                  17 @ Innisbrook
                </option>
                <option value="$1030_PGA_17">
                  17 @ PGA National
                </option>
                <option value="$1034_EASTLAKE_18">
                  18 @ East Lake
                </option>
                <option value="$1040_JapanMemorial_17">
                  17 @ Japan Memorial
                </option>
                <option value="$1042_RoyalPines_16">
                  16 @ Royal Pines
                </option>
                <option value="$1044_OakMont_8">
                  8 @ Oakmont
                </option>
              </select>
            </div>
            <div class="form-group m-2">
              <label for="challengeSubType">10 SHOT CHALLENGE TYPE</label>
              <select formControlName="challengeSubType" id="challengeSubType" class="form-select text-center">
                <option value="0">STANDARD</option>
                <option value="1">ONECLUB</option>
                <option value="2">PRO</option>
              </select>
            </div>
            <div class="form-group m-2">
              <label for="challengeType">CHALLENGE TYPE</label>
              <select formControlName="challengeType" id="challengeType" class="form-select">
                <option value="0">
                  NATIONAL CHALLENGE
                </option>
                <option value="1">
                  MINI GAME
                </option>
              </select>
            </div>
            <div class="form-group mb-2">
              <label for="title1">TITLE 1</label>
              <input class="form-control" formControlName="title1" type="text" id="title1">
            </div>

            <div class="form-group mb-2">
              <label for="title2">TITLE 2</label>
              <input class="form-control" formControlName="title2" type="text" id="title1">
            </div>


            <div class="form-group mb-2">
              <label for="distance">DISPLAYED DISTANCE</label>
              <input class="form-control" formControlName="distance" type="number" id="distance" />
            </div>
            <div class="form-group mb-2">
              <label for="description">DESCRIPTION</label>
              <textarea class="form-control" rows="5" formControlName="description"></textarea>
            </div>
            <div class="row">
              <div class="col-3">
                <div class="form-group mb-2">
                  <label for="startDate">START</label>
                  <input class="form-control" formControlName="startDate" type="date" id="startDate">
                </div>
              </div>
              <div class="col-3">
                <div class="form-group mb-2">
                  <label for="endDate">END</label>
                  <input class="form-control" formControlName="endDate" type="date" id="endDate">
                </div>
              </div>
              <div class="col-1">
                <div class="form-group mb-2">
                  <label for="minimumParticipation">MIN # OF PLAYERS</label>
                  <input class="form-control" formControlName="minimumParticipation" type="number" id="minimumParticipation">
                </div>
              </div>
              <div class="col-1">
                <div class="form-group mb-2">
                  <label for="maximumParticipation">MAX # OF PLAYERS</label>
                  <input class="form-control" formControlName="maximumParticipation" type="number" id="maximumParticipation">
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="numberOfShots"># OF SHOTS</label>
              <input class="form-control" formControlName="numberOfShots" type="number" id="numberOfShots">
            </div>
            <div class="form-group">
              <label>ICON</label>
              <!--<input type="file" id="imageUpload" (change)="onFileSelect($event)" class="form-control">-->
              <img src="{{iconURLHolder}}" class="img-thumbnail" style="max-height:450px" />
              <div class="form-group">
                <label for="imageUpload">ICON</label>
                <input type="file" formControlName="icon" id="icon" (change)="onFileSelect($event)" class="form-control">
              </div>
            </div>

            <div class="m-3" style="display:flex;justify-content:flex-end">
              <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
            </div>
          </form>
        </div>
      </div>
    </mat-step>
    <!--<mat-step [stepControl]="teesForm">
      <h2 class="display-6">TEES</h2>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="teesForm" class="m-2">
            <input type="hidden" formControlName="eventId" id="teesForm_eventId">
            <ng-template matStepLabel>TEES</ng-template>
            <div class="form-group m-2">
              <label for="maleTeeColor">MALE</label>
              <select formControlName="maleTeeColor" id="maleTeeColor" class="form-select">
                <option value="0" style="color:white;background-color:blue">
                  Blue
                </option>
                <option value="1">
                  White
                </option>
                <option value="2" style="background-color:red;">
                  Red
                </option>
                <option value="3" style="background-color:gold">
                  Gold
                </option>
              </select>
            </div>
            <div class="form-group m-2">
              <label for="femaleTeeColor">FEMALE</label>
              <select formControlName="femaleTeeColor" id="femaleTeeColor" class="form-select">
                <option value="0" style="color:white;background-color:blue">
                  Blue
                </option>
                <option value="1">
                  White
                </option>
                <option value="2" style="background-color:red;">
                  Red
                </option>
                <option value="3" style="background-color:gold">
                  Gold
                </option>
              </select>
            </div>
            <div class="form-group m-2">
              <label for="seniroTeeColor">SENIOR</label>
              <select formControlName="seniorTeeColor" id="seniorTeeColor" class="form-select">
                <option value="0" style="color:white;background-color:blue">
                  Blue
                </option>
                <option value="1">
                  White
                </option>
                <option value="2" style="background-color:red;">
                  Red
                </option>
                <option value="3" style="background-color:gold">
                  Gold
                </option>
              </select>
            </div>

            <div class="m-3" style="display:flex;justify-content:flex-end">
              <button mat-button matStepperPrevious class="btn btn-outline-light">Back</button>
              <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
            </div>

          </form>
        </div>
      </div>
    </mat-step>-->

    <mat-step [stepControl]="courseSettingForm">
      <form [formGroup]="courseSettingForm" class="m-2">
        <input type="hidden" formControlName="eventId" id="courseSettingForm_eventId">
        <ng-template matStepLabel>COURSE</ng-template>
        <div class="container">
          <div class="row">
            <div class="col-4">

              <h2 class="display-6">UNIT</h2>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="fieldDistance">Field Distance</label>
                    <select formControlName="fieldDistance" id="fieldDistance" class="form-select border text-center">
                      <option value="1">
                        Meter
                      </option>
                      <option value="2">
                        Feet
                      </option>
                      <option value="3">
                        Yard
                      </option>
                    </select>
                  </div>

                  <!-- Green Distance -->
                  <div class="form-group">
                    <label for="greenDistance">Green Distance</label>
                    <select id="greenDistance" formControlName="greenDistance" class="form-select border text-center">
                      <option value="1">
                        Meter
                      </option>
                      <option value="2">
                        Feet
                      </option>
                      <option value="3">
                        Yard
                      </option>
                    </select>

                  </div>

                  <!-- Speed Unit -->
                  <div class="form-group">
                    <label for="speedUnit">Speed Unit</label>
                    <select id="speedUnit" formControlName="speedUnit" class="form-select border text-center">
                      <option value="2">km/h</option>
                      <option value="1">m/s</option>
                      <option value="3">mph</option>
                    </select>

                  </div>
                </div>
              </div>

              <h2 class="display-6">VIEW MODE</h2>
              <div class="card">
                <div class="card-body">
                  <!-- Swing View -->
                  <div class="form-group">
                    <label for="swingView">Swing View</label>
                    <select id="swingView" formControlName="swingView" class="form-select border text-center">
                      <option value="1">
                        Basic
                      </option>
                      <option value="2">
                        Dynamic
                      </option>
                      <option value="3">
                        Hit1
                      </option>
                      <option value="4">
                        Hit2
                      </option>
                      <option value="5">
                        Hit3
                      </option>
                      <option value="6">
                        Hit4
                      </option>
                      <option value="7">
                        Hit5
                      </option>
                    </select>
                  </div>

                  <!-- Putting View -->
                  <div class="form-group">
                    <label for="puttingView">Putting View</label>
                    <select id="puttingView" formControlName="puttingView" class="form-select border text-center">
                      <option value="1">
                        Putting1
                      </option>
                      <option value="2">
                        Putting2
                      </option>
                      <option value="3">
                        Putting3
                      </option>
                      <option value="4">
                        Putting4
                      </option>
                    </select>

                  </div>

                  <!-- Auto Fly By -->
                  <div class="form-group">
                    <label for="autoFlyBy">Auto Fly By</label>
                    <select id="autoFlyBy" formControlName="autoFlyBy" class="form-select text-center">
                      <option value="1">On</option>
                      <option value="2">Off</option>
                    </select>
                  </div>

                </div>
              </div>

              <h2 class="display-6">DEVICE OPTION</h2>
              <div class="card">
                <div class="card-body">
                  <!-- X Plate Green -->
                  <div class="form-group">
                    <label for="xPlateGreen">X Plate Green</label>

                    <select type="number" id="xPlateGreen" formControlName="xPlateGreen" class="form-select text-center">
                      <option value="0">None</option>
                      <option value="1">15%</option>
                      <option value="2">30%</option>
                      <option value="3">50%</option>
                      <option value="4">100%</option>
                    </select>
                  </div>

                  <!-- X Plate Field -->
                  <div class="form-group">
                    <label for="xPlateField">X Plate Field</label>
                    <select id="xPlateField" formControlName="xPlateField" class="form-select text-center">
                      <option selected value="0">None</option>
                      <option value="1">15%</option>
                      <option value="2">30%</option>
                      <option value="3">50%</option>
                      <option value="4">100%</option>
                    </select>
                  </div>

                  <!-- Auto Ball Feed -->
                  <div class="form-group">
                    <label for="autoBallFeed">Auto Ball Feed</label>
                    <select id="autoBallFeed" formControlName="autoBallFeed" class="form-select text-center">
                      <option value="1">On</option>
                      <option value="2">Off</option>
                    </select>
                  </div>

                  <!-- Putting Preview -->
                  <div class="form-group">
                    <label for="puttingPreview">Putting Preview</label>
                    <select id="puttingPreview" formControlName="puttingPreview" class="form-select text-center">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">Unlimited</option>
                      <option value="4">Preview on > 5m</option>
                    </select>
                  </div>

                </div>
              </div>

            </div>
            <div class="col-4">
              <h2 class="display-6">LOCAL RULE</h2>
              <div class="card">
                <div class="card-body">
                  <!-- Concede -->
                  <div class="form-group">
                    <label for="conceed">Concede</label>
                    <select id="conceed" formControlName="conceed" class="form-select text-center">
                      <option value="1">
                        2ft
                      </option>
                      <option value="2">
                        4ft
                      </option>
                      <option value="3">
                        7ft
                      </option>
                    </select>
                  </div>

                  <!-- OB Tee -->
                  <div class="form-group">
                    <label for="obTee">OB Tee</label>
                    <select id="obTee" formControlName="obTee" class="form-select text-center">
                      <option value="1">On</option>
                      <option value="2">Off</option>
                    </select>
                  </div>

                  <!-- Playable Area -->
                  <div class="form-group">
                    <label for="playableArea">Playable Area</label>
                    <select id="playableArea" formControlName="playableArea" class="form-select text-center">
                      <option value="1">
                        Almost All
                      </option>
                      <option value="2">
                        Side
                        Hole
                      </option>
                      <option value="3">
                        Current Hole
                      </option>
                    </select>
                  </div>

                  <!-- Double Par -->
                  <div class="form-group">
                    <label for="doublePar">Double Par</label>
                    <select id="doublePar" formControlName="doublePar" class="form-select text-center">
                      <option value="1">On</option>
                      <option value="2">Off</option>
                    </select>
                  </div>

                  <!-- Wind Option -->
                  <div class="form-group">
                    <label for="windOption">Wind Option</label>
                    <select id="windOption" formControlName="windOption" class="form-select text-center">
                      <option value="1">
                        Calm
                      </option>
                      <option value="2">
                        Light
                      </option>
                      <option value="3">
                        Moderate
                      </option>
                      <option value="4">
                        Strong
                      </option>
                      <option value="5">
                        Random
                      </option>
                    </select>
                  </div>

                  <!-- Club Selection In The Fridge -->
                  <div class="form-group">
                    <label for="clubSelectionInTheFridge">Club Selection In The Fridge</label>
                    <select id="clubSelectionInTheFridge" formControlName="clubSelectionInTheFridge" class="form-select text-center">
                      <option value="1">Effective Range</option>
                      <option value="2">Putter</option>
                      <option value="3">Wedge</option>
                    </select>
                  </div>
                </div>
              </div>

              <h2 class="display-6">DISPLAY</h2>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="swingInformation">Swing Information</label>
                    <select id="swingInformation" formControlName="swingInformation" class="form-select text-center">
                      <option value="5">None</option>
                      <option value="1">3s</option>
                      <option value="2">6s</option>
                      <option value="3">Continue</option>
                      <option value="4">Camera</option>
                    </select>
                  </div>

                  <!-- Field Trajectory -->
                  <div class="form-group">
                    <label for="fieldTrajectory">Field Trajectory</label>
                    <select id="fieldTrajectory" formControlName="fieldTrajectory" class="form-select text-center">
                      <option value="1">
                        Last
                      </option>
                      <option value="2">
                        All
                      </option>
                    </select>
                  </div>

                  <!-- Green Condition -->
                  <div class="form-group">
                    <label for="greenCondition">Green Condition</label>
                    <select id="greenCondition" formControlName="greenCondition" class="form-select text-center">
                      <option value="1">
                        Normal
                      </option>
                      <option value="2">
                        Soft
                      </option>
                      <option value="3">
                        Wet
                      </option>
                      <option value="4">
                        Firm
                      </option>
                    </select>
                  </div>

                  <!-- Putting Grid -->
                  <div class="form-group">
                    <label for="puttingGrid">Putting Grid</label>
                    <select id="puttingGrid" formControlName="puttingGrid" class="form-select text-center">
                      <option value="1">ClassicGrid</option>
                      <option value="2">Grid</option>
                      <option value="3">Animated Grid</option>
                      <option value="4">Contour</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <h2 class="display-6">BALL SPEED APPLICATION RATE</h2>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="rough">Rough</label>
                    <select id="rough" formControlName="rough" class="form-select text-center">
                      <option value="90">90%</option>
                      <option value="85">85%</option>
                      <option value="80">80%</option>
                    </select>
                  </div>


                  <div class="form-group">
                    <label for="greenSideBunker">Green Side Bunker</label>
                    <select id="greenSideBunker" formControlName="greenSideBunker" class="form-select text-center">
                      <option value="70">70%</option>
                      <option value="60">60%</option>
                      <option value="50">50%</option>
                    </select>
                  </div>

                  <!-- Playable Area -->
                  <div class="form-group">
                    <label for="fairwayBunker">Fairway Bunker</label>
                    <select id="fairwayBunker" formControlName="fairwayBunker" class="form-select text-center">
                      <option value="90">90%</option>
                      <option value="85">85%</option>
                      <option value="80">80%</option>
                    </select>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>

        <div class="m-3" style="display:flex;justify-content:flex-end">
          <button mat-button matStepperPrevious class="btn btn-outline-light">Back</button>
          <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="calibrationForm">
      <h2 class="display-6">CALIBRATIONS</h2>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="calibrationForm" class="m-2">
            <input type="hidden" formControlName="eventId" id="calibrationForm_eventId">
            <ng-template matStepLabel>CALIBRATION</ng-template>

            <div class="form-group">
              <label for="ballSpeed">Ball Speed</label>
              <input type="number" id="ballSpeed" formControlName="ballSpeed" class="form-control" />
            </div>

            <div class="form-group">
              <label for="puttingSpeed">Putting Speed</label>
              <input type="number" id="puttingSpeed" formControlName="puttingSpeed" class="form-control" />
            </div>

            <div class="form-group">
              <label for="backSpinOffset">Back Spin Offset</label>
              <input type="number" id="backSpinOffset" formControlName="backSpinOffset" class="form-control" />
            </div>

            <div class="form-group">
              <label for="sideSpinOffset">Side Spin Offset</label>
              <input type="number" id="sideSpinOffset" formControlName="sideSpinOffset" class="form-control" />
            </div>

            <div class="form-group">
              <label for="backSpinGain">Back Spin Gain</label>
              <input type="number" id="backSpinGain" formControlName="backSpinGain" class="form-control" />
            </div>

            <div class="form-group">
              <label for="sideSpinGain">Side Spin Gain</label>
              <input type="number" id="sideSpinGain" formControlName="sideSpinGain" class="form-control" />
            </div>

            <div class="m-3" style="display:flex;justify-content:flex-end">
              <button mat-button matStepperPrevious class="btn btn-outline-light">Back</button>
              <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
            </div>
          </form>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="dynamicForm">
      <h2 class="display-6">DYNAMICS</h2>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="dynamicForm" class="m-2">
            <input type="hidden" formControlName="eventId" id="dynamicForm_eventId">
            <ng-template matStepLabel>DYNAMICS</ng-template>
            <div class="form-group">
              <label for="gravity">Gravity</label>
              <input type="number" id="gravity" formControlName="gravity" class="form-control" />
            </div>

            <div class="form-group">
              <label for="rollingResistance">Rolling Resistance</label>
              <input type="number" id="rollingResistance" formControlName="rollingResistance" class="form-control" />
            </div>

            <div class="form-group">
              <label for="ballSpeedCompensation">Ball Speed Compensation</label>
              <input type="number" id="ballSpeedCompensation" formControlName="ballSpeedCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="puttSpeedCompensation">Putt Speed Compensation</label>
              <input type="number" id="puttSpeedCompensation" formControlName="puttSpeedCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="sliceSpinCompensation">Slice Spin Compensation</label>
              <input type="number" id="sliceSpinCompensation" formControlName="sliceSpinCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="hookSpinCompensation">Hook Spin Compensation</label>
              <input type="number" id="hookSpinCompensation" formControlName="hookSpinCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="backSpinCompensation">Back Spin Compensation</label>
              <input type="number" id="backSpinCompensation" formControlName="backSpinCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="forwardSpinCompensation">Forward Spin Compensation</label>
              <input type="number" id="forwardSpinCompensation" formControlName="forwardSpinCompensation" class="form-control" />
            </div>

            <div class="m-3" style="display:flex;justify-content:flex-end">
              <button mat-button matStepperPrevious class="btn btn-outline-light">Back</button>
              <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
            </div>
          </form>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>CONFIRM</ng-template>
      <p>You are about to submit the following details:</p>
      <div class="container">
        <div class="row">
          <div class="col-6 gx-0 gy-0">

            <div class="p-0 border bg-light">

              <h5>Challenge:</h5>
              <ul>
                <ng-container *ngFor="let controlName of getObjectKeys(challengeForm.controls)">
                  <li *ngIf="controlName !== 'challengeId' && (controlName === 'challengeIdentifier' || controlName === 'challengeType')">
                    {{ camelCaseToNormal(controlName) }}: <b>{{ getOptionTextByIdAndValue(controlName, challengeForm.get(controlName)?.value) }}</b>
                  </li>
                  <li *ngIf="controlName !== 'challengeId' && controlName !== 'challengeIdentifier' && controlName !== 'challengeType'">
                    {{ camelCaseToNormal(controlName) }}:
                    <ng-container *ngIf="isImageFile(controlName); else notImage">
                      <!-- Bind to the stored image URL -->
                      <img [src]="imageSrcMap[controlName]" class="img-thumbnail">
                    </ng-container>
                    <ng-template #notImage><b>{{ challengeForm.get(controlName)?.value }}</b></ng-template>
                  </li>

                </ng-container>
              </ul>
            </div>


            <div class="p-0 border bg-light">
              <h5>Tee:</h5>
              <ul>
                <ng-container *ngFor="let controlName of getObjectKeys(teesForm.controls)">
                  <li *ngIf="controlName !== 'eventId'">
                    {{ camelCaseToNormal(controlName) }}: <b>{{ teesForm.get(controlName)?.value }}</b>
                  </li>
                </ng-container>
              </ul>

            </div>
            <div class="p-0 border bg-light">
              <h5>Course:</h5>
              <ul>
                <ng-container *ngFor="let controlName of getObjectKeys(courseSettingForm.controls)">
                  <li *ngIf="controlName !== 'eventId'">
                    {{ camelCaseToNormal(controlName) }}: <b>{{ getOptionTextByIdAndValue(controlName, courseSettingForm.get(controlName)?.value) }}</b>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>

          <div class="col-6  gx-0 gy-0">
            <div class="">
              <div class="p-0 border bg-light">
                <h5>Calibration Details:</h5>
                <ul>
                  <ng-container *ngFor="let controlName of getObjectKeys(calibrationForm.controls)">
                    <li *ngIf="controlName !== 'eventId'">
                      {{ camelCaseToNormal(controlName) }}: <b>{{ calibrationForm.get(controlName)?.value }}</b>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="">
              <div class="p-0 border bg-light">
                <h5>Dynamic Details:</h5>
                <ul>
                  <ng-container *ngFor="let controlName of getObjectKeys(dynamicForm.controls)">
                    <li *ngIf="controlName !== 'eventId'">
                      {{ camelCaseToNormal(controlName) }}: <b>{{ dynamicForm.get(controlName)?.value }}</b>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="m-3" style="display:flex;justify-content:flex-end">
        <button class="btn btn-outline-light" mat-button matStepperPrevious>Back</button>
        <button class="btn btn-outline-primary" mat-button (click)="onConfirm()">SAVE</button>
      </div>
    </mat-step>
  </mat-stepper>

</div>

