import { Component, ElementRef, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommunicationsService } from './communications.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserServiceService } from '../user-service.service';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommunicationsComposerComponent } from './communications-composer/communications-composer.component';
import { CommentRequest } from '../interfaces';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.css']
})
export class CommunicationsComponent implements OnInit {
  public isLoading: boolean;
  public communications: any[] = [];
  newCommentContent: { [key: number]: string } = {}; // Dictionary to store comment content for each post

  constructor(private el: ElementRef, private sanitizer: DomSanitizer,
    private service: CommunicationsService, private _snackBar: MatSnackBar, private userService: UserServiceService,
    public dialog: MatDialog
  ) {
    this.isLoading = true
    this.service.getAllPublicCommunications().subscribe(x => {
      this.communications = x
      this.isLoading = false
    }, err => { console.log(err) }, () => { })
  }

  ngOnInit(): void {
    this.loadCommunications();
  }

  loadCommunications(): void {
    // Call your service to load communications
    this.isLoading = true;
    this.service.getAllPublicCommunications().subscribe(
      (data: any[]) => {
        this.communications = data;
        this.isLoading = false;
      },
      (err) => {
        console.error('Error loading communications:', err);
        this.isLoading = false;
      }
    );
  }

  verifyAccess(roleRequired: string) {
    return this.userService.verifyUserRole(roleRequired);
  }

  sanitizeHtml(content: string) {
    // Explicitly trust the given HTML content as safe
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  createCommunication() {
    const dialogRef = this.dialog.open(CommunicationsComposerComponent)
    dialogRef.afterClosed().subscribe(result => {
      window.location.reload()
    })
  }


  addComment(postId: number): void {
    const content = this.newCommentContent[postId]?.trim();
    if (content) {
      const newComment = {
        communicationId: postId,
        commentText: content,
        createdOn: new Date().toISOString(),
        createdBy: '' // Replace with actual user ID
      };
      this.service.addCommentToCommunication(newComment).subscribe(
        (response: any) => {
          this._snackBar.open('Comment posted.', '', {
            duration: 3000,
            verticalPosition: "bottom",
            horizontalPosition: "right",
            panelClass: "successNotice"
          });
          const post = this.communications.find(p => p.communicationId === postId);
          console.log("found post for updating ", post)
          if (post) {
            post.comments.push({
              content: newComment.commentText,
              postedBy: 'You (a moment ago)', // Replace with actual current user data
              postedOn: new Date()
            });
            this.newCommentContent[postId] = ''; // Clear the input field
          }
        },
        (error) => {
          this._snackBar.open('Error occured while posting comment, sorry. Please try again shortly.', '', {
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: "failureNotice"
          });
          console.error('Error occurred while adding comment:', error);
        }
      );
    }
  }
  sanitizeHTML(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}

