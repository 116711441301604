<div *ngIf="!isLoading" class="container-fluid p-2">
  <div class="row center">
    <div class="col-md-12">
      <h1 class="display-6" style="text-align:center;">MANUAL SCORECARD ENTRY</h1>
      <span class="lead" style="text-align:center;">{{details.teamName}}</span>
    </div>
  </div>
  <div class="row center p-2">
    <form class="row" [formGroup]="form" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label>SCORECARD IMAGE</label>
        <input type="file" (change)="onFileChange($event)" class="form-control" required />
      </div>

      <button class="btn btn-sm xbtn-primary" type="submit" [disabled]="details.roundVerified">CREATE</button>
      <!--if details.netOnly we need to make sure the handicaps are entered before allowing submit-->
    </form>
  </div>
</div>



<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>

