<div *ngIf="!isLoading; else loading" class="container-fluid p-1">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <label>ADVANCE TO:</label>
    <div class="row">
      <div class="col-4">
        <mat-radio-group (change)="onRadioChange()" formControlName="selectedRound">
          <div *ngFor="let round of data.nextRounds" class="row p-1">
            <mat-radio-button [value]="round?.id" class="col-2"></mat-radio-button>
            <label class="form-check-label col-10 mt-1">
              {{ round.courseName }} - <strong>{{ round.title }}</strong>
            </label>
          </div>

          <!-- Player's Choice Section -->
          <div class="row p-1">
            <mat-radio-button class="col-2" [value]="-1"></mat-radio-button>
            <label class="form-check-label col-10 mt-1">TEAM WILL DECIDE</label>
          </div>
        </mat-radio-group>
      </div>
      <div class="col-6" *ngIf="golfers && golfers.length">
        <!-- Golfer Details Section -->
        <ng-container formArrayName="golfers">
          <table>
            <tr>
              <th>TEAM</th>
              <th>HANDICAPS</th>
            </tr>
            <tr *ngFor="let golfer of golfers.controls; index as i" [formGroupName]="i">
              <td><label>{{ golfer.get('golferEmail')?.value }}</label></td>
              <td>
                <input formControlName="handicap" />
              </td>
            </tr>
          </table>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <button class="btn btn-sm xbtn-primary" type="submit">ADVANCE</button>
    </div>
  </form>
</div>

<ng-template #loading>
  <div class="container">
    <div class="row" style="display:flex;justify-content:center;margin-top:13%">
      <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  </div>
</ng-template>

