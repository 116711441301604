import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppInviteRequest, CreateSeriesCompetitorRequest, CreateSeriesTournamentTeamRequest, XCupSignUpInfo } from '../../../interfaces';
import { UserSearchService } from '../../../user-search/user-search.service';
import { UserServiceService } from '../../../user-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TournamentService } from '../tournament.service';

@Component({
  selector: 'app-team-sign-up',
  templateUrl: './team-sign-up.component.html',
  styleUrls: ['./team-sign-up.component.css']
})
export class TeamSignUpComponent implements OnInit{
  public isLoading: boolean;
  form: FormGroup;
  locations: any[] = [];
  selectedTournament: any;
  public tourId: number;
  emailStatus: any[] = [];
  emailSearchingStatus: boolean[] = [];
  showDropdown: boolean = false;
  individualPlayerHolder: any;
  lastCheckedEmails: { [index: number]: string } = {};

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<TeamSignUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: XCupSignUpInfo, private userSearchService: UserSearchService,
    private userService: UserServiceService, private service: TournamentService,
    private _snackBar: MatSnackBar) {
    this.isLoading = true
    this.form = this.fb.group({
      tournamentId: this.data.focused,
      emails: this.fb.array([]),
      userSyncIds: this.fb.array([]),
      teamName: [''],
      locationId: ['']
    });

    this.tourId = this.data.focused
    this.selectedTournament = this.data.selectedTournament;
    const emailArray = this.form.get('emails') as FormArray;
    const idArray = this.form.get('userSyncIds') as FormArray;
    for (let i = 0; i < this.selectedTournament.maxTeamMemberCount; i++) {
      emailArray.push(this.fb.control('', i < this.selectedTournament.minTeamMemberCount ? [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] : null));
      idArray.push(this.fb.control('', i < this.selectedTournament.minTeamMemberCount ? Validators.required : null))
      this.emailSearchingStatus[i] = false;
    }
    if (this.data.selectedTournament.isTeamEvent) {
      this.form.get('teamName')?.setValidators(Validators.required);
    } else {
      emailArray.push(this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]));
      this.emailSearchingStatus[emailArray.length - 1] = false;
    }

  }

    ngOnInit(): void {
      this.form.controls['tournamentId'].setValue(this.tourId)
      this.userService.getLocations().subscribe(x => {
        this.locations = x
        if (this.locations.length > 1) {
          this.showDropdown = true;
        } else if (this.locations.length == 1) {
          this.form.controls['locationId'].setValue(this.locations[0].id)
        }
        this.isLoading = false
      }, err => { console.log(err) }, () => { });
    }
  get emailsArray(): FormArray {
    return this.form.get('emails') as FormArray
  }

  get idsArray(): FormArray {
    return this.form.get('userSyncIds') as FormArray
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (this.form.valid) {
      this.isLoading = true
        const teamRequest: CreateSeriesTournamentTeamRequest = {
            teamName: this.form.controls['teamName'].value,
            locationId: +this.form.controls['locationId'].value,
            emails: (this.form.get('emails') as FormArray).controls.map(control => control.value),
            userSyncIds: (this.form.get('userSyncIds') as FormArray).controls.map(control => control.value).map(id => id === '' ? 0 : id),
            id: 0,
            seriesTournamentId: this.data.selectedTournament.id,
            userMakingRequest: ''
      }
        console.log(teamRequest)
        this.service.createTeam(teamRequest).subscribe(x => {
          if (x) {
            this._snackBar.open('Team created.', '', {
              duration: 3000,
              verticalPosition: "bottom",
              horizontalPosition: "right",
              panelClass: "successNotice"
            });
          }
          this.dialogRef.close();
        }, err => {
          console.log(err)
          this._snackBar.open('Error:' + err, '', {
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: "failureNotice"
          });
          this.dialogRef.close();
        }, () => { })

      

    }

  }

  checkEmail(index: number): void {
    let email = this.emailsArray.controls[index].value;
    if (email && email.trim() !== '') {
      //this.lastCheckedEmails[index] = email;
      this.emailSearchingStatus[index] = true;
      this.userSearchService.getSearchResults(email).subscribe(result => {
        console.log("UserSearchResult", result)
        this.emailSearchingStatus[index] = false;
        if (result && Object.keys(result).length !== 0) {
          this.emailStatus[index] = true;
          this.individualPlayerHolder = result
          this.idsArray.controls[index].setValue(result.oldId);
        } else {
          this.emailStatus[index] = false;
          this.individualPlayerHolder = null;
          this.idsArray.controls[index].setValue("");
          this.emailsArray.controls[index].setErrors({ 'notFound': true });

        }
      }, error => {
        this.emailSearchingStatus[index] = false;
        this.emailStatus[index] = false;
        this.individualPlayerHolder = null;
        this.idsArray.controls[index].setValue("");
        this.emailsArray.controls[index].setErrors({ 'error': true });

      });
    } else {
      this.emailStatus[index] = undefined;
      this.emailSearchingStatus[index] = false;
      this.individualPlayerHolder = null;
      if (this.idsArray.controls[index]) {
        this.idsArray.controls[index].setValue("");

      }
      this.emailsArray.controls[index].reset(); // Or set a custom error like 'required'.

    }
  }

  allEmailsValid(): boolean {
    // Assuming emailStatus is an array where each element is true if the corresponding email is valid.
    return this.emailStatus.every(status => status === true);
  }

  sendAppInvite(i: number) {
    const emailArray = this.form.get('emails') as FormArray;
    const playersEmail = emailArray.at(i).value
    const location = this.form.controls['locationId'].value
    var request: AppInviteRequest = {
      email: playersEmail,
      locationId: location,
      userMakingRequest: ''
    }
    this.userService.postSendAppInvite(request).subscribe(x => {
      if (x) {
        this._snackBar.open('App invite emailed. Please have customer check their emails.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });

      }
    }, err => {
      this._snackBar.open('System had an error. Please try again shortly.', '', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: "failureNotice"
      });
    }, () => { })
  }

  searchesInProgress(): boolean {
    return Object.values(this.emailSearchingStatus).some(status => status === true);
  }


}
