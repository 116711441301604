import { Injectable, signal } from '@angular/core';
import * as signalR from '@microsoft/signalr'

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {
  private hubConnection: signalR.HubConnection | undefined
  constructor() { }

  public startConnection = (url: string): Promise<void> => {
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(url).build();
    return this.hubConnection.start().then(() => {
      console.log("connections made")
      
    }).catch(err => console.error(err))
  }

  public sendMessage = (methodName: string, message: any): void => {
    if (this.hubConnection) {
      const jsonMessage = JSON.stringify(message)
      this.hubConnection.invoke(methodName, jsonMessage).catch(err =>  console.log(err))
    }
  }

  public addMessageListener = (methodName: string, callback: (message: any) => void): void => {
    if (this.hubConnection) {
      this.hubConnection.on(methodName, (data: any) => {
        const message = JSON.parse(data)
        callback(message)
      })
    }
  }
}
