import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeamScorecardImageUploadRequest } from '../../../../interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoundService } from '../round.service';

@Component({
  selector: 'app-team-scorecard-upload',
  templateUrl: './team-scorecard-upload.component.html',
  styleUrls: ['./team-scorecard-upload.component.css']
})
export class TeamScorecardUploadComponent {
  public isLoading: boolean;
  public displayNameOptions: boolean;
  public nameOptions: string;
  form: FormGroup;
  tournamentRound: any; // Replace with your type
  tournamentRequest: any; // Replace with your type
  public details: any;

  constructor(private cd: ChangeDetectorRef, private _snackBar: MatSnackBar, private fb: FormBuilder, private roundService: RoundService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TeamScorecardImageUploadRequest,
    private dialogRef: MatDialogRef<TeamScorecardUploadComponent>) {
    this.displayNameOptions = false
    this.nameOptions = ''
    this.isLoading = true
    this.form = this.fb.group({
      competitorId: ['', [Validators.required]],
      tournamentRoundId: ['', [Validators.required]],
      referenceId: ['', [Validators.required]],
      file: ['', [Validators.required]],
      teamName: ['', [Validators.required]]
    });
    this.details = data
  }

  ngOnInit(): void {
    this.form.controls['competitorId'].setValue(this.data.competitorId)
    this.form.controls['tournamentRoundId'].setValue(this.data.tournamentRoundId)
    this.form.controls['referenceId'].setValue(this.data.referenceId);
    if (this.details.teamName != null) {
      this.form.controls['teamName'].setValue(this.data.teamName);
      this.displayNameOptions = false;
    } else {
      this.displayNameOptions = true
      this.nameOptions = this.data.teamNameVariations.join(', ')
    }
    this.isLoading = false;
    this.cd.markForCheck();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        file: file
      });
    }
  }

  onSubmit() {
    this.isLoading = true
    this.cd.markForCheck();
    const formData = new FormData();

    formData.append('competitorId', this.data.competitorId !== null && this.data.competitorId !== undefined ? this.data.competitorId.toString() : '0');
    formData.append('roundId', this.data.tournamentRoundId.toString());
    formData.append('referenceId', this.data.referenceId.toString());
    formData.append('emails', this.data.emails.join(','));
    formData.append('file', this.form.get('file')?.value);
    formData.append('location', this.data.location)
    formData.append('userSyncIds', this.data.userSyncIds.join(','))
    formData.append('tournamentId', this.data.seriesTournamentId.toString())
    formData.append('simProvidedScore', this.data.simProvidedScore.toString())
    formData.append('seriesId', this.data.seriesId.toString())
    formData.append('teamName', this.form.controls['teamName'].value)
    formData.append('manualEntry', "false")

    this.roundService.uploadScoreCardImageForRound(formData).subscribe((response: any) => {
      console.log("upload resoponse: ", response)
      if (response) {
        response.teamName = this.form.controls['teamName'].value
        this._snackBar.open('Image uploaded successfully.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
      }
      this.dialogRef.close(response)
    }, err => { console.log("upload error:", err) }, () => { });
  }

}
