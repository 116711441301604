<div class="container-fluid">
  <div class="row center">
    <h1 class="disply-3" style="text-align:center">CREATE SERIES</h1>
  </div>
  <form [formGroup]="seriesForm" (ngSubmit)="onSubmit()">
    <div class="col-6">
      <div class="form-group">
        <label>Series Title</label>
        <input class="form-control" formControlName="title" id="title" placeholder="Enter series title">
      </div>
    </div>

    <div class="col-6">
      <div class="form-group mb-2">
        <label for="startDate">START</label>
        <input class="form-control" formControlName="startDate" type="date" id="startDate">
      </div>
    </div>
    <div class="col-6">
      <div class="form-group mb-2">
        <label for="endDate">END</label>
        <input class="form-control" formControlName="endDate" type="date" id="endDate">
      </div>
    </div>



    <button mat-raised-button color="primary" type="submit" [disabled]="seriesForm.invalid">CREATE</button>
  </form>

</div>
