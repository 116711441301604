import { ChangeDetectorRef, Component, Inject, OnInit, Optional, NgZone } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BestBall_TeamAdvancementRequest, SeriesCompetitorAdvancementRequst, TeamAdvancementRequest, TeamTournamentAdvancementRequest } from '../../../../interfaces';
import { TournamentService } from '../../tournament.service';

@Component({
  selector: 'app-team-best-ball-advancement-confirm',
  templateUrl: './team-best-ball-advancement-confirm.component.html',
  styleUrls: ['./team-best-ball-advancement-confirm.component.css']
})
export class TeamBestBallAdvancementConfirmComponent implements OnInit{
  public isLoading: boolean;
  form: FormGroup;
  constructor(private service: TournamentService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: BestBall_TeamAdvancementRequest,
    private zone: NgZone, public dialog: MatDialog, private dialogRef: MatDialogRef<TeamBestBallAdvancementConfirmComponent>, private cd: ChangeDetectorRef,) {
    console.log("opening team advancement", this.data)
    this.isLoading = true
    this.form = this.fb.group({
      golfers: this.fb.array([]), // initialize the form array
      selectedRound: [null, Validators.required]
    });
  }

    ngOnInit(): void {
      this.isLoading = false;
      this.data.competitors.forEach(x => {
        this.addGolfer(x.playerSyncId, x.handicap, x.email);
      });
      this.cd.detectChanges();
    }

  addGolfer(golferId: number, handicap: number, email: string) {
    const golferForm = this.fb.group({
      golferId: [golferId, Validators.required],
      golferEmail: [email, Validators.required],
      handicap: [handicap !== null && handicap !== undefined ? handicap : '0']
    });

    this.golfers.push(golferForm);
  }

  onRadioChange() {
    const shouldEnable = this.isNetOptionSelected() || this.isTeamDecisionSelected();
    (this.golfers.controls as FormGroup[]).forEach(golferFormGroup => {
      const handicapControl = golferFormGroup.get('handicap');
      if (shouldEnable) {
        handicapControl?.enable({ emitEvent: false }); // Enable the control without emitting an event
      } else {
        handicapControl?.disable({ emitEvent: false }); // Disable the control without emitting an event
      }
    });

    // Trigger change detection to update the UI
    this.cd.detectChanges();
  }
  get golfers(): FormArray {
    return this.form.get('golfers') as FormArray;
  }

  onSubmit() {
    console.log(this.form.value);
    this.isLoading = true
    if (this.form.valid) {
      // Handle submission logic here, for example:
      var goingTo: number[] = []
      if (this.form.value.selectedRound === -1) {
        goingTo = this.data.nextRounds.map((nr: any) => nr.id);
      } else {
        goingTo.push(this.form.value.selectedRound)
      }
      var grossOnly: boolean
      var netOnly: boolean
      var teamOption: boolean
      if (this.form.value.selectedRound != -1) {
        // its either just gross or just next

        var nextRoundHolder = this.data.nextRounds.find((x: any) => {
          return x.id === this.form.value.selectedRound;
        });
        if (nextRoundHolder.netOnly) {
          grossOnly = false
          netOnly = true
          teamOption = false
        } else {
          grossOnly = true
          netOnly = false
          teamOption = false
        }

      } else {
        grossOnly = false
        netOnly = false
        teamOption = true
      }
      
      console.log("here are the next rounds", this.data.nextRounds);
      console.log("here is the next round holder", nextRoundHolder )
      var request: TeamTournamentAdvancementRequest = {
          seriesTournamentId: this.data.seriesTournamentId,
          teamId: this.data.team.teamId,
          nextRounds: goingTo,
          approvingAdvance: '',
          pastRoundsLocation: this.data.pastRoundsLocation,
          teamMembersInfo: {
              golfers: this.form.value.golfers,
              selectedRound: this.form.value.selectedRound
          },
          seriesId: this.data.seriesId,
          grossOnly: grossOnly,
          netOnly: netOnly,
          teamOption: teamOption
      }
      this.service.postAdvanceTeamInTournament(request).subscribe(x => {
        this.dialogRef.close(x); // Close the dialog

      }, err => {
        console.log(err)
      }, () => { })
    }
  }

  isSelectedRound(round: any): boolean {
    const selectedRoundValue = this.form.get('selectedRound')?.value;
    return selectedRoundValue === -1 || (round?.id === selectedRoundValue && round?.netOnly);
  }

  isNetOptionSelected(): boolean {
    const selectedRoundValue = this.form.get('selectedRound')?.value;
    const selectedRound = this.data.nextRounds.find(r => r.id === selectedRoundValue);
    return !!selectedRound?.netOnly; // Ensure 'netOnly' is true to enable the control
  }

  isTeamDecisionSelected(): boolean {
    return this.form.get('selectedRound')?.value === -1; // 'Team Will Decide' option
  }

  shouldDisableHandicap(): boolean {
    return this.isNetOptionSelected() || this.isTeamDecisionSelected();
  }

  updateHandicapControlsState() {
    const shouldDisable = this.shouldDisableHandicap();

    (this.golfers.controls as FormGroup[]).forEach(golferFormGroup => {
      const handicapControl = golferFormGroup.get('handicap');
      if (shouldDisable) {
        handicapControl?.disable();
      } else {
        handicapControl?.enable();
      }
    });
  }


}
