import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer, catchError, map, of } from 'rxjs';
import { SeriesSignUpComponent } from './series/series-sign-up/series-sign-up.component';
import { AppInviteRequest } from './interfaces';


@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  roles: Array<string> = [];
  assignedLocations: any[] = [];
  userProfile: any;
  constructor(private http: HttpClient) {}

  getUsersRoles(): Observable<any> {
    return this.http.get<any>(`/api/user/getRoles`, { withCredentials: true })
  }

  getUserProfile(): Observable<any> {
    return this.http.get<any>('/api/user/userProfile', { withCredentials: true })
      .pipe(
        catchError(err => {
          console.error(err);
          return of(null);
        }),
        map(profile => {
          if (profile && profile.roles) {
            this.roles = profile.roles;
            localStorage.setItem('userRoles', JSON.stringify(this.roles));
            localStorage.setItem('assignedLocations', JSON.stringify(profile.assignedLocations))
            localStorage.setItem('userProfile', JSON.stringify(profile.userProfile))
          }
          return this.roles;
        })
      );
  }

  getRoles(): Array<string> {
    const storedRoles = localStorage.getItem('userRoles');
    if (storedRoles) {
      this.roles = JSON.parse(storedRoles);
    }
    return this.roles;
  }

  getLocations(): Observable<any> {
    const locations = localStorage.getItem('assignedLocations');
    if (locations) {
      try {
        this.assignedLocations = JSON.parse(locations);
      } catch (e) {
        console.error('Error parsing assignedLocations:', e);
        // Handle this error appropriately.
      }
    }
    return of(this.assignedLocations);
  }

  getUsersCachedProfile(): Observable<any> {
    const profile = localStorage.getItem('userProfile')
    if (profile) {
      try {
        this.userProfile = JSON.parse(profile)
      }
      catch (e) {
        console.error('Error parsing userProfile:', e);
      }
    }
    return of(this.userProfile)
  }

  isAdmin(): boolean {
    const roles = this.getRoles();
    return roles.includes('SystemAdmin');
  }

  isLocationManager(): boolean {
    const roles = this.getRoles();
    return roles.includes('LocationManager');
  }

  verifyUserRole(role: string): boolean {
    const roles = this.getRoles()
    return roles.includes(role);
  }

  postSendLMInvite(inviteObj: AppInviteRequest): Observable<any> {
    return this.http.post<any>('api/user/sendLocationManagerInvite', inviteObj)
  }

  postSendAppInvite(inviteObj: AppInviteRequest): Observable<any> {
    return this.http.post<any>('api/user/sendAppInvite', inviteObj)
  }

}
