import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DirectCommunicationRequest } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private http: HttpClient) { }

  postSendPlayerMessage(request: DirectCommunicationRequest): Observable<any> {
    return this.http.post<any>('api/communications/sendPlayerDirectMessage', request)
  }

}
