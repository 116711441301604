import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SeriesService } from '../series.service';

@Component({
  selector: 'app-create-series',
  templateUrl: './create-series.component.html',
  styleUrls: ['./create-series.component.css']
})
export class CreateSeriesComponent {
  seriesForm: FormGroup;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateSeriesComponent>, private service: SeriesService,
    private _snackBar: MatSnackBar) {
    this.seriesForm = this.fb.group({
      title: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }


  onSubmit() {
    console.log(this.seriesForm.valid)
    if (this.seriesForm.valid) {
      const formData = new FormData();
      formData.append('title', this.seriesForm.get('title')?.value);
      formData.append('start', this.seriesForm.get('startDate')?.value);
      formData.append('end', this.seriesForm.get('endDate')?.value);
      // Handle form submission to your backend here
      this.service.createSeries(formData).subscribe(x => {
        if (x) {
          this._snackBar.open('Series created successfully!', 'Close', { duration: 3000 });
          this.dialogRef.close(x);
        }
      }, err => { }, () => { })
    }
  }

}
