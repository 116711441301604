<div *ngIf="isLoading" class="container-fuild">
  <mat-progress-bar style="color:#007041" mode="query"></mat-progress-bar>
</div>
<div [class.loading-opacity]="isLoading" class="container-fuild p-1">
  <div *ngIf="(verifyAccess('LocationManager') || verifyAccess('SystemAdmin'))">
    <div class="row">
      <div class="col-6 p-3">
        <div class="row" *ngIf="(verifyAccess('SystemAdmin') || verifyAccess('LocationManager')) && details">
          <div class="col-3" *ngIf="details?.tournamentRoundRequest.closed">
            <h4 class="text-danger">ROUND CLOSED</h4>
          </div>
          <h1>{{ details?.tournamentRoundRequest.title }}</h1>
          <h3>{{ details?.tournamentRoundRequest.startDate | date:'shortDate' }} - {{ details?.tournamentRoundRequest.endDate | date:'shortDate' }}</h3>
          <div class="row p-2" *ngIf="verifyAccess('TournamentAdmin') || verifyAccess('SystemAdmin')">
            <div class="col-3" *ngIf="!details?.tournamentRoundRequest.closed">
              <a (click)="closeRound(details?.tournamentRoundRequest)" class="btn xbtn-primary">CLOSE ROUND</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 p-3">
        <div style="max-height:250px;overflow-y:scroll">
          <table>
            <thead>
              <tr>
                <th *ngIf="!details?.tournamentRoundRequest.isTeamEvent">REGISTERED PLAYERS YET TO PLAY</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let individual of details?.individualCompetitors">
                <tr class="" *ngIf="(visibleToLM(individual) || verifyAccess('SystemAdmin')) && individual.tournamentRounds == null">
                  <!-- Display the email once -->
                  <td>
                    <a (click)="openComms(individual.playersEmail)">{{ individual.playersEmail }}</a> <div *ngIf="details?.tournamentRoundRequest.netOnly && details?.tournamentRoundRequest.isFinalRound">
                      FINAL HANDICAP {{convertHandicap(individual.startingHandicap)}}
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>


      </div>
    </div>

    <div class="row p-2" *ngIf="verifyAccess('SystemAdmin')">
      <!--<div class="col-3">
        <a class="xbtn-primary">EDIT</a>
      </div>-->
      <!--<div class="col-3" *ngIf="!details?.tournamentRoundRequest.closed">
        <a (click)="closeRound(details?.tournamentRoundRequest)" class="btn xbtn-primary">CLOSE ROUND</a>
      </div>-->
      <div *ngIf="details?.tournamentRoundRequest.isFinalRound" class="col-3">
        <a class="btn xbtn-primary">SEE LEADERBOARD</a>
      </div>
      <!--<div *ngIf="details.displayLeaderboard && details?.tournamentRoundRequest.isFinalRound" class="col-3">
        <a class="btn xbtn-primary">LEADERBOARD</a>
      </div>-->
    </div>
  </div>

  <div *ngIf="(verifyAccess('LocationManager') || verifyAccess('SystemAdmin'))" class="container-fluid">
    <div class="row">
      <form>
        <mat-form-field>
          <input type="text"
                 placeholder="Search by email"
                 matInput
                 [formControl]="myControl"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" class="custom-autocomplete" (optionSelected)="onEmailSelect($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.playersEmail">
              {{ option.playersEmail }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button type="button" style="background-color:transparent;border:0px" (click)="resetSearch()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
            <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z" />
          </svg>
        </button>
      </form>
      <mat-paginator *ngIf="details?.tournamentRoundRequest.isFinalRound" [length]="paginatedIndividualCompetitors.length"
                     [pageSize]="25"
                     [pageSizeOptions]="[5, 10, 25, paginatedIndividualCompetitors.length]"
                     style="width:100%">
      </mat-paginator>
      <mat-paginator *ngIf="!details?.tournamentRoundRequest.isFinalRound" [length]="details?.individualCompetitors.length"
                     [pageSize]="25"
                     [pageSizeOptions]="[5, 10, 25, details?.individualCompetitors.length]"
                     style="width:100%">
      </mat-paginator>
      <table class="table table-hover table-striped" matSort (matSortChange)="sortData($event)">
        <thead>
          <tr>
            <th class="lead" mat-sort-header="email">COMPETITOR</th>
            <th class="lead" mat-sort-header="date">DATE</th>
            <th class="lead">LOCATION</th>
            <th class="lead" mat-sort-header="round">ROUNDS</th>
            <th class="lead">STATUS</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let individual of paginatedIndividualCompetitors">

            <tr class="table-dark" *ngIf="(visibleToLM(individual) || verifyAccess('SystemAdmin')) && individual.tournamentRounds != null">
              <!-- Display the email once -->
              <td>
                <a (click)="openComms(individual.playersEmail)">{{ individual.playersEmail }}</a> <div *ngIf="details?.tournamentRoundRequest.netOnly && details?.tournamentRoundRequest.isFinalRound">
                  FINAL HANDICAP {{convertHandicap(individual.startingHandicap)}}
                </div>
              </td>
              <td></td>
              <td>
              </td>
              <td></td>
              <td>
                <ng-container *ngIf="individual.roundSyncId > 0 && individual.advancing && !details?.tournamentRoundRequest.isFinalRound">
                  <!--we then want to check if they have the advancing if true show they are advancing.-->
                  <span><strong style="font-size:1.2em">ADVANCING</strong></span>
                </ng-container>
                <!-- Display the "Advance" button for the lowest scoring round -->
                <ng-container *ngIf="individual.tournamentRounds != null && !details?.tournamentRoundRequest.isFinalRound && !individual.advancing && hasVerifiedScorecard(individual) && !details?.tournamentRoundRequest.closed">
                  <button *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="btn btn-sm xbtn-primary" (click)="openAdvancementScreen(individual)">Advance To Finals</button>
                </ng-container>
                <!-- ... your other conditions for displaying messages ... -->
              </td>
            </tr>
            <!-- Sort the rounds by score and then display them -->
            <ng-container *ngFor="let round of individual.tournamentRounds; let i = index">
              <tr [class.table-secondary]="i % 2 === 0" *ngIf="(visibleToLM(individual) || verifyAccess('SystemAdmin')) && individual.tournamentRounds != null">
                <td></td>
                <td style="text-align:center">
                  <a (click)="viewCard(individual, round)">{{round.created | date:'short'}}</a>
                </td>
                <td>{{round.location}}</td>
                <td>
                  <div style="display:flex;">
                    <!-- ... your code for displaying hole details ... -->
                    <!--<div style="border-right: black solid 1px; border-top:1px solid black; border-left: black solid 1px">
                      <div style="border-bottom: black solid 1px;text-align:center">
                        Hole
                      </div>
                      <div style="border-bottom: black solid 1px;text-align:center">
                        Par
                      </div>
                      <div style="text-align:center;border-bottom:1px solid black;">
                        Score
                      </div>
                    </div>-->
                    <!--<ng-container *ngFor="let hole of sortHoleScores(round)">
                      <div style="width:35px;border-right: black solid 1px; border-top:1px solid black; border-left: black solid 1px">
                        <div style="border-bottom: black solid 1px;text-align:center">
                          {{ hole.key }}
                        </div>
                        <div style="border-bottom: black solid 1px;text-align:center">
                          <ng-container *ngFor="let courseHole of details.course.courseHoles">
                            <div style="border-bottom: black solid 1px;text-align:center" *ngIf="courseHole.holeNumber == hole.key">

                              {{ courseHole.par }}
                            </div>
                          </ng-container>
                        </div>
                        <div style="text-align:center;border-bottom:1px solid black;">
                          {{ hole.value }}
                        </div>
                      </div>
                    </ng-container>-->

                    <div style="display:inline-block;margin:6px;padding:6px;font-weight:bold;font-size:1.7em">

                      {{ round.roundScoreTotal }}
                    </div>
                    <ng-container class="p-2" *ngIf="round.usedMulligan">
                      <h4 class="text-warning" style="margin-top:10px;text-align:center;">MULLIGAN USED</h4>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <ng-container *ngIf="individual.tournamentRounds != null">
                    <span *ngIf="individual.scorecardImageURL != null && individual.scorecardVerifiedBy != null">Card Verified</span>
                    <ng-container *ngIf="individual.scorecardImageURL != null && individual.roundSyncId == round.roundSyncId">
                      <span *ngIf="individual.scorecardVerified" class="lead">VERIFIED</span>
                      <span *ngIf="!individual.scorecardVerified" class="lead">Awaiting Verification...</span>
                      <img class="img-thumbnail" style="cursor:pointer; max-width:400px" (click)="viewCard(individual, round)" src="{{individual.scorecardImageURL}}" [ngStyle]="{'border': individual.scorecardVerified ? '4px solid green' : '4px solid red'}" />
                    </ng-container>
                    <ng-container *ngIf="!details?.tournamentRoundRequest.closed && !hasVerifiedScorecard(individual) && individual.scorecardImageURL == null && individual.roundSyncId != round.roundSyncId">
                      <button *ngIf="!individual.scorecardVerified" class="btn btn-sm xbtn-primary" (click)="openScorecardUploadScreen(round, individual)">Upload Scorecard</button>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>

            </ng-container>

          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
