<div *ngIf="isLoading" class="container-fuild">
  <mat-progress-bar style="color:#007041" mode="query"></mat-progress-bar>
</div>



<div [class.loading-opacity]="isLoading" class="container-fuild p-1">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">LOCATIONS</h1>
    </div>
  </div>


  <div *ngIf="(verifyAccess('LocationManager') || verifyAccess('SystemAdmin'))" class="container-fluid">
    <div class="row">
      <form>
        <mat-form-field>
          <input type="text"
                 placeholder="Search Locations"
                 matInput
                 [formControl]="myControl"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" class="custom-autocomplete" (optionSelected)="onNameSelect($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button type="button" style="background-color:transparent;border:0px" (click)="resetSearch()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
            <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z" />
          </svg>
        </button>
      </form>
      <mat-paginator [length]="locations?.length"
                     [pageSize]="25"
                     [pageSizeOptions]="[5, 10, 25, locations?.length]"
                     style="width:100%">
      </mat-paginator>
      <div class="morrisContainer p-5">
        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <th class="lead">LOCATION</th>
              <th class="lead">EST.</th>
              <th class="lead">STATUS</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let location of paginatedLocations">

              <tr>
                <td><a (click)="openLocationDetails(location)">{{ location.name }}</a></td>
                <td>{{ location.openDate | date:'shortDate' }}</td>
                <td>{{ location.isActive ? 'Active' : 'Inactive' }}</td>
              </tr>

            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>


