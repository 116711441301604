<div *ngIf="isLoading" class="container-fuild">
  <mat-progress-bar style="color:#007041" mode="query"></mat-progress-bar>
</div>

<div [class.loading-opacity]="isLoading" class="container-fuild p-1">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">SHORT GAMES</h1>
    </div>
  </div>


  <div *ngIf="(verifyAccess('SystemAdmin'))" class="container-fluid">
    <div class="row">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <!-- #enddocregion align-start -->
        <mat-tab label="SHORT GAMES">
          <div class="row">
            <form>
              <mat-form-field>
                <input type="text"
                       placeholder="Search Short Games"
                       matInput
                       [formControl]="myControl"
                       [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete" class="custom-autocomplete" (optionSelected)="onNameSelect($event)">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.challenge.title">
                    {{ option.challenge.title }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <button type="button" style="background-color:transparent;border:0px" (click)="resetSearch()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
                  <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z" />
                </svg>
              </button>
            </form>
            <div style="display:flex;justify-content:flex-start">
              <button class="btn btn-outline-primary col-1" (click)="createChallenge()">
                CREATE
              </button>
            </div>
            <mat-paginator [length]="challenges?.length"
                           [pageSize]="10"
                           [pageSizeOptions]="[5, 10, 25, challenges?.length]"
                           style="width:100%">
            </mat-paginator>
            <div class="morrisContainer p-5">
              <table class="table table-hover table-striped">
                <thead>
                  <tr>
                    <th class="lead">SHORT GAME</th>
                    <th class="lead"></th>
                    <th class="lead">TYPE</th>
                    <th class="lead">START</th>
                    <th class="lead">END</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let challenge of paginatedChallenges">
                    <tr>
                      <td>
                        <a [routerLink]="['/Challenge', challenge.id]">
                          <span>{{ replaceEmptySpacesWithSingleSpace(challenge?.description) }}</span>
                        </a>
                      </td>
                      <td>
                        <a (click)="editChallenge(challenge)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                          </svg>
                        </a>
                      </td>
                      <td>
                        <ng-container *ngIf="challenge?.challengeType === 'NATIONAL_CHALLENGE'">NATIONAL CHALLENGE</ng-container>
                        <ng-container *ngIf="challenge?.challengeType === 'MINI_GAMES'">MINI GAME</ng-container>
                      </td>
                      <td>{{ challenge.startDate | date:'shortDate' }}</td>
                      <td>{{ challenge.endDate | date:'shortDate' }}</td>

                    </tr>
                  </ng-container>


                </tbody>
              </table>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="ANALYTICS">
          <span>coming soon...</span>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>

</div>



