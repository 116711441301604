import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateSeriesTournamentTeamRequest, LeaderboardOnRequest, SeriesCompetitorAdvancementRequst, TeamTournamentAdvancementRequest } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class TournamentService {

  constructor(private http: HttpClient) { }

  getTournamentTeamResults(id: number): Observable<any> {
    return this.http.get<any>('/api/tournament/getTournamentResults', { params: { tournamentId: id.toString() } })
  }

  getTournament(id: number): Observable<any> {
    return this.http.get<any>('/api/tournament/getTournament', { params: { tournamentId: id.toString() } })
  }

  getRound(id: number): Observable<any> {
    return this.http.get<any>('/api/tournament/getRound', { params: { roundId: id.toString() } })
  }

  uploadScoreCardImageForRound(data: FormData) {
    return this.http.post('/api/tournament/uploadRoundScorecard', data);
  }

  postAdvanceSeriesCompetitor(data: SeriesCompetitorAdvancementRequst) {
    return this.http.post('/api/tournament/advanceSeriesCompetitor', data);
  }

  postAdvanceTeamInTournament(data: TeamTournamentAdvancementRequest) {
    return this.http.post('/api/tournament/advanceTeam', data)
  }

  postPublishLeaderboard(request: LeaderboardOnRequest) {
    return this.http.post('/api/tournament/displayTournamentResults', request)
  }

  createTeam(teamRequest: CreateSeriesTournamentTeamRequest): Observable<any> {
    return this.http.post<any>('api/tournament/createTeam', teamRequest)
  }

  createTournament(request: any) {
    return this.http.post<any>('api/tournament/create', request);
  }

  updateTournament(request: any) {
    return this.http.post<any>('api/tournament/edit', request);
  }

  getTournamentRoundDetails(id: number): Observable<any> {
    return this.http.get<any>('/api/tournament/getRoundDetails', { params: { id: id.toString() } })
  }
}
