import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TournamentService } from '../../tournament.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScorecardImageUploadRequest } from '../../../../interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoundService } from '../round.service';

@Component({
  selector: 'app-scorecard-upload',
  templateUrl: './scorecard-upload.component.html',
  styleUrls: ['./scorecard-upload.component.css']
})
export class ScorecardUploadComponent {
  public isLoading: boolean;
  form: FormGroup;
  tournamentRound: any; // Replace with your type
  tournamentRequest: any; // Replace with your type
  public details: any;
  
  constructor(private cd: ChangeDetectorRef, private _snackBar: MatSnackBar, private fb: FormBuilder, private tournamentSerivce: TournamentService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ScorecardImageUploadRequest,
    private dialogRef: MatDialogRef<ScorecardUploadComponent>) {
    this.isLoading = true
    this.form = this.fb.group({
      competitorId: ['', [Validators.required]],
      tournamentRoundId: ['', [Validators.required]],
      referenceId: ['', [Validators.required]],
      file: ['', [Validators.required]],
      teamCard: ['', [Validators.required]]
    });
    this.details = data
  }

  ngOnInit(): void {
    this.form.controls['competitorId'].setValue(this.data.competitorId)
    this.form.controls['tournamentRoundId'].setValue(this.data.tournamentRoundId)
    this.form.controls['referenceId'].setValue(this.data.referenceId);
    this.form.controls['teamCard'].setValue(this.data.teamCard);
    this.isLoading = false;
    this.cd.markForCheck();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        file: file
      });
    }
  }

  onSubmit() {
    this.isLoading = true
    this.cd.markForCheck();
    const formData = new FormData();

    formData.append('competitorId', this.data.competitorId !== null && this.data.competitorId !== undefined ? this.data.competitorId.toString() : '0');
    formData.append('roundId', this.data.tournamentRoundId.toString());
    formData.append('referenceId', this.data.referenceId.toString());
    formData.append('playersEmail', this.data.email);
    formData.append('file', this.form.get('file')?.value);
    formData.append('location', this.data.location)
    formData.append('userId', this.data.userId)
    formData.append('userSyncId', this.data.userSyncId.toString())
    formData.append('tournamentId', this.data.seriesTournamentId.toString())
    formData.append('simProvidedScore', this.data.simProvidedScore.toString())
    formData.append('seriesId', this.data.seriesId.toString())
    formData.append('manualEntry', "false")
    this.tournamentSerivce.uploadScoreCardImageForRound(formData).subscribe(response => {
      if (response) {
        this._snackBar.open('Image uploaded successfully.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
      }
      this.dialogRef.close(response)
    }, err => { console.log(err)}, () => { });
  }


}


