<div *ngIf="isLoading" class="container-fuild">
  <div class="row" style="display:flex;justify-content:center;margin-top:5%">
    <div class="col-sm-12">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" [class.loading-opacity]="isLoading" class="container-fluid p-2 morrisContainer" style="overflow-y:scroll;">
  <div class="row center">
    <h1 class="disply-3" style="text-align:center">EDIT ROUND</h1>
  </div>
  <mat-stepper headerPosition="bottom" [linear]="isLinear" #stepper class="m-3">
    <mat-step [stepControl]="roundForm">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="roundForm" class="m-2">
            <input type="hidden" formControlName="roundId" id="roundId">
            <input type="hidden" formControlName="tournamentType" id="tournamentType" />
            <input type="hidden" formControlName="tournamentId" id="tournamentId" />
            <ng-template matStepLabel>
              ROUND
            </ng-template>
            <div class="form-group m-2">
              <label>COURSE</label>
              <select [disabled]="true" id="courseCode" formControlName="courseCode" class="form-control">
                <option value="$1000_SeoSeoul">Seo Seoul</option>
                <option value="$1001_Montvert">Montvert</option>
                <option value="$1002_SilkRiver">Silk River</option>
                <option value="$1003_TongDo">Tong Do</option>
                <option value="$1004_Ora">Ora</option>
                <option value="$1005_MaunaOcean">Mauna Ocean</option>
                <option value="$1006_PristineValley">Pristine Valley</option>
                <option value="$1007_HwaSan">Hwa San</option>
                <option value="$1008_Muju">Muju</option>
                <option value="$1009_Sky72Sky">Sky 72 Sky</option>
                <option value="$1010_Jisan">Jisan</option>
                <option value="$1011_PineBeach">Pine Beach</option>
                <option value="$1012_Asiana">Asiana</option>
                <option value="$1015_InterBurgo">Inter Burgo</option>
                <option value="$1016_JgolfKasumi">Jgolf Kasumi</option>
                <option value="$1017_Sky72Ocean">Sky 72 Ocean</option>
                <option value="$1018_SunHills">Sun Hills</option>
                <option value="$1019_LakeWood">Lake Wood</option>
                <option value="$1020_Bethpage">Bethpage</option>
                <option value="$1021_Metropolitan">Metropolitan</option>
                <option value="$1022_HabourTown">Habour Town</option>
                <option value="$1023_BayHill">Bay Hill</option>
                <option value="$1024_EvianMasters">Evian Masters</option>
                <option value="$1025_Pebblebeach">Pebble Beach</option>
                <option value="$1026_AugustaNational">Augusta National</option>
                <option value="$1027_CoeurdAlene">Coeurd Alene</option>
                <option value="$1028_Innisbrook">Innisbrook</option>
                <option value="$1029_Spanishbay">Spanish bay</option>
                <option value="$1030_PGA">PGA</option>
                <option value="$1031_Spyglasshill">Spy Glass Hill</option>
                <option value="$1032_KapaLua">Kapa Lua</option>
                <option value="$1033_Doral">Doral</option>
                <option value="$1034_EASTLAKE">East Lake</option>
                <option value="$1035_TurtleBay">Turtle Bay</option>
                <option value="$1036_PrinceVille">Prince Ville</option>
                <option value="$1037_Hazeltine">Hazeltine</option>
                <option value="$1038_KawanaHotelFuji">Kawana Hotal Fuji</option>
                <option value="$1039_PebbleBeachGL">Pebble Beach GL</option>
                <option value="$1040_JapanMemorial">Japan Memorial</option>
                <option value="$1041_XgolfCC">Xgolf CC</option>
                <option value="$1042_RoyalPines">Royal Pines</option>
                <option value="$1043_Kingston">Kingston</option>
                <option value="$1044_OakMont">Oak Mont</option>
                <option value="$1045_Teecloud">Tee Cloud</option>
                <option value="$1046_Piratesisland">Pirates Island</option>
                <option value="$2000_Indoor_00">Indoor</option>
                <option value="$4001_OldCourse">Old Course</option>
              </select>
              <select id="courseAndHole" class="form-control">
                <option value="$1039_Challenge_10_Shot_0">
                  10 Shot Challenge
                </option>
                <option value="$4001_Challenge_10_Shot_0">
                  10 shot challenge St Andrews One Club
                </option>
                <option value="$1005_MaunaOcean_15">
                  15 @ Maura Ocean
                </option>
                <option value="$1011_PineBeach_15">
                  15 @ Pine Beach
                </option>
                <option value="$1020_Bethpage_17">
                  17 @ Bethpage
                </option>
                <option value="$1022_HabourTown_14">
                  14 @ Harbour Town
                </option>
                <option value="$1023_BayHill_17">
                  17 @ Bay Hill
                </option>
                <option value="$1025_Pebblebeach_7">
                  7 @ Pebble
                </option>
                <option value="$1028_Innisbrook_17">
                  17 @ Innisbrook
                </option>
                <option value="$1030_PGA_17">
                  17 @ PGA National
                </option>
                <option value="$1034_EASTLAKE_18">
                  18 @ East Lake
                </option>
                <option value="$1040_JapanMemorial_17">
                  17 @ Japan Memorial
                </option>
                <option value="$1042_RoyalPines_16">
                  16 @ Royal Pines
                </option>
                <option value="$1044_OakMont_8">
                  8 @ Oakmont
                </option>
              </select>
            </div>
            <div class="form-group m-2">
              <label for="courseHoleSelection">HOLE SELECTION</label>
              <select [disabled]="true" formControlName="courseHoleSelection" id="courseHoleSelection" class="form-select">
                <option value="FULL18">
                  FULL 18
                </option>
                <option value="FRONT9">
                  FRONT 9
                </option>
                <option value="BACK9">
                  BACK 9
                </option>
              </select>
            </div>
            <div class="form-group m-2">
              <label for="playMode">PLAY MODE</label>
              <select id="playMode" class="form-control" formControlName="playMode">
                <option value="STROKE">Stroke</option>
                <option value="MATCH_BEST_BALL">Best Ball</option>
                <option value="MATCH_BEST_BALL_GROSS">Best Ball Gross</option>
                <option value="MATCH_ALTERNATE_SHOT">Alternate Shot</option>
                <option value="MATCH_SKINS">Skins</option>
                <option value="EVENT_NEARPIN">Near Pin</option>
                <option value="EVENT_LONGEST">Longest</option>
                <option value="EVENT_PUTTING">Putting</option>
                <option value="TRAINING_DRIVING_RANGE">Driving Range Training</option>
                <option value="TRAINING_COURSE">Course Training</option>
                <option value="SCRAMBLE">Scramble</option>
              </select>
            </div>
            <div class="form-group m-2">
              <label for="roundType">ROUND TYPE</label>
              <select formControlName="roundType" id="roundType" class="form-select">
                <option value="COMPETITION">
                  COMPETITION ROUND
                </option>
                <option value="FINAL">
                  FINAL ROUND
                </option>
              </select>
            </div>
            <div class="form-group m-2">
              <label for="scoringType">SCORING TYPE</label>
              <select formControlName="scoringType" id="scoringType" class="form-select">
                <option value="GROSS">
                  GROSS
                </option>
                <option value="NET">
                  NET
                </option>
              </select>
            </div>
            <div class="form-group mb-2">
              <label for="name">TITLE</label>
              <input class="form-control" formControlName="name" type="text" id="name" >
            </div>
            <div class="row">
              <div class="col-3">
                <div class="form-group mb-2">
                  <label for="startDate">START</label>
                  <input class="form-control" formControlName="startDate" type="date" id="startDate">
                </div>
              </div>
              <div class="col-3">
                <div class="form-group mb-2">
                  <label for="endDate">END</label>
                  <input class="form-control" formControlName="endDate" type="date" id="endDate">
                </div>
              </div>

              <!--<div class="form-group m-2">
                <label for="rulesId">RULES</label>
                <select formControlName="rulesId" id="rulesId" class="form-select">
                  <option value="0">
                    Rule # 1
                  </option>
                  <option value="1">
                    Rule # 2
                  </option>
                </select>
              </div>-->

            </div>

            <!--<div class="form-group">
              <label for="imageUpload">ICON</label>
              <input type="file" id="imageUpload" (change)="onFileSelect($event)" class="form-control">
            </div>-->
            <div class="m-3" style="display:flex;justify-content:flex-end">
              <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
            </div>
          </form>
        </div>
      </div>
    </mat-step>


    <mat-step [stepControl]="courseSettingForm">
      <form [formGroup]="courseSettingForm" class="m-2">
        <input type="hidden" formControlName="eventId" id="courseSettingForm_eventId">
        <ng-template matStepLabel>COURSE</ng-template>
        <div class="container">
          <div class="row">
            <div class="col-4">

              <h2 class="display-6">UNIT</h2>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="fieldDistance">Field Distance</label>
                    <select formControlName="fieldDistance" id="fieldDistance" class="form-select border text-center">
                      <option value="1">
                        Meter
                      </option>
                      <option value="2">
                        Feet
                      </option>
                      <option value="3">
                        Yard
                      </option>
                    </select>
                  </div>

                  <!-- Green Distance -->
                  <div class="form-group">
                    <label for="greenDistance">Green Distance</label>
                    <select id="greenDistance" formControlName="greenDistance" class="form-select border text-center">
                      <option value="1">
                        Meter
                      </option>
                      <option value="2">
                        Feet
                      </option>
                      <option value="3">
                        Yard
                      </option>
                    </select>

                  </div>

                  <!-- Speed Unit -->
                  <div class="form-group">
                    <label for="speedUnit">Speed Unit</label>
                    <select id="speedUnit" formControlName="speedUnit" class="form-select border text-center">
                      <option value="2">km/h</option>
                      <option value="1">m/s</option>
                      <option value="3">mph</option>
                    </select>

                  </div>
                </div>
              </div>

              <h2 class="display-6">VIEW MODE</h2>
              <div class="card">
                <div class="card-body">
                  <!-- Swing View -->
                  <div class="form-group">
                    <label for="swingView">Swing View</label>
                    <select id="swingView" formControlName="swingView" class="form-select border text-center">
                      <option value="1">
                        Basic
                      </option>
                      <option value="2">
                        Dynamic
                      </option>
                      <option value="3">
                        Hit1
                      </option>
                      <option value="4">
                        Hit2
                      </option>
                      <option value="5">
                        Hit3
                      </option>
                      <option value="6">
                        Hit4
                      </option>
                      <option value="7">
                        Hit5
                      </option>
                    </select>
                  </div>

                  <!-- Putting View -->
                  <div class="form-group">
                    <label for="puttingView">Putting View</label>
                    <select id="puttingView" formControlName="puttingView" class="form-select border text-center">
                      <option value="1">
                        Putting1
                      </option>
                      <option value="2">
                        Putting2
                      </option>
                      <option value="3">
                        Putting3
                      </option>
                      <option value="4">
                        Putting4
                      </option>
                    </select>

                  </div>

                  <!-- Auto Fly By -->
                  <div class="form-group">
                    <label for="autoFlyBy">Auto Fly By</label>
                    <select id="autoFlyBy" formControlName="autoFlyBy" class="form-select text-center">
                      <option value="1">On</option>
                      <option value="2">Off</option>
                    </select>
                  </div>

                </div>
              </div>

              <h2 class="display-6">DEVICE OPTION</h2>
              <div class="card">
                <div class="card-body">
                  <!-- X Plate Green -->
                  <div class="form-group">
                    <label for="xPlateGreen">X Plate Green</label>

                    <select type="number" id="xPlateGreen" formControlName="xPlateGreen" class="form-select text-center">
                      <option value="0">None</option>
                      <option value="1">15%</option>
                      <option value="2">30%</option>
                      <option value="3">50%</option>
                      <option value="4">100%</option>
                    </select>
                  </div>

                  <!-- X Plate Field -->
                  <div class="form-group">
                    <label for="xPlateField">X Plate Field</label>
                    <select id="xPlateField" formControlName="xPlateField" class="form-select text-center">
                      <option selected value="0">None</option>
                      <option value="1">15%</option>
                      <option value="2">30%</option>
                      <option value="3">50%</option>
                      <option value="4">100%</option>
                    </select>
                  </div>

                  <!-- Auto Ball Feed -->
                  <div class="form-group">
                    <label for="autoBallFeed">Auto Ball Feed</label>
                    <select id="autoBallFeed" formControlName="autoBallFeed" class="form-select text-center">
                      <option value="1">On</option>
                      <option value="2">Off</option>
                    </select>
                  </div>

                  <!-- Putting Preview -->
                  <div class="form-group">
                    <label for="puttingPreview">Putting Preview</label>
                    <select id="puttingPreview" formControlName="puttingPreview" class="form-select text-center">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">Unlimited</option>
                      <option value="4">Preview on > 5m</option>
                    </select>
                  </div>

                </div>
              </div>

            </div>
            <div class="col-4">
              <h2 class="display-6">LOCAL RULE</h2>
              <div class="card">
                <div class="card-body">
                  <!-- Concede -->
                  <div class="form-group">
                    <label for="conceed">Concede</label>
                    <select id="conceed" formControlName="conceed" class="form-select text-center">
                      <option value="1">
                        2ft
                      </option>
                      <option value="2">
                        4ft
                      </option>
                      <option value="3">
                        7ft
                      </option>
                    </select>
                  </div>

                  <!-- OB Tee -->
                  <div class="form-group">
                    <label for="obTee">OB Tee</label>
                    <select id="obTee" formControlName="obTee" class="form-select text-center">
                      <option value="1">On</option>
                      <option value="2">Off</option>
                    </select>
                  </div>

                  <!-- Playable Area -->
                  <div class="form-group">
                    <label for="playableArea">Playable Area</label>
                    <select id="playableArea" formControlName="playableArea" class="form-select text-center">
                      <option value="1">
                        Almost All
                      </option>
                      <option value="2">
                        Side
                        Hole
                      </option>
                      <option value="3">
                        Current Hole
                      </option>
                    </select>
                  </div>

                  <!-- Double Par -->
                  <div class="form-group">
                    <label for="doublePar">Double Par</label>
                    <select id="doublePar" formControlName="doublePar" class="form-select text-center">
                      <option value="1">On</option>
                      <option value="2">Off</option>
                    </select>
                  </div>

                  <!-- Wind Option -->
                  <div class="form-group">
                    <label for="windOption">Wind Option</label>
                    <select id="windOption" formControlName="windOption" class="form-select text-center">
                      <option value="1">
                        Calm
                      </option>
                      <option value="2">
                        Light
                      </option>
                      <option value="3">
                        Moderate
                      </option>
                      <option value="4">
                        Strong
                      </option>
                      <option value="5">
                        Random
                      </option>
                    </select>
                  </div>

                  <!-- Club Selection In The Fridge -->
                  <div class="form-group">
                    <label for="clubSelectionInTheFridge">Club Selection In The Fridge</label>
                    <select id="clubSelectionInTheFridge" formControlName="clubSelectionInTheFridge" class="form-select text-center">
                      <option value="1">Effective Range</option>
                      <option value="2">Putter</option>
                      <option value="3">Wedge</option>
                    </select>
                  </div>
                </div>
              </div>

              <h2 class="display-6">DISPLAY</h2>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="swingInformation">Swing Information</label>
                    <select id="swingInformation" formControlName="swingInformation" class="form-select text-center">
                      <option value="5">None</option>
                      <option value="1">3s</option>
                      <option value="2">6s</option>
                      <option value="3">Continue</option>
                      <option value="4">Camera</option>
                    </select>
                  </div>

                  <!-- Field Trajectory -->
                  <div class="form-group">
                    <label for="fieldTrajectory">Field Trajectory</label>
                    <select id="fieldTrajectory" formControlName="fieldTrajectory" class="form-select text-center">
                      <option value="1">
                        Last
                      </option>
                      <option value="2">
                        All
                      </option>
                    </select>
                  </div>

                  <!-- Green Condition -->
                  <div class="form-group">
                    <label for="greenCondition">Green Condition</label>
                    <select id="greenCondition" formControlName="greenCondition" class="form-select text-center">
                      <option value="1">
                        Normal
                      </option>
                      <option value="2">
                        Soft
                      </option>
                      <option value="3">
                        Wet
                      </option>
                      <option value="4">
                        Firm
                      </option>
                    </select>
                  </div>

                  <!-- Putting Grid -->
                  <div class="form-group">
                    <label for="puttingGrid">Putting Grid</label>
                    <select id="puttingGrid" formControlName="puttingGrid" class="form-select text-center">
                      <option value="1">ClassicGrid</option>
                      <option value="2">Grid</option>
                      <option value="3">Animated Grid</option>
                      <option value="4">Contour</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <h2 class="display-6">BALL SPEED APPLICATION RATE</h2>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label for="rough">Rough</label>
                    <select id="rough" formControlName="rough" class="form-select text-center">
                      <option value="90">90%</option>
                      <option value="85">85%</option>
                      <option value="80">80%</option>
                    </select>
                  </div>


                  <div class="form-group">
                    <label for="greenSideBunker">Green Side Bunker</label>
                    <select id="greenSideBunker" formControlName="greenSideBunker" class="form-select text-center">
                      <option value="70">70%</option>
                      <option value="60">60%</option>
                      <option value="50">50%</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="fairwayBunker">Fairway Bunker</label>
                    <select id="fairwayBunker" formControlName="fairwayBunker" class="form-select text-center">
                      <option value="90">90%</option>
                      <option value="85">85%</option>
                      <option value="80">80%</option>
                    </select>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>

        <div class="m-3" style="display:flex;justify-content:flex-end">
          <button mat-button matStepperPrevious class="btn btn-outline-light">Back</button>
          <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="calibrationForm">
      <h2 class="display-6">CALIBRATIONS</h2>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="calibrationForm" class="m-2">
            <input type="hidden" formControlName="eventId" id="calibrationForm_eventId">
            <ng-template matStepLabel>CALIBRATION</ng-template>

            <div class="form-group">
              <label for="ballSpeed">Ball Speed</label>
              <input type="number" id="ballSpeed" formControlName="ballSpeed" class="form-control" />
            </div>

            <div class="form-group">
              <label for="puttingSpeed">Putting Speed</label>
              <input type="number" id="puttingSpeed" formControlName="puttingSpeed" class="form-control" />
            </div>

            <div class="form-group">
              <label for="backSpinOffset">Back Spin Offset</label>
              <input type="number" id="backSpinOffset" formControlName="backSpinOffset" class="form-control" />
            </div>

            <div class="form-group">
              <label for="sideSpinOffset">Side Spin Offset</label>
              <input type="number" id="sideSpinOffset" formControlName="sideSpinOffset" class="form-control" />
            </div>

            <div class="form-group">
              <label for="backSpinGain">Back Spin Gain</label>
              <input type="number" id="backSpinGain" formControlName="backSpinGain" class="form-control" />
            </div>

            <div class="form-group">
              <label for="sideSpinGain">Side Spin Gain</label>
              <input type="number" id="sideSpinGain" formControlName="sideSpinGain" class="form-control" />
            </div>

            <div class="m-3" style="display:flex;justify-content:flex-end">
              <button mat-button matStepperPrevious class="btn btn-outline-light">Back</button>
              <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
            </div>
          </form>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="dynamicForm">
      <h2 class="display-6">DYNAMICS</h2>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="dynamicForm" class="m-2">
            <input type="hidden" formControlName="eventId" id="dynamicForm_eventId">
            <ng-template matStepLabel>DYNAMICS</ng-template>
            <div class="form-group">
              <label for="gravity">Gravity</label>
              <input type="number" id="gravity" formControlName="gravity" class="form-control" />
            </div>

            <div class="form-group">
              <label for="rollingResistance">Rolling Resistance</label>
              <input type="number" id="rollingResistance" formControlName="rollingResistance" class="form-control" />
            </div>

            <div class="form-group">
              <label for="ballSpeedCompensation">Ball Speed Compensation</label>
              <input type="number" id="ballSpeedCompensation" formControlName="ballSpeedCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="puttSpeedCompensation">Putt Speed Compensation</label>
              <input type="number" id="puttSpeedCompensation" formControlName="puttSpeedCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="sliceSpinCompensation">Slice Spin Compensation</label>
              <input type="number" id="sliceSpinCompensation" formControlName="sliceSpinCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="hookSpinCompensation">Hook Spin Compensation</label>
              <input type="number" id="hookSpinCompensation" formControlName="hookSpinCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="backSpinCompensation">Back Spin Compensation</label>
              <input type="number" id="backSpinCompensation" formControlName="backSpinCompensation" class="form-control" />
            </div>

            <div class="form-group">
              <label for="forwardSpinCompensation">Forward Spin Compensation</label>
              <input type="number" id="forwardSpinCompensation" formControlName="forwardSpinCompensation" class="form-control" />
            </div>

            <div class="m-3" style="display:flex;justify-content:flex-end">
              <button mat-button matStepperPrevious class="btn btn-outline-light">Back</button>
              <button mat-button matStepperNext class="btn btn-outline-primary">Next</button>
            </div>
          </form>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>CONFIRM</ng-template>
      <p>You are about to submit the following details:</p>
      <div class="container">
        <div class="row">
          <div class="col-6 gx-0 gy-0">

            <div class="p-0 border bg-light">

              <h5>Round:</h5>
              <ul>
                <ng-container *ngFor="let controlName of getObjectKeys(roundForm.controls)">
                  <!--<li *ngIf="controlName !== 'roundId' && (controlName === 'roundIdentifier' || controlName === 'roundType')">
                    {{ camelCaseToNormal(controlName) }}: <b>{{ getOptionTextByIdAndValue(controlName, roundForm.get(controlName)?.value) }}</b>
                  </li>-->
                  <li *ngIf="controlName !== 'tournamentId' && controlName !== 'roundId' && controlName !== 'roundIdentifier' && controlName !== 'roundType'">
                    {{ camelCaseToNormal(controlName) }}:
                    <ng-container *ngIf="isImageFile(controlName); else notImage">
                      <!-- Bind to the stored image URL -->
                      <!--<img [src]="imageSrcMap[controlName]" class="img-thumbnail">-->
                    </ng-container>
                    <ng-template #notImage><b>{{ roundForm.get(controlName)?.value }}</b></ng-template>

                  </li>

                </ng-container>
              </ul>
            </div>

            <div class="p-0 border bg-light">
              <h5>Course:</h5>
              <ul *ngIf="courseSettingForm && getObjectKeys(courseSettingForm.controls).length > 0">
                <ng-container *ngFor="let controlName of getObjectKeys(courseSettingForm.controls)">
                  <li *ngIf="controlName !== 'eventId'">
                    {{ camelCaseToNormal(controlName) }}: <b>{{ getOptionTextByIdAndValue(controlName, courseSettingForm.get(controlName)?.value) }}</b>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>

          <div class="col-6  gx-0 gy-0">
            <div class="">
              <div class="p-0 border bg-light">
                <h5>Calibration Details:</h5>
                <ul>
                  <ng-container *ngFor="let controlName of getObjectKeys(calibrationForm.controls)">
                    <li *ngIf="controlName !== 'eventId'">
                      {{ camelCaseToNormal(controlName) }}: <b>{{ calibrationForm.get(controlName)?.value }}</b>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="">
              <div class="p-0 border bg-light">
                <h5>Dynamic Details:</h5>
                <ul>
                  <ng-container *ngFor="let controlName of getObjectKeys(dynamicForm.controls)">
                    <li *ngIf="controlName !== 'eventId'">
                      {{ camelCaseToNormal(controlName) }}: <b>{{ dynamicForm.get(controlName)?.value }}</b>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="m-3" style="display:flex;justify-content:flex-end">
        <button class="btn btn-outline-light" mat-button matStepperPrevious>Back</button>
        <button class="btn btn-outline-primary" mat-button (click)="onConfirm()">SAVE</button>
      </div>
    </mat-step>
  </mat-stepper>

</div>
