<div class="container-fluid" style="padding: 16px; width: 80vw;">
  <div class="row">
    <div class="col-sm-4">
      <h3>Teams</h3>
      <div>
        <button [disabled]="checkMatchMax()" class="btn btn-sm xbtn-primary" (click)="setTeamMatch()">Set Match</button>
      </div>
      <div *ngFor="let team of startingList">
        <mat-checkbox (change)="selectTeam($event.checked, team)" [checked]="isTeamIn(team)" style="margin:6px;">
          {{team.teamName}}
        </mat-checkbox>
      </div>

    </div>
    <div class="col-sm-4">
      <h3>Team Matches</h3>
      <div *ngFor="let match of matchedTeamList" style="margin:6px;">
        <mat-checkbox (change)="selectMatch($event.checked, match)">
          {{match.teamA.teamName}} VS {{match.teamB.teamName}}
        </mat-checkbox>
        
      </div>
    </div>
    <div class="col-sm-4">
      <h3>Player Matches</h3>
      <div *ngIf="playerTeamAHolder.length > 0">
        <a class="btn btn-sm xbtn-primary" (click)="setTeamMatch()">Set Match</a>
      </div>
      <div class="row">
        <div class="col-6">
          <span></span>
          <div *ngFor="let a of playerTeamAHolder">
            <mat-checkbox>
              {{a.playerInfo.email}}
            </mat-checkbox>
          </div>
        </div>
        <div class="col-6">
          <span></span>
          <div *ngFor="let b of playerTeamBHolder">
            <mat-checkbox>
              {{b.playerInfo.email}}
            </mat-checkbox>
          </div>
        </div>
      </div>
  
    </div>
  </div>
</div>


