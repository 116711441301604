import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DirectCommunicationRequest, ScoreValidationRequest, ScorecardViewRequest, TeamScorecardValidationRequest, TeamScorecardViewRequest } from '../../../../interfaces';
import { map } from 'rxjs';
import { RoundService } from '../round.service';
import { UserServiceService } from '../../../../user-service.service';
import { CommunicationComponent } from '../../../../communication/communication.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-team-round-recap',
  templateUrl: './team-round-recap.component.html',
  styleUrls: ['./team-round-recap.component.css']
})
export class TeamRoundRecapComponent {
  public isLoading: boolean;
  public sortedHoleScores: { key: number, value: any }[];
  public details: any;
  form: FormGroup;
  public roundData: any;
  public bestBallRoundData: any;

  constructor(private fb: FormBuilder, public roundService: RoundService, private _snackBar: MatSnackBar, private userService: UserServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TeamScorecardViewRequest, public dialog: MatDialog, private dialogRef: MatDialogRef<TeamRoundRecapComponent>,
    private cd: ChangeDetectorRef) {
    this.isLoading = true
    this.details = this.data

    //check for best ball results
    if (this.data.gameMode === 3) {
      this.roundService.getTeamRoundStrokes_BestBall(this.details.roundSyncId, this.details.referenceId, this.details.userSyncIds).subscribe(x => {
        this.bestBallRoundData = x
        console.log("round data here ", x)
      }, err => { }, () => { })
    }
    else {
      this.roundService.getTeamRoundStrokes_Scramble(this.details.roundSyncId, this.details.referenceId, this.details.userSyncIds).subscribe(x => {
        this.details.allStrokes = x.allStrokes
        this.roundData = x
        console.log("round data here ", this.roundData)
      }, err => { }, () => { })
    }

    const subscription = this.dialogRef.beforeClosed().subscribe(() => {
      subscription.unsubscribe();  // Unsubscribe before closing the dialog
      this.dialogRef.close(this.details);
    });

    this.form = this.fb.group({
      simProvidedScore: [this.details.simProvidedScore > 0 ? this.details.simProvidedScore : this.details.roundGrossScore],
      validationInput: [this.details.overrideScore ? this.details.overrideScore : 0],
      messageArea: [{ value: this.details.messageArea ? this.details.messageArea : '', disabled: true }],
      submitButton: [false] 
    });
    this.form.get('validationInput')?.valueChanges.subscribe(val => {
      const input1Value = this.form.get('simProvidedScore')?.value;
      if (val !== input1Value) {
        this.form.get('messageArea')?.enable();
      } else {
        this.form.get('messageArea')?.disable();
      }

      this.updateSubmitButtonState();
    });

    // Subscribe to value changes of 'simProvidedScore' (if needed)
    this.form.get('simProvidedScore')?.valueChanges.subscribe(() => {
      // Update the submit button state when 'simProvidedScore' changes (if needed)
      this.updateSubmitButtonState();
    });
    //this.form.get('simProvidedScore')?.disable();

    this.details = this.data
    if (this.details.roundVerified) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    // need to add a check here for the tournament type   stramble or best ball.



    this.sortedHoleScores = this.details.eligibleForManual ? [] : this.sortHoleScores()
    this.isLoading = false
  }

  sortHoleScores(): { key: number, value: any }[] {
    if (!this.roundData || !this.roundData.scorecard) {
      return [];
    }

    const scores = this.roundData.scorecard;
    return Object.entries(scores)
      .map(([key, value]) => ({ key: +key, value }))
      .sort((a, b) => a.key - b.key);
  }

  getParForHole(holeNumber: number): number {
    const hole = this.details.course.courseHoles.find((h: any) => h.holeNumber === holeNumber);
    return hole ? hole.par : 0;
  }

  sortHoleScoresForTeamMember(scores: any): { key: number, value: any } [] {
  return Object.entries(scores)
    .map(([key, value]) => ({ key: +key, value }))
    .sort((a, b) => a.key - b.key);
  }

  calculateCardScore(card: any) {
    let total = 0;
    for (let key in card) {
      if (card.hasOwnProperty(key)) {
        total += card[key];
      }
    }
    return total;
  }

  calculateTotalBestBallScore() {
    let totalScore = 0;
    this.bestBallRoundData?.teamResults.forEach((result:any) => {
      if (result.scorecard) {
        totalScore += this.calculateCardScore(result.scorecard);
      }
    });
    return totalScore;
}

  getEmailFromId(id: number): string | undefined {
    for (const entry of this.data.membersIdsToEmails) {
      if (entry[id]) {
        return entry[id];
      }
    }
    return undefined;
  }

  updateSubmitButtonState() {
    const val = this.form.get('validationInput')?.value;
    const input1Value = this.form.get('simProvidedScore')?.value;

    if (this.details.eligibleForManual) {
      this.form.get('submitButton')?.setValue(val !== input1Value);
    } else {
      this.form.get('submitButton')?.setValue(false); // Change this to your specific condition
    }
  }

  verifyAccess(roleRequired: string): boolean {
    return this.userService.verifyUserRole(roleRequired);
  }

  onSubmit() {
    this.isLoading = true;
    this.cd.markForCheck();
    var request: TeamScorecardValidationRequest = {
        referenceId: this.details.referenceId,
        simProvidedScore: this.details.simProvidedScore,
        overrideMessage: this.form.controls['messageArea'].value,
        validatedBy: '',
        emails: this.data.emails.join(", "),
        overrideRoundScore: this.form.controls['validationInput'].value,
        teamId: this.details.teamId,
        tournamentId: this.details.seriesTournamentId,
        roundId: this.details.tournamentRoundId
    }

    this.roundService.postValidateTeamTournamentRound(request).subscribe(x => {
      if (x) {
        this.form.disable()
        this.details.roundVerified = true
        this.details.roundGrossScore = this.form.controls['validationInput'].value
        this.isLoading = false
        this._snackBar.open('Scorecard verified.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.cd.markForCheck();
      }
    }, err => {
    console.log(err)}, () => { })

  }

  discardRound() {
    if (!window.confirm('Are you sure you want to discard this round?')) {
      return;
    }

    this.isLoading = true;

    

    this.roundService.postDiscardTeamRound(this.details.teamId, this.details.seriesTournamentId, this.details.tournamentRoundId).subscribe(x => {
      if (x) {
        this._snackBar.open('Scorecard discarded. Please refresh page', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        if (!window.confirm('You have discarded the round, would like to refresh now?')) {
          return;
        }
        window.location.reload()
      }
    }, err => { }, () => {

    })
  }

  openComms(email: string) {

    var request: DirectCommunicationRequest = {
      emailTo: email,
      emailFrom: '',
      message: ''
    }

    this.dialog.open(CommunicationComponent, {
      data: request
    })
  }
}
