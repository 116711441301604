import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../../user-service.service';
import { UserSearchService } from '../../user-search/user-search.service';
import { PlayersService } from '../players.service';

@Component({
  selector: 'app-player-stats',
  templateUrl: './player-stats.component.html',
  styleUrls: ['./player-stats.component.css']
})
export class PlayerStatsComponent implements OnInit { 
  userProfile: any;
  public result: any;
  constructor(private userService: UserServiceService, private searchService: UserSearchService, private playerService: PlayersService) { }

  ngOnInit(): void {
    this.userService.getUsersCachedProfile().subscribe(x => {
      this.userProfile = x
      if (this.userProfile) {
        this.playerService.getPlayersStats(this.userProfile.syncId).subscribe(y => {
          this.result = y
          console.log(this.result)
        }, err => { console.log(err) }, () => {})
      }
    }, err => { console.log(err) }, () => { })
  }

}
