import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeriesService } from '../series.service';

@Component({
  selector: 'app-series-details',
  templateUrl: './series-details.component.html',
  styleUrls: ['./series-details.component.css']
})
export class SeriesDetailsComponent implements OnInit {
  public isLoading: boolean;
  details: any;
  constructor(private route: ActivatedRoute, private service: SeriesService) {
    this.isLoading = true;
  }

    ngOnInit(): void {
      this.route.paramMap.subscribe(params => {
        var id = Number(params.get('seriesId'))
        this.service.getSeries(id).subscribe(x => {
          this.details = x
          this.isLoading = false
        }, err => { console.log(err) }, () => { })
      }, err => { console.log(err) }, () => { });
    }

}
