<div *ngIf="isLoading" class="container-fuild">
  <div class="row" style="display:flex;justify-content:center;margin-top:5%">
    <div class="col-sm-12">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>

<div *ngIf="!isLoading" class="container-fluid p-5 morrisContainer">
  <div class="row center">
    <h1 class="disply-3" style="text-align:center">EDIT TOURNAMENT</h1>
    <h2 style="text-align:center">Editing a tournament for {{series.title}}</h2>
  </div>
  <form [formGroup]="tournamentForm" (ngSubmit)="onSubmit()">
    <input type="hidden" formControlName="id" id="id" />
    <input type="hidden" formControlName="seriesId" id="seriesId" />
    <div appearance="fill" class="form-group mb-2">
      <label>NAME</label>
      <input class="form-control" formControlName="title">
      <div *ngIf="tournamentForm.get('title')?.hasError('required')">
        Name is required
      </div>
    </div>

    <div appearance="fill" class="form-group mb-2">
      <label>ICON</label>
      <div *ngIf="this.data.tournamentImageURL">
        <img src="{{this.data.tournamentImageURL}}" style="height:100px;width:100px;"/>
      </div>
      <input class="form-control" formControlName="formFile" type="file" (change)="onFileChange($event)">
      <div *ngIf="tournamentForm.get('formFile')?.hasError('required')">
        Photo is required
      </div>
    </div>
    <div class="form-group mb-2">
      <label for="description">DESCRIPTION</label>
      <textarea class="form-control" rows="5" formControlName="description"></textarea>
    </div>
    <div class="form-group m-2">
      <label for="tournamentType">ROUND TYPE</label>
      <select formControlName="tournamentType" id="tournamentType" class="form-select">
        <option value="INDIVIDUAL">
          INDIVIDUAL
        </option>
        <option value="TEAM">
          TEAM
        </option>
      </select>
    </div>
    <div class="form-group m-2" *ngIf="typeIsSelected() && isTeamType()">
      <label for="gameMode">PLAY MODE</label>
      <select id="gameMode" class="form-control" formControlName="gameMode">
        <option value="63">Best Ball</option>
        <option value="65" *ngIf="isTeamType()">Scramble</option>
      </select>
    </div>
    <div class="col-3" *ngIf="isTeamType()">
      <div class="form-group mb-2">
        <label for="startDate">MAX TEAM MEMBERS ALLOWED</label>
        <input class="form-control" formControlName="maxTeamMemberCount" type="number" id="maxTeamMemberCount">
      </div>
    </div>
    <div class="col-3">
      <div class="form-group mb-2">
        <label for="startDate">START</label>
        <input class="form-control" formControlName="startDate" type="date" id="startDate">
      </div>
    </div>
    <div class="col-3">
      <div class="form-group mb-2">
        <label for="endDate">END</label>
        <input class="form-control" formControlName="endDate" type="date" id="endDate">
      </div>
    </div>

    <button mat-raised-button color="primary" type="submit" [disabled]="tournamentForm.invalid">SAVE</button>
  </form>
</div>
