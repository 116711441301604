<div class="row">
  <div class="col-md-12 center">
    <h1 class="display-1">XGA SERIES</h1>
  </div>
</div>
<div class="row" *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')">
  <div class="col-md-12 mb-3 p-2">
    <button class="btn btn-sm xbtn-primary" (click)="createSeries()">CREATE SERIES</button>
  </div>
</div>

<div *ngIf="!isLoading && verifyAccess('LocationManager') || verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="container-fluid">

  <mat-accordion>
    <mat-expansion-panel *ngFor="let cup of series" [expanded]="cup == series[0]">
      <mat-expansion-panel-header class="series-header">
        <mat-panel-title class="lead">
          <h1>{{cup.title}}</h1>
          <div *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" style="margin-left:16px">
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
              </svg>
            </a>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="container mt-3">
        <div class="card mb-3">
          <div class="row no-gutters">
            <div class="col-md-12" style="display:flex;justify-content:center;">
              <img src="{{cup.imageURL}}" class="card-img img-fluid" alt="Golf Tournament Image" style="width:30%">
            </div>
            <div class="col-md-12">
              <div class="card-body">
                <h2>{{cup.title}}</h2>
                <p class="card-text">{{cup.description}}</p>
                <p class="card-text">{{cup.startDate | date:'shortDate'}} - {{cup.endDate | date:'shortDate'}}</p>
                <button *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="btn btn-sm xbtn-primary"  (click)="createTournament(cup)">CREATE TOURNAMENT</button>
              </div>

            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="center">

                <h4 class="display-4">TOURNAMENTS</h4>
              </div>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let tour of cup.tournamentRequests">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="xSubTitle">
                      <h4>{{tour.title}}</h4>
                      <div *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="m-2">
                        <a (click)="editTournament(tour, cup.id)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                          </svg>
                        </a>
                      </div>
                    </mat-panel-title>
                    <mat-panel-description class="xSubTitle">
                      <h4>
                        {{tour.startDate | date:'shortDate'}} - {{tour.endDate | date:'shortDate'}}
                        <ng-container *ngIf="tour.isTeamEvent && tour.gameMode === 1"> Team Best Ball Event</ng-container>
                        <ng-container *ngIf="tour.isTeamEvent && tour.gameMode === 2"> Team Scramble Event</ng-container>
                      </h4>
                      

                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="container mt-3">
                    <div class="card mb-3">
                      <div class="row m-5">
                        <div class="col-md-12 center">
                          <img src="{{tour.tournamentImageURL}}" class="card-img img-fluid" alt="Golf Tournament Image" style="width:auto">
                          <div class="card-img-overlay">
                            <div>
                              <div style="display:flex;justify-content:flex-start">
                                <h4 class="card-title">{{tour.title}}</h4>
                              </div>
                              <div style="display:flex;justify-content:flex-end">
                                <button
                                        *ngIf="tour.isTeamEvent && displayTeamRegisterButton(tour)"
                                        class="btn btn-sm xbtn-primary"
                                        (click)="signUp(tour, tour.id, cup.id)">REGISTER TEAM</button>
                                <button *ngIf="!tour.isTeamEvent" class="btn btn-sm xbtn-primary" (click)="signUp(tour, tour.id, cup.id)">REGISTER INDIVIDUAL</button>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card-body">
                            <p class="card-text">{{tour.description}}</p>
                          </div>
                          
                        </div>
  
                        <div class="col-md-12">
                          <div class="card-body">
                  

                            <div class="card-text">
                              <h5>ROUNDS:</h5>
                              <a *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="btn btn-sm xbtn-primary" (click)="createRound(tour)">CREATE ROUND</a>
                              <table class="table table-sm">
                                <thead>
                                  <tr>
                                    <th scope="col">TITLE</th>
                                    <th scope="col">DATES</th>

                                    <th scope="col"></th>
                                    <th *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')"></th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let round of tour.tournamentRounds">
                                    <td *ngIf="tour.isTeamEvent"><a (click)="openTournamentRoundInfo(round, cup.id, tour.id, tour.gameMode)">{{round.title}}</a></td>
                                    <td *ngIf="!tour.isTeamEvent && (verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin'))"><a [routerLink]="['/Series', cup.id, 'Tournament', tour.id, 'Round', round.id]">{{round.title}}</a></td>
                                    <td *ngIf="!tour.isTeamEvent && verifyAccess('LocationManager')"><a (click)="openTournamentRoundInfo(round, cup.id, tour.id, tour.gameMode)">{{round.title}}</a></td>
                                    <td>{{round.startDate | date:'shortDate' }} - {{round.endDate | date:'shortDate' }}</td>
                                    <td>
                                      <span *ngIf="isDateInPlay(round.startDate, round.endDate)">
                                        In Play
                                      </span>
                                    </td>
                                    <td *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')">
                                      <a (click)="editRound(round, tour.id, cup.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:5%">
    <div class="col-sm-12">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
