import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateLeagueEventDialogData, LeagueData } from '../../../interfaces';
import { LeagueComponent } from '../../league.component';
import { LeagueServiceService } from '../../league-service.service';
import { CreateLeagueTeamComponent } from '../../league-team/create-league-team/create-league-team.component';

@Component({
  selector: 'app-league-team-list',
  templateUrl: './league-team-list.component.html',
  styleUrls: ['./league-team-list.component.css']
})
export class LeagueTeamListComponent implements OnInit {
  teamsList: any;
  leagueId: number;
  constructor(public dialog: MatDialog, private dialogRef: MatDialogRef<LeagueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LeagueData, private service: LeagueServiceService) {
    this.leagueId = this.data.leagueId;
  }

  ngOnInit(): void {
    this.service.getLeagueTeams(this.data.leagueId).subscribe(x => {
      this.teamsList = x
    }, err => { }, () => { })
  }

  openCreateDialog() {
    const leagueData: LeagueData = {
      leagueId: this.data.leagueId,
      leagueTitle: this.data.leagueTitle,
      locationId: this.data.locationId,
      eventId: 0,
      eventRoundAssignment: this.data.eventRoundAssignment
    }
    const dialogRef = this.dialog.open(CreateLeagueTeamComponent, {
      data: leagueData
    })

    dialogRef.afterClosed().subscribe(result => {
      this.teamsList.push(result)
    })
  }

}
