import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoundService } from '../round.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateRoundComponent } from '../create-round/create-round.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TournamentService } from '../../tournament.service';

@Component({
  selector: 'app-edit-round',
  templateUrl: './edit-round.component.html',
  styleUrls: ['./edit-round.component.css']
})
export class EditRoundComponent implements OnInit {
  public isLinear = false;
  roundForm!: FormGroup;
  courseSettingForm!: FormGroup;
  teesForm!: FormGroup;
  calibrationForm!: FormGroup;
  dynamicForm!: FormGroup;
  tournament: any;
  public isLoading: boolean;
  public holder: any;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private service: RoundService, private tournamentService: TournamentService,
    private dialogRef: MatDialogRef<CreateRoundComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar) {
    this.isLoading = false
    this.tournament = this.data
    console.log("passed in is the tournament its self ", this.tournament)
    this.initializeForms();
    
  }

  initializeForms() {
    this.roundForm = this.fb.group({
      roundId: ['', Validators.required],
      name: ['', Validators.required],
      courseCode: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      tournamentId: ['', Validators.required],
      roundType: ['', Validators.required],
      scoringType: ['', Validators.required],
      playMode: ['', Validators.required],
      courseHoleSelection: ['', Validators.required],
      tournamentType: ['', Validators.required]
    });
    this.courseSettingForm = this.fb.group({
      //eventId: [0], // Default to empty string, replace with actual default value as needed
      fieldDistance: ['', [Validators.required]],
      greenDistance: ['', [Validators.required]],
      speedUnit: ['', [Validators.required]],
      swingView: ['', [Validators.required]],
      puttingView: ['', [Validators.required]],
      autoFlyBy: ['', [Validators.required]],
      xPlateGreen: ['', [Validators.required]],
      xPlateField: ['', [Validators.required]],
      autoBallFeed: ['', [Validators.required]],
      puttingPreview: ['', [Validators.required]],
      conceed: ['', [Validators.required]],
      obTee: ['', [Validators.required]],
      playableArea: ['', [Validators.required]],
      doublePar: ['', [Validators.required]],
      windOption: ['', [Validators.required]],
      clubSelectionInTheFridge: ['', [Validators.required]],
      swingInformation: ['', [Validators.required]],
      fieldTrajectory: ['', [Validators.required]],
      greenCondition: ['', [Validators.required]],
      puttingGrid: ['', [Validators.required]],
      rough: ['', [Validators.required]],
      greenSideBunker: ['', [Validators.required]],
      fairwayBunker: ['', [Validators.required]],
    });
    this.teesForm = this.fb.group({
      //eventId: [0],
      maleTeeColor: [0, Validators.required],
      femaleTeeColor: [0, Validators.required],
      seniorTeeColor: [0, Validators.required]
    });
    this.calibrationForm = this.fb.group({
      //eventId: [0], // Default to empty string, replace with actual default value as needed
      ballSpeed: ['', [Validators.required]], // Nullable int, default to null or ''
      puttingSpeed: ['', [Validators.required]], // Nullable int, default to null or ''
      backSpinOffset: ['', [Validators.required]], // Nullable int, default to null or ''
      sideSpinOffset: ['', [Validators.required]], // Nullable int, default to null or ''
      backSpinGain: ['', [Validators.required]], // Nullable int, default to null or ''
      sideSpinGain: ['', [Validators.required]], // Nullable int, default to null or ''
    });
    this.dynamicForm = this.fb.group({
      //eventId: [0], // Validators can be adjusted as needed
      gravity: ['', [Validators.required]], // Assuming 0 is a sane default, adjust as necessary
      rollingResistance: ['', [Validators.required]],
      ballSpeedCompensation: ['', [Validators.required]],
      puttSpeedCompensation: ['', [Validators.required]],
      sliceSpinCompensation: ['', [Validators.required]],
      hookSpinCompensation: ['', [Validators.required]],
      backSpinCompensation: ['', [Validators.required]],
      forwardSpinCompensation: ['', [Validators.required]]
    });
  }

  populateForms() {
    if (this.holder) {
      if (this.tournament) {
        this.roundForm.controls["roundId"].setValue(this.tournament.round.id)
        this.roundForm.controls["tournamentId"].setValue(this.tournament.round.tournamentId)
        this.roundForm.controls["name"].setValue(this.holder.tournament.name)
        this.roundForm.controls["courseCode"].setValue(this.holder.tournament.courseCode)
        this.roundForm.controls["courseHoleSelection"].setValue(this.holder.tournament.courseHoleSelection)
        this.roundForm.controls["playMode"].setValue(this.holder.tournament.playMode);
        if (this.tournament.isCompetitionRound) {
          this.roundForm.controls["roundType"].setValue("COMPETITION")
        }
        else {
          this.roundForm.controls["roundType"].setValue("FINAL")
        }
        if (this.tournament.isTeamEvent) {
          this.roundForm?.controls["tournamentType"].setValue("TEAM")
        } else {
          this.roundForm?.controls["tournamentType"].setValue("INDIVIDUAL")
        }
        if (this.tournament.netOnly) {
          this.roundForm?.controls["scoringType"].setValue("NET")
        }
        else { this.roundForm?.controls["scoringType"].setValue("GROSS") }
        var start = this.formatDate(this.holder.tournament.startDate)
        var end = this.formatDate(this.holder.tournament.endDate)
        this.roundForm?.controls["startDate"].setValue(start)
        this.roundForm?.controls["endDate"].setValue(end)
      }
      this.courseSettingForm.patchValue(this.holder.courseSettings);
      this.teesForm.patchValue(this.holder.tees);
      this.calibrationForm.patchValue(this.holder.calibration);
      this.dynamicForm.patchValue(this.holder.dynamics);
    }
  }

  ngOnInit(): void {

    this.initializeForms()

    this.tournamentService.getTournamentRoundDetails(this.data.round.syncId).subscribe(x => {
      this.holder = x
      this.populateForms()
      this.cd.detectChanges()
      this.isLoading = false;
    }, err => { console.log(err) }, () => { })
   
  }

  onConfirm() {
    this.isLoading = true
    this.cd.detectChanges();

    // Update the form value
    const formData = new FormData();

    const roundDetails = {
      Tournament: this.roundForm.value,
      CourseSettings: this.courseSettingForm.value,
      Calibration: this.calibrationForm.value,
      Dynamics: this.dynamicForm.value,
      Tees: this.teesForm.value // Assuming you have a form for the tees
    };
    for (const [key, value] of Object.entries(roundDetails)) {
      console.log(`${key}: `, value); // Verify the values are correct
      if (value) {
        formData.append(key, JSON.stringify(value));
      } else {
        console.error(`${key} is undefined.`);
      }
    }
    // Append properties of Challenge
    Object.entries(this.roundForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Tournament.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Tournament.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.courseSettingForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`CourseSettings.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`CourseSettings.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.calibrationForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Calibration.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Calibration.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.dynamicForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Dynamics.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Dynamics.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.teesForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Tees.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Tees.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });
    console.log("trying to create the tournament now", formData)

    this.service.postUpdateTournamentRound(formData).subscribe(x => {
      if (x) {
        this._snackBar.open('Round updated.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.dialogRef.close(x)
      }
    }, err => {
      console.log(err)
      this._snackBar.open('Error occured while trying to create round.', '', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: "failureNotice"
      });
      this.dialogRef.close()
    }, () => { })


  }

  formatDate(dateString: string): string {
    // Check if the date string is in the correct format
    if (dateString && dateString.includes("T")) {
      console.log(dateString)
      const datePart = dateString.split("T")[0]; // Extract the date part before the 'T'
      console.log("date part ", datePart)
      return datePart; // This will be in 'yyyy-MM-dd' format
    }
    return dateString; // Return as is if not in expected format
  }



  camelCaseToNormal(text: string): string {
    // First, use a regular expression to insert a space before all capital letters
    let result = text.replace(/([A-Z])/g, ' $1');
    // Then, capitalize the first letter of the resulting string
    result = result.charAt(0).toUpperCase() + result.slice(1);
    // Trim any leading spaces that might have been added by the regex replacement
    return result.trim();
  }

  getObjectKeys(obj: any): string[] {

    return Object.keys(obj);
  }

  getOptionTextByIdAndValue(selectId: string, value: string | number): string | undefined {
    // Use nativeElement to access the DOM API
    const selectElement: HTMLSelectElement = document.getElementById(selectId) as HTMLSelectElement;
    if (!selectElement) return undefined;

    const optionElement = selectElement.querySelector(`option[value="${value}"]`) as HTMLOptionElement;
    return optionElement ? optionElement.text : undefined;
  }

  isImageFile(controlName: string): boolean {
    const file = this.roundForm.get(controlName)?.value;
    return file instanceof File && file.type.startsWith('image/');
  }

}
