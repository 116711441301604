<div *ngIf="!isLoading" class="container p-5" style="width:500px">
  <div class="row">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <label>ADVANCE TO:</label>
      <mat-radio-group #radioGroup="matRadioGroup" formControlName="selectedRound">
        <div *ngFor="let round of data.nextRounds" class="row p-1">
          <mat-radio-button [value]="round.id" class="col-2"></mat-radio-button>
          <label class="form-check-label col-10 mt-1">
            {{ round.courseName }} - <strong>{{ round.title }}</strong>
            <input *ngIf="radioGroup.value === round.id && round.netOnly" class="m-1" type="text" formControlName="handicap" style="margin-left: 6px;width:50px" />
          </label>
        </div>
        <div class="row p-1">
          <mat-radio-button [value]="-1" class="col-2"></mat-radio-button>
          <label class="col-10 mt-1">
            PLAYER WILL DECIDE
            <input *ngIf="radioGroup.value === -1" class="m-1" type="text" formControlName="handicap" style="margin-left: 6px;width:50px" />
          </label>
        </div>
      </mat-radio-group>
      <button class="btn btn-sm xbtn-primary" type="submit">ADVANCE</button>
    </form>
  </div>
</div>


<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
