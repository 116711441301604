
<div *ngIf="!isLoading" style="width:100%;overflow-y:scroll;height:100vh;">
  <div *ngIf="!isLoading && (verifyAccess('LocationManager') || verifyAccess('SystemAdmin'))" class="container p-2">
    <div class="row">
      <div class="col-6 p-3">
        <div class="row" *ngIf="!isLoading && (verifyAccess('SystemAdmin') || verifyAccess('LocationManager')) && details">
          <div class="col-3" *ngIf="details?.tournamentRoundRequest.closed">
            <h4 class="text-danger">ROUND CLOSED</h4>
          </div>
          <h1>{{ details?.tournamentRoundRequest.title }}</h1>
          <h2>{{ details?.tournamentRoundRequest.description }}</h2>
          <h3>{{ details?.tournamentRoundRequest.startDate | date:'shortDate' }} - {{ details?.tournamentRoundRequest.endDate | date:'shortDate' }}</h3>
        </div>
      </div>
      <div class="col-6 p-3">
        <div style="max-height:250px;overflow-y:scroll">
          <table>
            <thead>
              <tr>
                <th *ngIf="!details?.tournamentRoundRequest.isTeamEvent">REGISTERED PLAYERS YET TO PLAY</th>
                <th *ngIf="details?.tournamentRoundRequest.isTeamEvent">REGISTERED TEAMS YET TO PLAY</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let individual of details?.individualCompetitors">
                <tr class="" *ngIf="(visibleToLM(individual) || verifyAccess('SystemAdmin')) && individual.tournamentRounds == null">
                  <!-- Display the email once -->
                  <td>
                    <a (click)="openComms(individual.playersEmail)">{{ individual.playersEmail }}</a> <div *ngIf="details?.tournamentRoundRequest.netOnly && details?.tournamentRoundRequest.isFinalRound">
                      FINAL HANDICAP {{convertHandicap(individual.startingHandicap)}}
                    </div>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngFor="let team of details?.seriesTournamentTeamRounds">
                <tr class="" *ngIf="team.teamTournamentRounds == null || team.teamTournamentRounds.length === 0">
                  <!-- Display the email once -->
                  <td>
                     {{ team.teamName }} <!--<a *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="m-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                        </svg></a>-->

                                         <a (click)="createRoundManually(team)" *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="m-1">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                             <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                             <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                             <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                           </svg>
                                         </a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
       

      </div>
    </div>

    <div class="row p-2" *ngIf="!isLoading && verifyAccess('SystemAdmin')">
      <!--<div class="col-3">
        <a class="xbtn-primary">EDIT</a>
      </div>-->
      <div  class="col-3" *ngIf="!details?.tournamentRoundRequest.closed">
        <a (click)="closeRound(details?.tournamentRoundRequest)"  class="btn xbtn-primary">CLOSE ROUND</a>
      </div>
      <!--<div *ngIf="!details.displayLeaderboard && details?.tournamentRoundRequest.isFinalRound" class="col-3">
        <a class="btn xbtn-primary" (click)="publishLeaderboard()">PUBLISH LEADERBOARD</a>
      </div>
      <div *ngIf="details.displayLeaderboard && details?.tournamentRoundRequest.isFinalRound" class="col-3">
        <a  class="btn xbtn-primary">LEADERBOARD</a>
      </div>-->
    </div>
  </div>

  <div *ngIf="!isLoading && (verifyAccess('LocationManager') || verifyAccess('SystemAdmin'))" class="container">
    <div class="row">
      <table class="table table-hover table-striped"> <!--matSort (matSortChange)="sortData($event)"-->
        <thead>
          <tr>
            <th class="lead" *ngIf="!details?.tournamentRoundRequest.isTeamEvent"> <!--mat-sort-header="email"--> COMPETITOR</th>
            <th class="lead" *ngIf="details?.tournamentRoundRequest.isTeamEvent">TEAM</th>
            <th class="lead">LOCATION</th>
            <th class="lead"><ng-container  *ngIf="details?.tournamentRoundRequest.isTeamEvent">TEAM NAME(S) & </ng-container>ROUNDS</th>
            <th class="lead">STATUS</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="details?.tournamentRoundRequest.isTeamEvent; else individualEventBody">
            <ng-container *ngFor="let team of details?.seriesTournamentTeamRounds">
              <ng-container *ngIf="teamRoundVisibleToLM(team)">
                <tr class="table-dark">
                  <td>
                    <ng-container *ngFor="let email of team.memberEmails">
                      <a (click)="openComms(email)" style="color:white;font-size:.9em">{{email}}</a><br/>
                    </ng-container>
                    <div>(
                      <ng-container *ngFor="let name of team.displayNames; let isLast = last">
                        <span style="color:white; font-size:.9em">
                          {{ name }}<span *ngIf="!isLast">, </span>
                        </span>
                      </ng-container>
                    )</div>
                  </td>
                  <td>
                  </td>
                  <td *ngIf="team.teamName != null" style="font-size:1.1em">
                    {{team.teamName}}
                  </td>
                  <td *ngIf="team.teamName == null" style="font-size:.7em">
                    <span *ngFor="let name of team.teamNameVariations">{{name}} </span>
                  </td>
                  <td>
                    <ng-container *ngIf="team.advancing && !details?.tournamentRoundRequest.isFinalRound">
                      <span><strong style="font-size:1.2em">ADVANCING</strong></span>
                    </ng-container>

                    <ng-container *ngIf="!team.advancing && !details?.tournamentRoundRequest.isFinalRound && !details?.tournamentRoundRequest.closed && team.scoreCardVerified">
                      <button *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="btn btn-sm xbtn-primary" (click)="openTeamAdvancementScreen(team, details?.tournamentRoundRequest)">Advance To Finals</button>
                    </ng-container>
                  </td>
                </tr>
                <tr *ngFor="let round of team.teamTournamentRounds">
                  <td><a (click)="viewTeamCard(team, round)">{{round.join | date:'short'}}</a></td>
                  <td>{{round.location}}</td>
                  
                  <td>
                    <ng-container class="p-2" *ngIf="round.mulliganUsed">
                      <h4 class="text-warning" style="margin-top:10px;text-align:center;">MULLIGAN USED</h4>
                    </ng-container>
                    <div style="display:flex;" *ngIf="round.roundScores != null">
                      <ng-container *ngIf="round.roundGrossScore != null ; else simScoreArea">
                        <div style="display:block;margin:6px;padding:6px;font-weight:bold;font-size:1.7em">
                          <ng-container *ngIf="details.gameMode === 3 || details.gameMode === 0 ; else otherScoreDisplay">
                            {{ round.totalScore }}
                          </ng-container>
                          <ng-template #otherScoreDisplay>
                            {{ round.roundGrossScore }}
                          </ng-template>
                        </div>
                      </ng-container>
                      <ng-template #simScoreArea>
                        <span style="display:block;margin:6px;padding:6px;font-weight:bold;font-size:1.7em">{{ round.simProvidedRoundScore }}</span>
                      </ng-template>

                    </div>



                    <div *ngIf="round.roundScores == null">
                      <span>MANUALLY UPLOADED</span>
                      <div style="display:flex;justify-content:flex-end;padding:6px;padding:6px;font-weight:bold;font-size:1.7em">
                        {{ round.roundGrossScore }}
                      </div>
                    </div>
                  </td>
                  <td>

                    <ng-container *ngIf="team.scoreCardVerified && round.scorecardImageUrl != null"><span class="lead" style="color:green">VERIFIED</span></ng-container>
                    <ng-container *ngIf="round.scorecardImageUrl != null">
                      <ng-container *ngIf="!team.scoreCardVerified">
                        <span class="lead">AWAITING VERIFICATION</span>
                      </ng-container>
                      <img (click)="viewTeamCard(team, round)" src="{{round.scorecardImageUrl}}" class="img-thumbnail" style="cursor:pointer" [ngStyle]="{'border': team.scoreCardVerified ? '4px solid green' : '4px solid red'}" />
                    </ng-container>

                    <button (click)="openTeamScorecardUploadScreen(round, team)" *ngIf="!displayTeamUpload(team.teamTournamentRounds) && !team.scoreCardVerified" class="btn btn-sm xbtn-primary">Upload Scorecard</button>

                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #individualEventBody>
          <!--<tr colspan="4" class="w-100">
              <mat-paginator [length]="details?.individualCompetitors.length"
                             [pageSize]="10"
                             [pageSizeOptions]="[5, 10, 25, 100]"
                             style="width:100%">
              </mat-paginator>
            </tr>-->
            
            <ng-container *ngFor="let individual of details?.individualCompetitors">

              <tr class="table-dark" *ngIf="(visibleToLM(individual) || verifyAccess('SystemAdmin')) && individual.tournamentRounds != null">
                <!-- Display the email once -->
                <td>
                  <a (click)="openComms(individual.playersEmail)">{{ individual.playersEmail }}</a> <div *ngIf="details?.tournamentRoundRequest.netOnly && details?.tournamentRoundRequest.isFinalRound">
                    FINAL HANDICAP {{convertHandicap(individual.startingHandicap)}}
                  </div>
                </td>
                <td></td>
                <td>
                </td>
                <td>
                  <ng-container *ngIf="individual.roundSyncId > 0 && individual.advancing && !details?.tournamentRoundRequest.isFinalRound">
                    <!--we then want to check if they have the advancing if true show they are advancing.-->
                    <span><strong style="font-size:1.2em">ADVANCING</strong></span>
                  </ng-container>
                  <!-- Display the "Advance" button for the lowest scoring round -->
                  <ng-container *ngIf="individual.tournamentRounds != null && !details?.tournamentRoundRequest.isFinalRound && !individual.advancing && hasVerifiedScorecard(individual) && !details?.tournamentRoundRequest.closed">
                    <button *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" class="btn btn-sm xbtn-primary" (click)="openAdvancementScreen(individual)">Advance To Finals</button>
                  </ng-container>
                  <!-- ... your other conditions for displaying messages ... -->
                </td>
              </tr>
              <!-- Sort the rounds by score and then display them -->
              <ng-container *ngFor="let round of individual.tournamentRounds; let i = index">
                <tr [class.table-secondary]="i % 2 === 0" *ngIf="visibleToLM(individual) || verifyAccess('SystemAdmin')">
                  <td style="text-align:center">
                    <a (click)="viewCard(individual, round)">{{round.created | date:'short'}}</a>
                  </td>
                  <td>{{round.location}}</td>
                  <td>
                    <div style="display:flex;">
                      <!-- ... your code for displaying hole details ... -->
                      <div style="border-right: black solid 1px; border-top:1px solid black; border-left: black solid 1px">
                        <div style="border-bottom: black solid 1px;text-align:center">
                          Hole
                        </div>
                        <div style="border-bottom: black solid 1px;text-align:center">
                          Par
                        </div>
                        <div style="text-align:center;border-bottom:1px solid black;">
                          Score
                        </div>
                      </div>
                      <ng-container *ngFor="let hole of sortHoleScores(round)">
                        <div style="width:35px;border-right: black solid 1px; border-top:1px solid black; border-left: black solid 1px">
                          <div style="border-bottom: black solid 1px;text-align:center">
                            {{ hole.key }}
                          </div>
                          <div style="border-bottom: black solid 1px;text-align:center">
                            <ng-container *ngFor="let courseHole of details.course.courseHoles">
                              <div style="border-bottom: black solid 1px;text-align:center" *ngIf="courseHole.holeNumber == hole.key">

                                {{ courseHole.par }}
                              </div>
                            </ng-container>
                          </div>
                          <div style="text-align:center;border-bottom:1px solid black;">
                            {{ hole.value }}
                          </div>
                        </div>
                      </ng-container>

                      <div style="display:inline-block;margin:6px;padding:6px;font-weight:bold;font-size:1.7em">
                        {{ round.roundScoreTotal }}
                      </div>
                      <ng-container class="p-2" *ngIf="round.usedMulligan">
                        <h4 class="text-warning" style="margin-top:10px;text-align:center;">MULLIGAN USED</h4>
                      </ng-container>
                    </div>
                  </td>
                  <td>
                    <ng-container *ngIf="individual.tournamentRounds != null">
                      <span *ngIf="individual.scorecardImageURL != null && individual.scorecardVerifiedBy != null">Card Verified</span>
                      <ng-container *ngIf="individual.scorecardImageURL != null && individual.roundSyncId == round.roundSyncId">
                        <span *ngIf="individual.scorecardVerified" class="lead">VERIFIED</span>
                        <span *ngIf="!individual.scorecardVerified" class="lead">Awaiting Verification...</span>
                        <img class="img-thumbnail" style="cursor:pointer" (click)="viewCard(individual, round)" src="{{individual.scorecardImageURL}}" [ngStyle]="{'border': individual.scorecardVerified ? '4px solid green' : '4px solid red'}" />
                      </ng-container>
                      <ng-container *ngIf="!details?.tournamentRoundRequest.closed && !hasVerifiedScorecard(individual) && individual.scorecardImageURL == null && individual.roundSyncId != round.roundSyncId">
                        <button *ngIf="!individual.scorecardVerified" class="btn btn-sm xbtn-primary" (click)="openScorecardUploadScreen(round, individual)">Upload Scorecard</button>
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>

              </ng-container>

            </ng-container>


          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
  </div>

  <div *ngIf="isLoading" class="container">
    <div class="row" style="display:flex;justify-content:center;margin-top:13%">
      <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  </div>
