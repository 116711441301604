import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TournamentService } from '../tournament.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-tournament',
  templateUrl: './edit-tournament.component.html',
  styleUrls: ['./edit-tournament.component.css']
})
export class EditTournamentComponent implements OnInit {
  tournamentForm: FormGroup;
  series: any
  public isLoading: boolean;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<EditTournamentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private service: TournamentService,
    private _snackBar: MatSnackBar) {
    this.isLoading = true
    this.series = this.data
    this.tournamentForm = this.fb.group({
      id: [this.data.id],
      title: [this.data.title, Validators.required],
      description: [this.data.description],
      seriesId: [this.data.seriesId, Validators.required],
      isTeamEvent: [this.data.isTeamEvent],
      //tournament Icon
      formFile: [''],
      startDate: [this.formatDate(this.data.startDate), Validators.required],
      endDate: [this.formatDate(this.data.endDate), Validators.required],
      //rulesId: ['', Validators.required],
      tournamentType: [{value:'', disabled:true}, Validators.required],

      maxTeamMemberCount: [this.data.maxTeamMemberCount],
      userMakingRequest: [''],
      gameMode: [{ value: this.data.gameMode, disabled: true }, Validators.required],

    });
  }

  ngOnInit(): void {
    //YOU SET THE TOURNAMENT TYPE HERE
    if (this.data.isTeamEvent) {
      this.tournamentForm.controls["tournamentType"].setValue("TEAM")
    } else {
      this.tournamentForm.controls["tournamentType"].setValue("INDIVIDUAL")
    }
    this.isLoading = false;
  }

  isTeamType(): boolean {
    return this.tournamentForm.get('tournamentType')?.value === 'TEAM';
  }

  typeIsSelected(): boolean {
    return (this.tournamentForm.get('tournamentType')?.value === 'TEAM' ||
      this.tournamentForm.get('tournamentType')?.value === 'INDIVIDUAL')
  }


  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.tournamentForm.patchValue({
        formFile: file
      });
      this.cd.markForCheck();
    }
  }

  formatDate(date: any): string {
    const d = new Date(date);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }

  onSubmit() {
    this.isLoading = true
    if (this.tournamentForm.valid) {
      const formData = new FormData();
      formData.append('id', this.tournamentForm.get('id')?.value);
      formData.append('title', this.tournamentForm.get('title')?.value);
      formData.append('description', this.tournamentForm.get('description')?.value);
      formData.append('seriesId', this.tournamentForm.get('seriesId')?.value);
      formData.append('isTeamEvent', this.tournamentForm.get('tournamentType')?.value == 'TEAM' ? 'true' : 'false')
      formData.append('startDate', this.tournamentForm.get('startDate')?.value);
      formData.append('endDate', this.tournamentForm.get('endDate')?.value);
      formData.append('tournamentType', this.tournamentForm.get('tournamentType')?.value);
      formData.append('maxTeamMemberCount', this.tournamentForm.get('maxTeamMemberCount')?.value);
      formData.append('formFile', this.tournamentForm.get('formFile')?.value);
      formData.append('gameMode', this.tournamentForm.get('gameMode')?.value);

      this.service.updateTournament(formData).subscribe(x => {
        this._snackBar.open('Tournamnet updated successfully!', 'Close', { duration: 3000 });
        this.dialogRef.close(x);

      }, err => {
        console.log(err)
        this.dialogRef.close();
        this._snackBar.open('Error Occured!', 'Close', { duration: 3000 });
        this.isLoading = false
      }, () => { this.isLoading = false })



    }
  }


}
