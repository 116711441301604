import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateTeamRoundRequest } from '../../../../interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoundService } from '../round.service';
import { UserServiceService } from '../../../../user-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manual-round-entry',
  templateUrl: './manual-round-entry.component.html',
  styleUrls: ['./manual-round-entry.component.css']
})
export class ManualRoundEntryComponent {
  public isLoading: boolean;
  public details: any;
  form: FormGroup;
  constructor(private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: CreateTeamRoundRequest,
  public userService: UserServiceService,
    public roundService: RoundService,
    private _snackBar: MatSnackBar, private dialogRef: MatDialogRef<ManualRoundEntryComponent>) {
    this.isLoading = false
    this.form = this.fb.group({
      file: ['', [Validators.required]],
    });
    this.details = data
  }

  onSubmit() {
    this.isLoading = true
    //this.cd.markForCheck();
    const formData = new FormData();

    formData.append('competitorId', this.data.teamId.toString())
    formData.append('roundId', this.data.tournamentRoundId.toString())
    formData.append('referenceId', '0')
    formData.append('emails', this.data.emails.join(','))
    formData.append('file', this.form.get('file')?.value)
    formData.append('location', this.data.location)
    formData.append('userSyncIds', this.data.userSyncIds.join(','))
    formData.append('tournamentId', this.data.seriesTournamentId.toString())
    formData.append('simProvidedScore', '0')
    formData.append('seriesId', this.data.seriesId.toString())
    formData.append('teamName', this.data.teamName)
    formData.append('manualEntry', "true")
    this.roundService.uploadScoreCardImageForRound(formData).subscribe(response => {
      console.log(response)
      if (response) {
        this._snackBar.open('Manually added round successfully.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
      }
      this.dialogRef.close(response)
    }, err => {
      console.log(err)
      this._snackBar.open('An error has occured. Please try again later.', '', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: "failureNotice"
      });
    }, () => { });
  }


  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        file: file
      });
    }
  }

  verifyAccess(roleRequired: string) {
    return this.userService.verifyUserRole(roleRequired);
  }

}
