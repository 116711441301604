<div *ngIf="!isLoading" class="container-fluid">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">SIMULATORS</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <select (change)="onLocationChange($event)">
        <option *ngFor="let location of userLocations" [value]="location.id">{{ location.name }}</option>
      </select>

    </div>
  </div>
  <div class="row center" *ngIf="selectedLocation.simulators?.length > 0">
    <h1> {{selectedLocation.name}} </h1>
    <h2>SIMULATOR MACHINE HEALTH</h2>
  </div>
  <div class="row p-4" *ngIf="selectedLocation.simulators?.length > 0">
    <div *ngFor="let sim of selectedLocation.simulators">
      <div class="card" style="width: 18rem;" [ngStyle]="{'border': sim.cpuUsage > 0 ? '4px solid green' : '4px solid red'}">
        <div class="card-body">
          <p class="card-title" style="position:relative">{{sim.handle}} | BAY {{sim.bay}}</p>
          <p class="card-text fw-bolder">
            STATUS:
            <span *ngIf="sim.cpuUsage === 0 ">TRYING TO CONNECT...</span>
            <span *ngIf="sim.cpuUsage > 0 ">CONNECTED</span>
          </p>
          <p class="card-text fw-bolder">CPU: {{sim.cpuUsage}}%</p>
          <p class="card-text fw-bolder">RAM: {{sim.ramUsage}}%</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="selectedLocation.simulators?.length === 0">
    No Simulators established for Location in this system yet.
  </div>
</div>

  <div *ngIf="isLoading" class="container">
    <div class="row" style="display:flex;justify-content:center;margin-top:5%">
      <div class="col-sm-12">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  </div>
