import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../../user-service.service';
import { PlayersService } from '../players.service';
import { TournamentRoundViewRequest } from '../../interfaces';

@Component({
  selector: 'app-player-series-view',
  templateUrl: './player-series-view.component.html',
  styleUrls: ['./player-series-view.component.css']
})
export class PlayerSeriesViewComponent implements OnInit {
  userProfile: any;
  series: any
  seriesCompetitorTournamentRounds: any;
  finalObj: any
  constructor(private userService: UserServiceService, private playersService: PlayersService) { }

  ngOnInit(): void {
    this.userService.getUsersCachedProfile().subscribe(x => {
      this.userProfile = x
      if (this.userProfile) {
        this.playersService.getPlayersSeriesData(this.userProfile.syncId).subscribe(y => {
          console.log(y)
          this.finalObj = y
          this.series = y.map((item: any) => item.seriesInfo); // Extract seriesInfo from each item
          this.seriesCompetitorTournamentRounds = y.map((item: any) => item.seriesCompetitorTournamentRounds);
          console.log("Final", this.finalObj)
          console.log("series obj", this.series)
          console.log(this.seriesCompetitorTournamentRounds)
        }, err => { console.log(err)}, () => { })
      }
      
    }, err => { console.log(err) }, () => { })
  }


  getTwoRoundsExcluding(tournamentId: number, roundId: number) {
    // Find the specific tournament request using the provided tournamentId
    const tournament = this.series.find((seriesItem: any) => seriesItem.tournamentRequests.some((tr: any) => tr.id === tournamentId))
      .tournamentRequests.find((tr: any) => tr.id === tournamentId);

    if (!tournament) {
      return [];
    }

    // Filter rounds not equal to roundId and take the first two
    const filteredRounds = tournament.tournamentRounds.filter((round: any) => round.id !== roundId);
    return filteredRounds.slice(0, 2);
  }


  openTournamentRoundInfo(round: any, seriesId: number, tournamentId: number) {
    var nextRounds = this.getTwoRoundsExcluding(round.tournamentId, round.id)
    var request: TournamentRoundViewRequest = {
        round: round,
        nextRounds: nextRounds,
        seriesId: seriesId,
        tournamentId: tournamentId,
        gameMode: 0
    }

  }

  isDateInPlay(startDate: Date | string, endDate: Date | string): boolean {
    if (typeof startDate === 'string') {
      startDate = new Date(startDate);
    }
    if (typeof endDate === 'string') {
      endDate = new Date(endDate);
    }

    if (!(startDate instanceof Date) || !(endDate instanceof Date) || isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error('Invalid date provided:', startDate, endDate);
      return false;
    }

    const currentDate = new Date();
    const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const strippedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const strippedEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    return strippedCurrentDate >= strippedStartDate && strippedCurrentDate <= strippedEndDate;
  }

}
