import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateSeriesCompetitorRequest, CreateSeriesTournamentTeamRequest } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SeriesService {

  constructor(private http: HttpClient) { }

  getAllSeries(): Observable<any> {
    return this.http.get<any>('/api/series/getAllSeries')
  }

  getSeries(id: number): Observable<any> {
    return this.http.get<any>('api/series/getSeries', { params: { id: id.toString() } })
  }

  createSeriesCompetitor(playerRequest: CreateSeriesCompetitorRequest): Observable<any> {
    return this.http.post<any>('api/series/createSeriesCompetitor', playerRequest)
  }

  createSeries(playerRequest: any): Observable<any> {
    return this.http.post<any>('api/series/createSeries', playerRequest)
  }
}
