import { Component } from '@angular/core';

@Component({
  selector: 'app-media-items',
  templateUrl: './media-items.component.html',
  styleUrls: ['./media-items.component.css']
})
export class MediaItemsComponent {

}
