<div *ngIf="!isLoading" class="container" style="padding:16px;">
  <div class="row center">
    <div class="col-md-12">
      <h1 class="display-6" style="text-align:center;">UPDATING MEDIA ITEM</h1>
      <span class="lead">PLEASE NOTE, YOU ARE GOING TO BE REPLACING THIS MEDIA ITEM:</span>
      <img src="{{data.mediaUrl}}" height="200" />
    </div>
  </div>
  <div class="row">
    <form [formGroup]="mediaForm" (ngSubmit)="onSubmit()">
      <!-- ... other HTML elements ... -->
      <input type="hidden" formControlName="id" />
      <div class="form-group">

        <input type="file" (change)="onFileChange($event)" class="form-control" required />
      </div>

      <button class="m-3 btn btn-lg xbtn-primary" type="submit">REPLACE</button>
    </form>
  </div>
</div>

<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
