
<div *ngIf="!isLoading" class="container">
  <div class="row">
    <div class="col-md-4">
      <a [routerLink]="['/Series']">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
        </svg>
        Back
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3>{{details.title}}</h3>
      <span class="lead">{{details.description}}</span>
    </div>
    <div>
      {{details.startDate | date:'medium'}}-{{details.endDate  | date:'medium'}}
    </div>
    <div>
      <img src="{{details.imageURL}}" height="100"/>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-12">
      <h6>TOURNAMENTS</h6>
    </div>
    <div class="col-md-12">
      <a asp-page="Create" class="btn btn-sm xbtn-primary">CREATE NEW</a>
    </div>
  </div>
  <div class="row">
    <table class="table table-hover ">
      <thead>
        <tr>
          <th class="lead">
            TITLE
          </th>
          <th class="lead">
            START
          </th>
          <th class="lead">
            END
          </th>
          <th class="lead">
            IMAGE
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let tournament of details.tournamentRequests">
          <td>
            <a [routerLink]="['/Series/Tournament', tournament.id]">
              {{tournament.title}}
            </a>
          </td>
          <td>
            {{tournament.startDate}}
          </td>
          <td>
            {{tournament.endDate}}
          </td>
          <td>
          </td>


          <td>
            <a>Edit</a> |
            <a>Details</a> |
            <a>Delete</a>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</div>

<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:33%">
    <div class="col-sm-12">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
