import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaItemsService } from '../media-items.service';

@Component({
  selector: 'app-update-media-item',
  templateUrl: './update-media-item.component.html',
  styleUrls: ['./update-media-item.component.css']
})
export class UpdateMediaItemComponent implements OnInit {
  mediaForm: FormGroup;
  public isLoading: boolean;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef, private dialogRef: MatDialogRef<UpdateMediaItemComponent>,
    private service: MediaItemsService, private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,)
  {
    console.log(this.data)
    this.isLoading = true
    this.mediaForm = this.fb.group({
      id:[this.data.id, Validators.required],
      icon: ['', Validators.required]
    })
  }

    ngOnInit(): void {
      this.isLoading = false;
    }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.mediaForm.patchValue({
        icon: file
      });
    }
  }

  onSubmit() {
    this.isLoading = true;
    const formData = new FormData();
    Object.entries(this.mediaForm.value).forEach(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`${key}`, value);
      }
      else {
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });
    this.service.postUpdateMediaItem(formData).subscribe(x => {
      if (x) {
        this._snackBar.open('Media Item updated.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.dialogRef.close(x);
      }
    }, err => { console.log(err) }, () => { })
  }

}
