import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SeriesCompetitorAdvancementRequst, TeamAdvancementRequest, TeamTournamentAdvancementRequest } from '../../../../interfaces';
import { TournamentService } from '../../tournament.service';

@Component({
  selector: 'app-team-advancement-confirm',
  templateUrl: './team-advancement-confirm.component.html',
  styleUrls: ['./team-advancement-confirm.component.css']
})
export class TeamAdvancementConfirmComponent implements OnInit {
  
  public isLoading: boolean;
  form: FormGroup;
  constructor(private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: TeamAdvancementRequest,
    public dialog: MatDialog, private dialogRef: MatDialogRef<TeamAdvancementConfirmComponent>, private service: TournamentService) {
    console.log("opening team advancement", this.data)
    this.isLoading = true
    this.form = this.fb.group({
      selectedRound: [null, Validators.required]  
    });
  }
    ngOnInit(): void {
      this.isLoading = false
  }

  onSubmit(): void {
    this.isLoading = true
    if (this.form.valid) {
      // Handle submission logic here, for example:
      var goingTo: number[] = []
      if (this.form.value.selectedRound === -1) {
        goingTo = this.data.nextRounds.map((nr: any) => nr.id);
      } else {
        goingTo.push(this.form.value.selectedRound)
      }
      var nextRoundHolder = this.data.nextRounds.find((x:any) => {
        return x.roundId == this.form.value.selectedRound
      })
      console.log(nextRoundHolder)
      var request: TeamTournamentAdvancementRequest = {
          seriesTournamentId: this.data.tournamentId,
          teamId: this.data.team.teamId,
          nextRounds: goingTo,
          approvingAdvance: '',
          pastRoundsLocation: this.data.pastRoundPlayedLocation,
          teamMembersInfo: {
              golfers: this.form.value.golfers,
              selectedRound: this.form.value.selectedRound
          },
          seriesId: 0,
          grossOnly: true,
          netOnly: false,
          teamOption: false
      }
      this.service.postAdvanceTeamInTournament(request).subscribe(x => {
        this.dialogRef.close(x); // Close the dialog

      }, err => {
        console.log(err)
      }, () => { })


    }
  }

}
