<!--<div class="container pt-1 ps-1 pe-1 pb-1">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">X-CUP</h1>
    </div>
  </div>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let cup of series" [expanded]="cup == series[0]" style="width:100%;padding:0">
      <mat-expansion-panel-header class="series-header">
        <mat-panel-title class="lead">
          <h1>{{cup.title}}</h1>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="mt-1 p-0">
        <div class="card mb-1">
          <div class="row no-gutters" style="padding:0px">
            <div class="col-md-12" style="display:flex;justify-content:center;">
              <img src="{{cup.imageURL}}" class="card-img img-fluid" alt="Golf Tournament Image" style="width:30%">
            </div>
            <div class="col-md-12">
              <div class="card-body">
                <h2>{{cup.title}}</h2>
                <p class="card-text">{{cup.description}}</p>
                <p class="card-text">{{cup.startDate | date:'shortDate'}} - {{cup.endDate | date:'shortDate'}}</p>
              </div>

            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-md-12">
              <div class="center">

                <h4 class="display-4">TOURNAMENTS</h4>
              </div>
              <mat-accordion>
                <mat-expansion-panel *ngFor="let tour of cup.tournamentRequests" style="width:100%;padding:0">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="xSubTitle">
                      <h4>{{tour.title}}</h4>
                    </mat-panel-title>
                    <mat-panel-description class="xSubTitle">
                      <h4>
                        {{tour.startDate | date:'shortDate'}} - {{tour.endDate | date:'shortDate'}}
                        <ng-container *ngIf="tour.isTeamEvent && tour.gameMode === 1"> Team Best Ball Event</ng-container>
                        <ng-container *ngIf="tour.isTeamEvent && tour.gameMode === 2"> Team Scramble Event</ng-container>
                      </h4>


                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="mt-1">
                    <div class="card mb-1">
                      <div class="row">
                        <div class="col-md-12 center">
                          <img src="{{tour.tournamentImageURL}}" class="card-img img-fluid" alt="Golf Tournament Image" style="width:auto">
                          <div class="card-img-overlay">
                            <div>-->
<!--<div style="display:flex;justify-content:flex-end">
  <button *ngIf="tour.isTeamEvent" class="btn btn-sm xbtn-primary" (click)="signUp(tour, tour.id, cup.id)">REGISTER TEAM</button>
  <button *ngIf="!tour.isTeamEvent" class="btn btn-sm xbtn-primary" (click)="signUp(tour, tour.id, cup.id)">REGISTER INDIVIDUAL</button>
</div>-->
<!--</div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card-body">
                            <h4>{{tour.title}}</h4>
                            <p class="card-text">{{tour.description}}</p>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card-body">


                            <div class="card-text">
                              <h5>ROUNDS:</h5>
                              <table class="table table-sm">
                                <thead>
                                  <tr>
                                    <th scope="col">TITLE</th>
                                    <th scope="col">DATES</th>

                                    <th scope="col"></th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let round of tour.tournamentRounds">
                                    <td>{{round.title}}</td>
                                    <td>{{round.startDate | date:'shortDate' }} - {{round.endDate | date:'shortDate' }}</td>
                                    <td>
                                      <span *ngIf="isDateInPlay(round.startDate, round.endDate)">
                                        *
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>-->


<div class="container">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">REGISTERED FOR</h1>
    </div>
  </div>
  <div *ngFor="let obj of finalObj">
        <!-- Loop through the tournamentRounds of the current tournamentRequest -->
        <div *ngFor="let tournament of obj.seriesInfo.tournamentRequests">
          <!-- Check if the tournamentRoundId matches the id of the current round -->
          <div *ngFor="let round of tournament.tournamentRounds">
            <div *ngFor="let thing of obj.seriesCompetitorTournamentRounds">
     
              <div *ngIf="thing.tournamentRoundId == round.id">
           
                  <!-- Display relevant content -->
                  <div>{{round.title}}</div>
                  <div>{{round.description}}</div>
              
              </div>
            </div>
          </div>
        </div>

   


    
  </div>
</div>

