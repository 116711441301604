<mat-sidenav-container autosize class="matdrawercontainer">
  <mat-sidenav #sidenav mode="side" [(opened)]="opened"
               style="height:100vh;background-color:white">
    <div class="sidebar" id="navSidebar">
      <button type="button" mat-button (click)="sidenav.toggle()" style="border:none;background-color:transparent;display:flex;justify-content:flex-end;">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
          <path *ngIf="opened" fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
        </svg>
      </button>
      <div class="sidebarNavItem" *ngIf="userAuthorized('LocationAdmin') || userAuthorized('SystemAdmin') || userAuthorized('LocationManager')">
        <a routerLink="/" routerLinkActive="activebutton" class="sidebarNavLink lead">
          COMMUNICATIONS
        </a>
      </div>


      <div class="sidebarNavItem" *ngIf="userAuthorized('LocationAdmin') || userAuthorized('SystemAdmin')">
        <a routerLink="/Locations" routerLinkActive="activebutton" class="sidebarNavLink lead">LOCATIONS</a>
      </div>

      <div class="sidebarNavItem" *ngIf="userAuthorized('LocationManager') || userAuthorized('SystemAdmin')">
        <a routerLink="/Series" routerLinkActive="activebutton" class="sidebarNavLink lead">TOURNAMENTS</a>
      </div>


      <div class="sidebarNavItem" *ngIf="userAuthorized('LocationAdmin') || userAuthorized('SystemAdmin')">
        <a routerLink="/Challenges" routerLinkActive="activebutton" class="sidebarNavLink lead">
          SHORT GAMES
        </a>
      </div>
      <!--<div class="sidebarNavItem" *ngIf="userAuthorized('LocationManager') || userAuthorized('SystemAdmin')">
    <a routerLink="/Leagues" routerLinkActive="activebutton" class="sidebarNavLink lead">X-LEAGUE *</a>
  </div>-->
      <!--<div class="sidebarNavItem" *ngIf="userAuthorized('LocationManager') || userAuthorized('SystemAdmin')">
    <a href="/Teams" class="sidebarNavLink lead">Teams</a>
  </div>-->



      <div class="sidebarNavItem" *ngIf="userAuthorized('SystemAdmin')">
        <a routerLink="/UserSearch" routerLinkActive="activebutton" class="sidebarNavLink lead">PLAYER SEARCH</a>
      </div>

      <!--<div class="sidebarNavItem" *ngIf="userAuthorized('LocationManagerBeta') || userAuthorized('SystemAdmin')">
        <a routerLink="/Simulators" routerLinkActive="activebutton" class="sidebarNavLink lead">SIMULATORS</a>
      </div>-->

    </div>
  </mat-sidenav>


  <div class="row" style="margin:12px;">
    <div class="col-md-2">
      <button type="button" mat-button (click)="sidenav.toggle()" style="border:none;background-color:transparent;">
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
          <path *ngIf="!opened" fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
        
        </svg>
      </button>
    </div>
  </div>

  <router-outlet></router-outlet>

</mat-sidenav-container>


