<div *ngIf="isLoading" class="container-fuild">
  <mat-progress-bar style="color:#007041" mode="query"></mat-progress-bar>
</div>

<div [class.loading-opacity]="isLoading" class="container-fuild p-2">
  <div class="row">
    <div class="col-2">
      <a routerLink="/Challenges">BACK TO LIST</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" style="text-align:center">
      <h1 class="display-3">{{challengeContainer?.name}}</h1>
      <h2 class="lead">{{challengeContainer?.startDate | date:'shortDate'}} - {{challengeContainer?.endDate | date:'shortDate'}}</h2>
    </div>
  </div>
  <div class="row p-2">
    <mat-tab-group>
      <mat-tab label="LEADERBOARD">
        <app-challenge-leaderboard [challengeId]="challengeContainer?.id" [challengeShots]="challengeContainer?.numberOfShots" [challengeObject]="challengeContainer"></app-challenge-leaderboard>
      </mat-tab>
      <mat-tab label="SETTINGS">
        <mat-tab-group>
          <mat-tab label="DETAILS">
            <div style="background-color:#eee;">
              <div class="row p-5">
                <table class="table table-striped table-bordered col-4">
                  <tbody>
                    <tr>
                      <td class="col-1"><span class="lead">NAME</span></td>
                      <td class="col-1"><span><strong>{{challengeContainer?.name}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">CHALLENGE TYPE</span></td>
                      <td><span><strong>{{challengeContainer?.challengeType}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">DISTANCE</span></td>
                      <td><span><strong>{{challengeContainer?.distance}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">DESCRIPTION</span></td>
                      <td><span><strong>{{challengeContainer?.description}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">START</span></td>
                      <td><span><strong>{{challengeContainer?.startDate}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">END</span></td>
                      <td><span><strong>{{challengeContainer?.endDate}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">MIN PARTICIPANTS</span></td>
                      <td><span><strong>{{challengeContainer?.minParticipants}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">MAX PARTICIPANTS</span></td>
                      <td><span><strong>{{challengeContainer?.maxParticipants}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead"># OF SHOTS</span></td>
                      <td><span><strong>{{challengeContainer?.numberOfShots}}</strong></span></td>
                    </tr>

                    <tr>
                      <td><span class="lead">ICON</span></td>
                      <td>
                        <img class="img-thumbnail" src="{{ challengeContainer?.iconURL }}" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab style="background-color:#eee;" label="COURSE">
            <div style="background-color:#eee;">
              <div class="row p-5">
                <table class="table table-striped table-bordered col-4">
                  <tbody>
                    <!--units-->
                    <tr>
                      <td><span class="lead">FIELD DISTANCE</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.fieldDistance}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">GREEN DISTANCE</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.greenDistance}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">SPEED UNIT</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.speedUnit}}</strong></span></td>
                    </tr>

                    <!--views-->
                    <tr>
                      <td><span class="lead">SWING VIEW</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.swingView}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">PUTTING VIEW</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.puttingView}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">AUTO FLY BY</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.autoFlyBy}}</strong></span></td>
                    </tr>


                    <!--device-->
                    <tr>
                      <td><span class="lead">X PLATE GREEN</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.xPlateGreen}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">X PLATE FIELD</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.xPlateField}}</strong></span></td>
                    </tr>
                    <tr>
                      <td class="col-1"><span class="lead">AUTO BALL FEED</span></td>
                      <td class="col-1"><span><strong>{{challengeContainer?.courseSettings?.autoBallFeed}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">PUTTING PREVIEW</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.puttingPreview}}</strong></span></td>
                    </tr>

                    <!--local-->
                    <tr>
                      <td><span class="lead">CONCEED</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.conceed}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">OB TEE</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.obTee}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">PLAYABLE AREA</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.playableArea}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">DOUBLE PAR</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.doublePar}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">WIND OPTION</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.windOption}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">FRIDGE CLUB</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.clubSelectionInTheFridge}}</strong></span></td>
                    </tr>

                    <!--display-->
                    <tr>
                      <td><span class="lead">SWING INFORMATION</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.swingInformation}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">FIELD TRAJECTORY</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.fieldTrajectory}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">GREEN CONDITION</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.greenCondition}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">PUTTING GRID</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.puttingGrid}}</strong></span></td>
                    </tr>


                    <!--trajectory-->
                    <tr>
                      <td><span class="lead">ROUGH</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.rough}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">GREEN SIDE BUNKER</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.greenSideBunker}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">FAIRWAY BUNKER</span></td>
                      <td><span><strong>{{challengeContainer?.courseSettings?.fairwayBunker}}%</strong></span></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>


          </mat-tab>
          <mat-tab label="TEES">
            <div style="background-color:#eee;">
              <div class="row p-5">
                <table class="table table-striped table-bordered col-4">
                  <tbody>
                    <tr>
                      <td><span class="lead">MALE</span></td>
                      <td><span><strong [style.color]="challengeContainer?.tees?.maleTee">{{challengeContainer?.tees?.maleTee}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">FEMALE</span></td>
                      <td><span><strong [style.color]="challengeContainer?.tees?.femaleTee">{{challengeContainer?.tees?.femaleTee}}</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">SENIOR</span></td>
                      <td><span><strong [style.color]="challengeContainer?.tees?.seniorTee">{{challengeContainer?.tees?.seniorTee}}</strong></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="CALIBRATION">
            <div style="background-color:#eee;">
              <div class="row p-5">
                <table class="table table-striped table-bordered col-4">
                  <tbody>
                    <tr>
                      <td><span class="lead">BALL SPEED</span></td>
                      <td><span><strong>{{challengeContainer?.calibration?.ballSpeed}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">PUTTING SPEED</span></td>
                      <td><span><strong>{{challengeContainer?.calibration?.puttingSpeed}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">BACK SPIN OFFSET</span></td>
                      <td><span><strong>{{challengeContainer?.calibration?.backSpinOffset}} RPM</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">SIDE SPIN OFFSET</span></td>
                      <td><span><strong>{{challengeContainer?.calibration?.sideSpinOffset}} RPM</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">BACK SPIN GAIN</span></td>
                      <td><span><strong>{{challengeContainer?.calibration?.backSpinGain}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">SIDE SPIN GAIN</span></td>
                      <td><span><strong>{{challengeContainer?.calibration?.sideSpinGain}}%</strong></span></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="DYNAMICS">
            <div style="background-color:#eee">
              <div class="row p-5">
                <table class="table table-striped table-bordered col-4">
                  <tbody>
                    <tr>
                      <td><span class="lead">GRAVITY</span></td>
                      <td><span><strong>{{challengeContainer?.dynamics?.gravity}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">BACK SPIN COMPENSATION</span></td>
                      <td><span><strong>{{challengeContainer?.dynamics?.backSpinCompensation}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">BALL SPEED COMPENSATION</span></td>
                      <td><span><strong>{{challengeContainer?.dynamics?.ballSpeedCompensation}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">FORWARD SPIN COMPENSATION</span></td>
                      <td><span><strong>{{challengeContainer?.dynamics?.forwardSpinCompensation}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">HOOK SPIN COMPENSATION</span></td>
                      <td><span><strong>{{challengeContainer?.dynamics?.hookSpinCompensation}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">PUTT SPEED COMPENSATION</span></td>
                      <td><span><strong>{{challengeContainer?.dynamics?.puttSpeedCompensation}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">ROLLING RESISTANCE</span></td>
                      <td><span><strong>{{challengeContainer?.dynamics?.rollingResistance}}%</strong></span></td>
                    </tr>
                    <tr>
                      <td><span class="lead">SLICE SPIN COMPENSATION</span></td>
                      <td><span><strong>{{challengeContainer?.dynamics?.sliceSpinCompensation}}%</strong></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>



        </mat-tab-group>
      </mat-tab>
      <mat-tab label="ANALYTICS">
        <app-challenge-analytics></app-challenge-analytics>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
