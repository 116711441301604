<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">PLAYER SEARCH</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="searchForm" (ngSubmit)="submitForm()">
        <input type="hidden" name="formType" value="form2" />
        <div class="form-floating form-group">
          <input class="form-control" aria-required="true" formControlName="email" />
          <label>Email</label>
          <span class="text-danger"></span>
        </div>
        <input type="submit" class="btn xbtn-primary" value="Search"  [disabled]="!searchForm.valid" />
      </form>
    </div>
  </div>
</div>

<div *ngIf="searching" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:15%">
    <div class="col-sm-12">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>

<div *ngIf="resultToDisplay" class="container-fluid">
  <mat-tab-group>
    <mat-tab label="User Information">
        <div class="row">
          <div class="col-4">
            <h6>Email:</h6> <h5>{{ result?.email }}</h5>
            <h6>Birthdate:</h6> <h5>{{ result?.systemUserProfile?.birthDate | date:'MM/dd/yyyy' }}</h5>
            <h6>Display Name:</h6> <h5>{{ result?.systemUserProfile?.displayName }}</h5>
          </div>
          <div class="col-4">
            <h6>Name:</h6> <h5>{{ result?.systemUserProfile?.firstName }} {{ result?.systemUserProfile?.lastName }}</h5>
            <h6>Handedness:</h6> <h5>{{ result?.systemUserProfile?.handedness }}</h5>
          </div>
        </div>

    </mat-tab>
    <mat-tab label="Golf Stats">
      <div class="card-deck">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Handicap Index</h6>
            <h5 class="card-text">{{ result?.playerStats?.handicapIndex }}</h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Holes Completed</h6>
            <h5 class="card-text">{{ result?.playerStats?.holesCompleted }}</h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Average Putts per Hole</h6>
            <h5 class="card-text">{{ result?.playerStats?.averagePuttsPerHole }}</h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Best 18-Hole Score</h6>
            <h5 class="card-text">{{ result?.playerStats?.bestEighteenHoleScore }}</h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Best 9-Hole Score</h6>
            <h5 class="card-text">{{ result?.playerStats?.bestNineHoleScore }}</h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Three Putt Percentage</h6>
            <h5 class="card-text">{{ result?.playerStats?.threePuttPercent }}</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="card-deck">
            <div class="card">
              <div class="card-body">
                <h6 class="card-title">Driving Distance (Max in Regulation)</h6>
                <h5 class="card-text">{{ result?.playerStats?.drivingDistanceMaxInRegulation }}</h5>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6 class="card-title">Driving Total Distance</h6>
                <h5 class="card-text">{{ result?.playerStats?.drivingDistanceTotalDistance }}</h5>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6 class="card-title">Driving Total Drives</h6>
                <h5 class="card-text">{{ result?.playerStats?.drivingTotalDrives }}</h5>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6 class="card-title">Driving Total Drives in Regulation</h6>
                <h5 class="card-text">{{ result?.playerStats?.drivingTotalDrivesInRegulation }}</h5>
              </div>
            </div>


            <div class="card">
              <div class="card-body">
                <h6 class="card-title">General Greens in Regulation</h6>
                <h5 class="card-text">{{ result?.playerStats?.generalGreensInRegulation }}</h5>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6 class="card-title">Putting Longest Putt Made</h6>
                <h5 class="card-text">{{ result?.playerStats?.puttingLongestPuttMade }}</h5>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6 class="card-title">Record Count</h6>
                <h5 class="card-text">{{ result?.playerStats?.recordCount }}</h5>
              </div>
            </div>

          </div>
        </div>
        <div class="col-6">
          <div class="card-deck">
            <div class="card">
              <div class="card-body">
                <h6 class="card-title">General Up/Down 30 Yards Attempts</h6>
                <h5 class="card-text">{{ result?.playerStats?.generalUpDown30YardsAttempts }}</h5>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6 class="card-title">General Up/Down 30 Yards Attempts Made</h6>
                <h5 class="card-text">{{ result?.playerStats?.generalUpDown30YardsAttemptsMade }}</h5>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6 class="card-title">General Up/Down Within 50 Yards Attempts</h6>
                <h5 class="card-text">{{ result?.playerStats?.generalUpDownWithin50YardsAttempts }}</h5>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6 class="card-title">General Up/Down Within 50 Yards Attempts Made</h6>
                <h5 class="card-text">{{ result?.playerStats?.generalUpDownWithin50YardsAttemptsMade }}</h5>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h6 class="card-title">Putting Inside 10ft Attempts</h6>
              <h5 class="card-text">{{ result?.playerStats?.puttingInside10ftAttempts }}</h5>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h6 class="card-title">Putting Inside 10ft Attempts Made</h6>
              <h5 class="card-text">{{ result?.playerStats?.puttingInside10ftAttemptsMade }}</h5>
            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <h6 class="card-title">Putting Inside 20ft Attempts</h6>
              <h5 class="card-text">{{ result?.playerStats?.puttingInside20ftAttempts }}</h5>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h6 class="card-title">Putting Inside 20ft Attempts Made</h6>
              <h5 class="card-text">{{ result?.playerStats?.puttingInside20ftAttemptsMade }}</h5>
            </div>
          </div>





          <!-- Add more cards for other stats -->
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Previous Rounds">
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            HANDICAP CALCULATOR
          </mat-panel-title>
          <mat-panel-description>
            <i>click to  {{panelOpenState ? 'close.' : 'open.'}} </i>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <h6 class="lead">TOTAL SCORE: </h6>
        <h5>{{estimateTool_Total}}</h5>
        <h6 class="lead" *ngIf="estimateTool_Selected != null">TOTAL PAR: </h6><h5 *ngIf="estimateTool_Selected != null">{{estimateTool_TotalPar}}</h5>
        <h6 class="lead" *ngIf="estimateTool_Selected != null">DIFFERENCE: </h6><h5 *ngIf="estimateTool_Selected != null">{{estimateTool_TotalDif}}</h5>
        <h6 class="lead" *ngIf="estimateTool_Selected != null">ESTIMATED HANDICAP: </h6><h5 *ngIf="estimateTool_Selected != null">{{estimateTool_Final}}</h5>
        <mat-slider *ngIf="estimateTool_Selected != null" min="1" max="100" step="1" value="100" showTickMarks discrete [displayWith]="formatLabel">
          <input matSliderThumb (input)="onInputChange($event)">
        </mat-slider>
        <h6 class="lead" *ngIf="estimateTool_Selected != null">ADJUSTED ESTIMATED HANDICAP: </h6><h5 *ngIf="estimateTool_Selected != null">{{estimateTool_Adjusted}}</h5>


      </mat-expansion-panel>
      <div class="row p-2" *ngFor="let pRound of playerPreviousRounds">
        <div class="row" style="display:inline-block;margin:6px;padding:6px;font-weight:bold;font-size:1.2em">
          <p>{{ getLocalTime(pRound.created) }}</p>
          <p>{{ pRound.course.title }}</p>
          <p>{{ pRound.roundScoreTotal }}</p>
          <input *ngIf="panelOpenState" type="checkbox" (change)="updateEstTotal(pRound, $event)">
          <div class="center">
            <div style="width: 50px; border-right: black solid 1px; border-top: 1px solid black; border-left: black solid 1px; display: inline-table; ">
              <div style="height: 50px; width:80px; padding: 9px; border-bottom: black solid 1px; text-align: center ">Hole</div>
              <div style="height: 50px; width: 80px; padding: 9px; border-bottom: black solid 1px; text-align: center ">Par</div>
              <div style="height: 50px; width: 80px; padding: 9px; text-align: center; border-bottom: 1px solid black;">Score</div>
            </div>
            <ng-container *ngFor="let hole of sortHoleScores(pRound.roundScores)">
              <div style="width: 50px; border-right: black solid 1px; border-top: 1px solid black; border-left: black solid 1px; display: inline-table;">
                <div style="height: 50px; border-bottom: black solid 1px; text-align: center; padding: 6px">{{ hole.key }}</div>
                <div style="height: 50px; border-bottom: black solid 1px; text-align: center; padding: 9px">{{ getParForHole(hole.key, pRound.course) }}</div>
                <div style="height: 50px; text-align: center; border-bottom: 1px solid black; padding: 9px">
                  <ng-container *ngIf="hole.value < getParForHole(hole.key, pRound.course) - 1">
                    <div class="bird-double-circle">
                      <div class="bird-circle">
                        <div class="inner-circle">
                          {{ hole.value }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="hole.value === getParForHole(hole.key, pRound.course) - 1">
                    <span class="bird-circle">{{ hole.value }}</span>
                  </ng-container>
                  <ng-container *ngIf="hole.value === getParForHole(hole.key, pRound.course)">
                    <span>{{ hole.value }}</span>
                  </ng-container>
                  <ng-container *ngIf="hole.value === getParForHole(hole.key, pRound.course) + 1">
                    <span class="boggy-square">{{ hole.value }}</span>
                  </ng-container>
                  <ng-container *ngIf="hole.value === getParForHole(hole.key, pRound.course) + 2">
                    <div class="">
                      <div class="outter-square">
                        <div class="boggy-square">
                          {{ hole.value }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="hole.value > getParForHole(hole.key, pRound.course) + 2">
                    <span>{{ hole.value }}</span>
                  </ng-container>
                </div>
              </div>
            </ng-container>

          </div>
        </div>

        <div class="row">
          <div class="p-5">
            <div style="max-height:400px;overflow-y:scroll;overflow-x:hidden;border:2px solid black;">
              <table style="width:100%; border-collapse:collapse;">
                <thead class="sticky-top mb-1" style="font-size: 1em; top: 0; z-index: 1000;">
                  <tr style="background-color:yellow;">
                    <th colspan="18" style="text-align:left;">* MULLIGAN</th>
                  </tr>
                  <tr class="sticky-top mb-2" style="background-color:#ccc;font-size:.75em; top: 0; z-index: 1000;height:50px;">
                    <th>HOLE</th>
                    <th>STROKE</th>
                    <th>START</th>
                    <th>CLUB</th>
                    <th>CLUB SPEED <sub>mph</sub></th>
                    <th>SWING PATH <sub>degree</sub></th>
                    <th>BALL SPEED <sub>mph</sub></th>
                    <th>SMASH FACTOR</th>
                    <th>BALL DIR <sub>degree</sub></th>
                    <th>BACK SPIN <sub>rpm</sub></th>
                    <th>SIDE SPIN <sub>rpm</sub></th>
                    <th>VERTICAL PEAK <sub>yards</sub></th>
                    <th>PEAK HORIZON <sub>yards</sub></th>
                    <th>CARRY <sub>yards</sub></th>
                    <th>ROLL <sub>yards</sub></th>
                    <th>END</th>
                    <th>DISTANCE <sub>yards</sub></th>
                    <th>LEFT TO FLAG <sub>yards</sub></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of pRound.allStrokes; let i = index">
                    <tr *ngIf="i > 0 && item.holeNumber > pRound.allStrokes[i - 1].holeNumber">
                      <td colspan="18"><hr /></td>
                    </tr>
                    <tr [ngStyle]="{'background-color': item.strokeType == 'MULLIGAN' ? 'yellow' : 'inherit'}">
                      <td>
                        <ng-container *ngIf="i === 0 || item.holeNumber !== pRound.allStrokes[i - 1].holeNumber; else emptySpace">
                          <span style="font-weight: bold; font-size: 1.5em">{{ item.holeNumber }}</span>
                        </ng-container>
                        <ng-template #emptySpace></ng-template>
                      </td>
                      <td>{{ item.strokeNumber }}</td>
                      <td>{{ item.from }}</td>
                      <td>{{ item.club }}</td>
                      <td>{{ item.clubSpeedMilesPerHour | number:'1.2-2' }}</td>
                      <td>{{ item.impactAngle | number:'1.2-2' }}</td>
                      <td>{{ item.ballSpeedMilesPerHour | number:'1.2-2' }}</td>
                      <td>{{ item.smashFactor | number:'0.2-2'}}</td>
                      <td>{{ item.launchAngle | number:'1.2-2' }}</td>
                      <td>{{ item.backSpin | number:'1.2-2' }}</td>
                      <td>{{ item.sideSpin | number:'1.2-2' }}</td>
                      <td>{{ item.maxHeight | number:'1.2-2' }}</td>
                      <td>{{ item.distanceAtMaxHeight | number:'1.2-2' }}</td>
                      <td>{{ item.carryDistanceInYards | number:'1.2-2' }}</td>
                      <td>{{ calculateRoll(item.carryDistanceInYards, item.totalDistanceInYards) | number:'1.2-2'}}</td>
                      <td>{{ item.to }}</td>
                      <td style="font-size:1.25em">{{ item.totalDistanceInYards | number:'1.2-2' }}</td>
                      <td>{{ item.distanceRemaining !== 'HOLE' ? (item.distanceRemaining | number:'1.2-2') : '' }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </mat-tab>
  </mat-tab-group>

</div>









