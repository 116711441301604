import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../../app.component';
import { LeagueServiceService } from '../league-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-league',
  templateUrl: './create-league.component.html',
  styleUrls: ['./create-league.component.css']
})
export class CreateLeagueComponent implements OnInit {
  isLoading: boolean = false;
  locationLeagueForm: FormGroup;
  public locations: any;
  constructor(private formBuilder: FormBuilder, private app: AppComponent, private leagueService: LeagueServiceService,
    private _snackBar: MatSnackBar, private router: Router) {
    this.locations = app.userProfile.assignedLocations;
    // MaxNumberOfTeamMembers
    // MaxNumberOfTeams
    
    this.locationLeagueForm = this.formBuilder.group({
      id: [0],
      locationId: [this.locations[0]?.id, Validators.required],
      title: [null, Validators.required],
      description: [null],
      scoringDescription: [null],
      totalNumberOfWeeks: [0, Validators.required],
      startDate: [null],
      isActive: [false]
    });
  }
    ngOnInit(): void {
        
  }

  onSubmit() {
    this.isLoading = true;
    if (!this.locationLeagueForm.untouched) {
      // Process the form data
      
      this.leagueService.createLocalLeague(this.locationLeagueForm.value).subscribe(x => {
        this._snackBar.open('League created.', '', {
          duration: 2000,
          verticalPosition: "top",
          horizontalPosition: "center",

        });
        this.router.navigate(['/Leagues']);
      }, err => { console.log(err) }, () => {

        
      })
    }
  }


  userAuthorized(item: string) {
    return this.app.userAuthorized(item);
  }

}
