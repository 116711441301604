<div class="container pt-1 ps-5 pe-5 pb-1">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">XGA STATS</h1>
    </div>
  </div>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Handicap Index</h5>
        <h3 class="card-text">{{ result?.handicapIndex }}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Holes Completed</h5>
        <h3 class="card-text">{{ result?.holesCompleted }}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Average Putts per Hole</h5>
        <h3 class="card-text">{{ result?.averagePuttsPerHole }}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Best 18-Hole Score</h5>
        <h3 class="card-text">{{ result?.bestEighteenHoleScore }}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Best 9-Hole Score</h5>
        <h3 class="card-text">{{ result?.bestNineHoleScore }}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Three Putt Percentage</h5>
        <h3 class="card-text">{{ result?.threePuttPercent }}</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Driving Distance (Max in Regulation)</h6>
        <h3 class="card-text">{{ result?.drivingDistanceMaxInRegulation }}</h3>
      </div>
    </div>


    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Driving Total Distance</h6>
        <h3 class="card-text">{{ result?.drivingDistanceTotalDistance }}</h3>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Driving Total Drives</h6>
        <h3 class="card-text">{{ result?.drivingTotalDrives }}</h3>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Driving Total Drives in Regulation</h6>
        <h3 class="card-text">{{ result?.drivingTotalDrivesInRegulation }}</h3>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">General Greens in Regulation</h6>
        <h3 class="card-text">{{ result?.generalGreensInRegulation }}</h3>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Putting Longest Putt Made</h6>
        <h3 class="card-text">{{ result?.puttingLongestPuttMade }}</h3>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">Record Count</h6>
        <h3 class="card-text">{{ result?.recordCount }}</h3>
      </div>
    </div>



    <div class="card">
      <div class="card-body">
        <h6 class="card-title">General Up/Down 30 Yards Attempts</h6>
        <h3 class="card-text">{{ result?.generalUpDown30YardsAttempts }}</h3>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">General Up/Down 30 Yards Attempts Made</h6>
        <h3 class="card-text">{{ result?.generalUpDown30YardsAttemptsMade }}</h3>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">General Up/Down Within 50 Yards Attempts</h6>
        <h3 class="card-text">{{ result?.generalUpDownWithin50YardsAttempts }}</h3>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="card-title">General Up/Down Within 50 Yards Attempts Made</h6>
        <h3 class="card-text">{{ result?.generalUpDownWithin50YardsAttemptsMade }}</h3>
      </div>
    </div>


  <div class="card">
    <div class="card-body">
      <h6 class="card-title">Putting Inside 10ft Attempts</h6>
      <h3 class="card-text">{{ result?.puttingInside10ftAttempts }}</h3>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <h6 class="card-title">Putting Inside 10ft Attempts Made</h6>
      <h3 class="card-text">{{ result?.puttingInside10ftAttemptsMade }}</h3>
    </div>
  </div>


  <div class="card">
    <div class="card-body">
      <h6 class="card-title">Putting Inside 20ft Attempts</h6>
      <h3 class="card-text">{{ result?.puttingInside20ftAttempts }}</h3>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <h6 class="card-title">Putting Inside 20ft Attempts Made</h6>
      <h3 class="card-text">{{ result?.puttingInside20ftAttemptsMade }}</h3>
    </div>
  </div>






</div>
</div>
