<div *ngIf="!isLoading" class="container" style="padding:16px;">
  <div class="row center">
    <div class="col-md-12">
      <h1 class="display-6" style="text-align:center;">UPLOAD SCORECARD IMAGE</h1>
    </div>
  </div>
  <div class="row">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <!-- ... other HTML elements ... -->

      <div class="form-group">
        <label>Team Name</label>
        <input type="text" class="form-control" formControlName="teamName" />
        <span *ngIf="displayNameOptions" >OPTIONS: {{nameOptions}}</span>
      </div>

      <div class="form-group">
        <input type="file" (change)="onFileChange($event)" class="form-control" required />
      </div>

      <button class="m-3 btn btn-lg xbtn-primary" type="submit">UPLOAD</button>
    </form>
  </div>
</div>

<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
