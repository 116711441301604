<div  *ngIf="!isLoading" class="container p-5">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="hidden" formControlName="locationId" />
    <div class="mb-3">
      <label for="email" class="form-label">Email</label>
      <input type="email" class="form-control" formControlName="email" />
    </div>
    <button class="btn btn-sm xbtn-primary" type="submit" [disabled]="!form.valid">Submit</button>
  </form>
</div>

<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
