<div class="container-fluid p-1">
  <div class="row">
    <div class="col-lg-6">
      <canvas #barChart></canvas>
    </div>
    <div class="col-lg-6">
      <canvas #locationChart></canvas>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <canvas #playerPieChart></canvas>
    </div>

  </div>
</div>

