import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ChallengesService } from '../challenges.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserServiceService } from '../../user-service.service';
import { Chart, registerables } from 'chart.js';
import { SharingService } from '../../sharing.service';

@Component({
  selector: 'app-challenge-analytics',
  templateUrl: './challenge-analytics.component.html',
  styleUrls: ['./challenge-analytics.component.css']
})
export class ChallengeAnalyticsComponent implements OnInit, AfterViewInit {
  attempts: any[] = [];
  

  

  


  @ViewChild('barChart', { static: true }) private chartRef!: ElementRef;
  chart: any;
  @ViewChild('locationChart', { static: true }) private locationChartRef!: ElementRef;
  locationChart: any;
  @ViewChild('playerPieChart', { static: true }) private playerPieChartRef!: ElementRef;
  playerChart: any;
  @ViewChild('pieAbandonmentChart', { static: true }) private pieAbandonmentChartRef!: ElementRef;
  abandonmentPieChart: any;

  constructor(
    private sharingService: SharingService,
    private route: ActivatedRoute,
    private userService: UserServiceService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private service: ChallengesService
  ) { }

  ngOnInit(): void {
    this.sharingService.currentData.subscribe(x => {
      this.attempts = x
      this.createChart()
      this.createLocationChart()
      this.createPieChart()
        console.log("Attempts so far", this.attempts)
    }, err => { console.log(err) }, () => { })
  }

  ngAfterViewInit(): void {
    this.createChart();
    this.createLocationChart()
    this.createPieChart()

  }

  createChart(): void {
    if (this.chartRef && Array.isArray(this.attempts)) {
      const dateCounts: { [key: string]: { attemptsTotal: number, scannedIn: number, anonoymous: number } } = {};

      this.attempts.forEach((attempt: any) => {
        const date = new Date(attempt.gameSessionPlayed).toLocaleDateString();
        if (!dateCounts[date]) {
          dateCounts[date] = {attemptsTotal: 0, scannedIn: 0, anonoymous: 0 };
        }
        dateCounts[date].attemptsTotal++;

        if (attempt.anonymousPlayerSyncId) {
          dateCounts[date].anonoymous++;
        } else if (attempt.userSyncId) {
          dateCounts[date].scannedIn++;
        }
      });

      const labels = Object.keys(dateCounts).sort((a, b) => new Date(a).getTime() - new Date(b).getTime()); // Sort the labels to ensure chronological order
      const appUsers = labels.map(label => dateCounts[label].scannedIn);
      const unknownUsers = labels.map(label => dateCounts[label].anonoymous);

      console.log("Labels: ", labels);
      console.log("Scanned-In Players: ", appUsers);
      console.log("Anonoymous Players: ", unknownUsers);

      if (this.chart) {
        this.chart.destroy();
      }

      Chart.register(...registerables);

      this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Scanned-In',
              data: appUsers,
              backgroundColor: '#007041',
              barPercentage: 0.5,
              categoryPercentage: 1.0
            },
            {
              label: 'Anoyomous',
              data: unknownUsers,
              backgroundColor: '#ffcc00',
              barPercentage: 0.5,
              categoryPercentage: 1.0
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: false,
              display: true,
              title: {
                display: true,
                text: 'Date'
              },
              ticks: {
                maxRotation: 90,
                minRotation: 45
              }
            },
            y: {
              stacked: false,
              display: true,
              title: {
                display: true,
                text: 'Number of Sessions'
              },
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.dataset.label || '';
                  const value = context.raw || 0;
                  return `${label}: ${value}`;
                }
              }
            }
          }
        }
      });
    }
  }

  createPieChart(): void {
    if (this.playerPieChartRef && Array.isArray(this.attempts)) {
      let anonymousCount = 0;
      let scannedInCount = 0;

      this.attempts.forEach((attempt: any) => {
        if (attempt.anonymousPlayerSyncId) {
          anonymousCount++;
        } else if (attempt.userSyncId) {
          scannedInCount++;
        }
      });

      const labels = ['Scanned-in Players', 'Anonymous Players'];
      const data = [scannedInCount, anonymousCount,];
      console.log("Pie Chart Labels: ", labels);
      console.log("Pie Chart Data: ", data);

      if (this.playerChart) {
        this.playerChart.destroy();
      }

      Chart.register(...registerables);

      this.playerChart = new Chart(this.playerPieChartRef.nativeElement, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Player Distribution',
              data: data,
              backgroundColor: ['#007041', '#ffcc00' ]
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.label || '';
                  const value = context.raw || 0;
                  return `${label}: ${value}`;
                }
              }
            }
          }
        }
      });
    }
  }

  createLocationChart(): void {
    if (this.locationChartRef && Array.isArray(this.attempts)) {
      // Create a map to hold the count of attempts per location
      const locationCounts: { [key: string]: number } = {};

      // Count the attempts per location, excluding 'Unknown'
      this.attempts.forEach((attempt: any) => {
        const location = attempt.location;
        if (location && location !== 'Unknown') {
          if (!locationCounts[location]) {
            locationCounts[location] = 0;
          }
          locationCounts[location]++;
        }
      });

      // Convert the locationCounts object into arrays for labels and data
      const labels = Object.keys(locationCounts);
      const data = Object.values(locationCounts);

      console.log("Bar Chart Labels: ", labels);
      console.log("Bar Chart Data: ", data);

      // Destroy the previous chart if it exists
      if (this.locationChart) {
        this.locationChart.destroy();
      }

      // Register necessary components with Chart.js
      Chart.register(...registerables);

      // Create a new bar chart
      this.locationChart = new Chart(this.locationChartRef.nativeElement, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Attempts per Location',
              data: data,
              backgroundColor: '#007041'
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Number of Attempts'
              }
            },
            x: {
              title: {
                display: true,
                text: 'Locations'
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.label || '';
                  const value = context.raw || 0;
                  return `${label}: ${value}`;
                }
              }
            }
          }
        }
      });
    }
  }

  //createSessionPieChart(): void {
  //  if (this.pieAbandonmentChartRef && Array.isArray(this.attempts)) {
  //    console.log("i am trying to make a chart")
  //    let completedCount = 0;
  //    let abandonedCount = 0;

  //    // Count the sessions based on the provided criteria
  //    this.attempts.forEach((attempt: any) => {
  //      if (attempt.gameSessionStatus && attempt.playerState) {
  //        const gameSessionStatus = attempt.gameSessionStatus;
  //        const playerState = attempt.playerState;

  //        if (gameSessionStatus === 'ENDED' && playerState === 'LEFT') {
  //          completedCount++;
  //        } else if ((gameSessionStatus === 'SETUP' || gameSessionStatus === 'STARTED') && (playerState === 'LEFT' || playerState === 'JOINED')) {
  //          abandonedCount++;
  //        }
  //      }
  //    });

  //    const labels = ['Completed Sessions', 'Abandoned Sessions'];
  //    const data = [completedCount, abandonedCount];

  //    console.log("Pie Chart Labels: ", labels);
  //    console.log("Pie Chart Data: ", data);

  //    // Destroy the previous chart if it exists
  //    if (this.abandonmentPieChart) {
  //      this.abandonmentPieChart.destroy();
  //    }

  //    // Register necessary components with Chart.js
  //    Chart.register(...registerables);

  //    // Create a new pie chart
  //    this.abandonmentPieChart = new Chart(this.pieAbandonmentChartRef.nativeElement, {
  //      type: 'pie',
  //      data: {
  //        labels: labels,
  //        datasets: [
  //          {
  //            label: 'Session Status Distribution',
  //            data: data,
  //            backgroundColor: ['#3cba9f', '#ffcc00']
  //          }
  //        ]
  //      },
  //      options: {
  //        responsive: true,
  //        plugins: {
  //          legend: {
  //            display: true,
  //            position: 'top',
  //          },
  //          tooltip: {
  //            callbacks: {
  //              label: function (context) {
  //                const label = context.label || '';
  //                const value = context.raw || 0;
  //                return `${label}: ${value}`;
  //              }
  //            }
  //          }
  //        }
  //      }
  //    });
  //  }
  //}
}
