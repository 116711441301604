import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ChallengesService } from '../challenges.service';
import { UserServiceService } from '../../user-service.service';
import * as ExportToCsv from 'export-to-csv';
import { mkConfig, generateCsv, asString } from "export-to-csv";
import { SharingService } from '../../sharing.service';

@Component({
  selector: 'app-challenge-leaderboard',
  templateUrl: './challenge-leaderboard.component.html',
  styleUrls: ['./challenge-leaderboard.component.css']
})
export class ChallengeLeaderboardComponent implements OnInit {
  @Input() challengeId!: number;
  @Input() challengeShots!: number;
  @Input() challengeObject!: any;

  public isLoading: boolean = true;
  challengeDetails: any;
  paginatedResults: any[] = [];
  paginatedGroupedResults: any[] = [];
  allResults: any[] = [];
  groupedResults: any[] = [];
  length: number = 0;
  groupedLength: number = 0;
  pageSize: number = 25;
  pageIndex: number = 0;
  groupedPageSize: number = 25;
  groupedPageIndex: number = 0;
  isGroupedView: boolean;

  constructor(private service: ChallengesService, private sharingService: SharingService,
    private userService: UserServiceService) {
    this.isGroupedView = false
  }

  verifyAccess(roleRequired: string) {
    return this.userService.verifyUserRole(roleRequired);
  }

  ngOnInit(): void {
    //this.loadLeaderboard()
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("ARE YOU THERE?", this.challengeId, this.challengeShots)
    if (this.challengeId && this.challengeShots >= 0) {
      this.loadLeaderboard();
    }
  }

  loadLeaderboard(): void {
    const type = this.challengeShots > 0 ? 'distance' : 'points';
    console.log(type, this.challengeId)
    this.service.getChallengeLeaderboard(this.challengeId, type).subscribe(
      data => {
        console.log("results ", data)
        this.allResults = data.playerScores || [];
        this.sharingService.changeData(this.allResults);  
        this.groupResults();
        this.length = this.allResults.length;
        this.groupedLength = this.groupedResults.length;
        this.updatePaginatedResults();
        this.isLoading = false;
      },
      err => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  toggleView(): void {
    this.isGroupedView = !this.isGroupedView;
    this.updatePaginatedResults();
  }

  groupResults(): void {
    const grouped = this.allResults
      .filter(result => result.userSyncId && result.userName)
      .reduce((acc: any, curr: any) => {
        const user = acc.find((u: any) => u.userSyncId === curr.userSyncId);
        if (user) {
          user.scores.push({
            gameSessionPlayed: curr.gameSessionPlayed,
            challengeScore: curr.challengeScore,
            shotDistance: curr.shotDistance,
            distanceToPin: curr.distanceToPin
          });
        } else {
          acc.push({
            email: curr.email,
            location: curr.location,
            userSyncId: curr.userSyncId,
            displayName: curr.userName,
            scores: [{
              gameSessionPlayed: curr.gameSessionPlayed,
              challengeScore: curr.challengeScore,
              shotDistance: curr.shotDistance,
              distanceToPin: curr.distanceToPin
            }]
          });
        }
        return acc;
      }, []);

    this.groupedResults = grouped;
  }


  updatePaginatedResults(): void {
    const startIndex = this.isGroupedView ? this.groupedPageIndex * this.groupedPageSize : this.pageIndex * this.pageSize;
    const endIndex = startIndex + (this.isGroupedView ? this.groupedPageSize : this.pageSize);

    if (this.isGroupedView) {
      this.paginatedGroupedResults = this.groupedResults.slice(startIndex, endIndex);
    } else {
      this.paginatedResults = this.allResults.slice(startIndex, endIndex);
    }
  }

  onPageChange(event: any): void {
    if (this.isGroupedView) {
      this.groupedPageIndex = event.pageIndex;
      this.groupedPageSize = event.pageSize;
    } else {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }
    this.updatePaginatedResults();
  }

  sortData(event: any): void {
    const data = this.isGroupedView ? this.groupedResults.slice() : this.allResults.slice();
    if (!event.active || event.direction === '') {
      this.updatePaginatedResults();
      return;
    }

    const sortedData = data.sort((a: any, b: any) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'displayname':
          return this.compare(a.displayName || a.userName || a.anonymousDisplayName, b.displayName || b.userName || b.anonymousDisplayName, isAsc);
        case 'date':
          return this.compare(a.gameSessionPlayed, b.gameSessionPlayed, isAsc);
        case 'score':
          return this.compare(a.challengeScore, b.challengeScore, isAsc);
        case 'round':
          return this.compare(a.distanceToPin, b.distanceToPin, isAsc);
        default:
          return 0;
      }
    });

    if (this.isGroupedView) {
      this.groupedResults = sortedData;
    } else {
      this.allResults = sortedData;
    }

    this.updatePaginatedResults();
  }

  compare(a: any, b: any, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  exportToCSV(): void {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const dataToExport = this.isGroupedView ? this.prepareGroupedData() : this.prepareIndividualData();
    const csvOutput: ExportToCsv.CsvOutput = generateCsv(csvConfig)(dataToExport);
    const csvString = csvOutput.toString(); // Convert CsvOutput to a string

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });


    // Create a link element
    const link = document.createElement('a');

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD format
    const title = this.isGroupedView ? "grouped_challenge_results" : "listed_challenge_results";
    // Set the download attribute with a filename that includes the date
    link.download = `${title}_${formattedDate}.csv`;


    // Create a URL for the Blob and set it as the href attribute
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }

  prepareIndividualData(): any[] {
    return this.allResults.map(result => ({
      PLAYER: result.userName || result.anonymousDisplayName,
      EMAIL: result.email || '',
      'PLAYED ON': new Date(result.gameSessionPlayed).toLocaleDateString(),
      SCORE: this.challengeShots === 0 ? result.challengeScore : '',
      DISTANCE: this.challengeShots > 0 ? result.distanceToPin : '',
      LOCATION: result.location || ''
    }));
  }

  prepareGroupedData(): any[] {
    return this.groupedResults.map(group => ({
      PLAYER: group.displayName,
      EMAIL: group.email,
      LOCATION: group.location,
      SCORES: group.scores.map((score: any) =>
        `${new Date(score.gameSessionPlayed).toLocaleDateString()} - ${this.challengeShots === 0 ? 'Score: ' + score.challengeScore : 'Distance: ' + score.shotDistance}`
      ).join(' | ')
    }));
  }

}
