import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChallengesService } from '../challenges.service';
import { UpdateMediaItemComponent } from '../../media-items/update-media-item/update-media-item.component';


@Component({
  selector: 'app-edit-challenge',
  templateUrl: './edit-challenge.component.html',
  styleUrls: ['./edit-challenge.component.css']
})
export class EditChallengeComponent implements OnInit{
  imageSrcMap: { [key: string]: string | null } = {};
  challengeForm!: FormGroup;
  courseSettingForm!: FormGroup;
  teesForm!: FormGroup;
  calibrationForm!: FormGroup;
  dynamicForm!: FormGroup;
  public isLoading: boolean;
  public holder: any;
  public iconURLHolder!: string;

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private service: ChallengesService,
    private dialogRef: MatDialogRef<EditChallengeComponent>,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar) {
    this.isLoading = true

    console.log("data:", data)
  }


  initializeForms() {
    const start = this.formatDate(this.data.startDate)
    const end = this.formatDate(this.data.endDate)
    const titleArea = this.data?.description.split(/\s{2,}/);
    console.log(titleArea)
    this.challengeForm = this.fb.group({
      challengeId: [this.data.id],
      challengeIdentifier: [this.data.name + "_" + this.data.holeIndex, Validators.required],
      title1: [titleArea[0], Validators.required],
      title2: [titleArea[1], this.data.challenge?.subTitle],
      description: [this.data.description, Validators.required],
      startDate: [start, Validators.required],
      endDate: [end, Validators.required],
      numberOfShots: [this.data.numberOfShots, [Validators.required, Validators.min(1)]],
      minimumParticipation: [this.data.minParticipants, [Validators.required, Validators.min(1)]],
      maximumParticipation: [this.data.maxParticipants, [Validators.required, Validators.min(1), Validators.max(100)]],
      maxAttemptsAllowed: [5000, [Validators.required, Validators.min(1), Validators.max(5000)]],
      holeIndex: [this.data.holeIndex, [Validators.required, Validators.min(1), Validators.max(5000)]],
      distance: [this.data.distance, [Validators.required, Validators.min(1)]],
      challengeType: [+this.data.challengeType, Validators.required],
      challengeSubType: [+this.data.tenShotChallengeType, [Validators.required, Validators.min(1)]],
      icon: ['']
    });
    this.courseSettingForm = this.fb.group({
      id: [this.data.eventCourseSetting?.id, [Validators.required]],
      eventId: [this.data.eventCourseSetting?.eventId, [Validators.required]],
      fieldDistance: [this.data.eventCourseSetting?.fieldDistance, [Validators.required]],
      greenDistance: [this.data.eventCourseSetting?.greenDistance, [Validators.required]],
      speedUnit: [this.data.eventCourseSetting?.speedUnit, [Validators.required]],
      swingView: [this.data.eventCourseSetting?.swingView, [Validators.required]],
      puttingView: [this.data.eventCourseSetting?.puttingView, [Validators.required]],
      autoFlyBy: [this.data.eventCourseSetting?.autoFlyBy, [Validators.required]],
      xPlateGreen: [this.data.eventCourseSetting?.xPlateGreen, [Validators.required]],
      xPlateField: [this.data.eventCourseSetting?.xPlateField, [Validators.required]],
      autoBallFeed: [this.data.eventCourseSetting?.autoBallFeed, [Validators.required]],
      puttingPreview: [this.data.eventCourseSetting?.puttingPreview, [Validators.required]],
      conceed: [this.data.eventCourseSetting?.conceed, [Validators.required]],
      obTee: [this.data.eventCourseSetting?.obTee, [Validators.required]],
      playableArea: [this.data.eventCourseSetting?.playableArea, [Validators.required]],
      doublePar: [this.data.eventCourseSetting?.doublePar, [Validators.required]],
      windOption: [this.data.eventCourseSetting?.windOption, [Validators.required]],
      clubSelectionInTheFridge: [this.data.eventCourseSetting?.clubSelectionInTheFridge, [Validators.required]],
      swingInformation: [this.data.eventCourseSetting?.swingInformation, [Validators.required]],
      fieldTrajectory: [this.data.eventCourseSetting?.fieldTrajectory, [Validators.required]],
      greenCondition: [this.data.eventCourseSetting?.greenCondition, [Validators.required]],
      puttingGrid: [this.data.eventCourseSetting?.puttingGrid, [Validators.required]],
      rough: [this.data.eventCourseSetting?.rough, [Validators.required]],
      greenSideBunker: [this.data.eventCourseSetting?.greenSideBunker, [Validators.required]],
      fairwayBunker: [this.data.eventCourseSetting?.fairwayBunker, [Validators.required]],
    });
    this.courseSettingForm.disable()

    this.teesForm = this.fb.group({
      eventId: [0, Validators.required],
      maleTeeColor: [0, Validators.required],
      femaleTeeColor: [0, Validators.required],
      seniorTeeColor: [0, Validators.required]
    });
    this.teesForm.disable()

    this.calibrationForm = this.fb.group({
      eventId: [this.data.eventCalibration?.eventId, Validators.required],
      ballSpeed: [this.data.eventCalibration?.ballSpeed, [Validators.required]],
      puttingSpeed: [this.data.eventCalibration?.puttingSpeed, [Validators.required]],
      backSpinOffset: [this.data.eventCalibration?.backSpinOffset, [Validators.required]],
      sideSpinOffset: [this.data.eventCalibration?.sideSpinOffset, [Validators.required]],
      backSpinGain: [this.data.eventCalibration?.backSpinGain, [Validators.required]],
      sideSpinGain: [this.data.eventCalibration?.sideSpinGain, [Validators.required]],
    });
    this.calibrationForm.disable()

    this.dynamicForm = this.fb.group({
      eventId: [this.data.eventDynamic?.eventId, Validators.required],
      gravity: [this.data.eventDynamic?.gravity, [Validators.required]],
      rollingResistance: [this.data.eventDynamic?.rollingResistance, [Validators.required]],
      ballSpeedCompensation: [this.data.eventDynamic?.ballSpeedCompensation, [Validators.required]],
      puttSpeedCompensation: [this.data.eventDynamic?.puttSpeedCompensation, [Validators.required]],
      sliceSpinCompensation: [this.data.eventDynamic?.sliceSpinCompensation, [Validators.required]],
      hookSpinCompensation: [this.data.eventDynamic?.hookSpinCompensation, [Validators.required]],
      backSpinCompensation: [this.data.eventDynamic?.backSpinCompensation, [Validators.required]],
      forwardSpinCompensation: [this.data.eventDynamic?.forwardSpinCompensation, [Validators.required]]
    });
    this.dynamicForm.disable()
  }

  populateForms()
  {
      this.courseSettingForm.patchValue(this.holder.courseSettings);
      this.teesForm.patchValue(this.data.tees);
      this.calibrationForm.patchValue(this.data.calibration);
      this.dynamicForm.patchValue(this.data.dynamics);
    
  }

  ngOnInit(): void {
    this.isLoading = true
    this.initializeForms()
    this.service.getChallenge(this.data.id).subscribe(x => {
      console.log("all done here ", x)
      if (x) {
        this.holder = x
        this.populateForms()
      }
    }, err => { }, () => { })
    if (this.data.challengeType === "MINI_GAMES") {
       this.challengeForm.controls["challengeType"].setValue(1)
    }
    if (this.data.challengeType === "NATIONAL_CHALLENGE") {
      this.challengeForm.controls["challengeType"].setValue(0)
    }
    if (this.data.tenShotChallengeType === "STANDARD") {
      this.challengeForm.controls["challengeSubType"].setValue(0)
    }
    if (this.data.tenShotChallengeType === "ONECLUB") {
      this.challengeForm.controls["challengeSubType"].setValue(1)
    }
    if (this.data.tenShotChallengeType === "PRO") {
      this.challengeForm.controls["challengeSubType"].setValue(2)
    }
    this.cd.detectChanges()
    //ad null check
    this.iconURLHolder = this.data.imageUrls[0]
    this.isLoading = false
  }


  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  onFileSelect(event: Event): void {
    const element = event.target as HTMLInputElement;
    let file: File | null = element.files ? element.files[0] : null;
    if (file) {
      // Create an object URL for the selected file
      const imageUrl = URL.createObjectURL(file);
      const controlName = 'icon'; // replace with your actual control name if different

      // Cleanup any existing object URL to avoid memory leaks
      this.cleanupObjectURL(controlName);

      // Store the object URL in the map
      this.imageSrcMap[controlName] = imageUrl;

      // Update the form control with the file object
      this.challengeForm.get(controlName)?.setValue(file);
    }
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  camelCaseToNormal(text: string): string {
    // First, use a regular expression to insert a space before all capital letters
    let result = text.replace(/([A-Z])/g, ' $1');
    // Then, capitalize the first letter of the resulting string
    result = result.charAt(0).toUpperCase() + result.slice(1);
    // Trim any leading spaces that might have been added by the regex replacement
    return result.trim();
  }

  onConfirm() {
    const formData = new FormData();
    const iconFile = this.challengeForm.get('icon')?.value;
    if (iconFile) {
      formData.append('icon', iconFile);
    } else {
      console.error('Icon file is undefined.');
    }
    const challengeDetails = {
      Challenge: this.challengeForm.value,
      CourseSettings: this.courseSettingForm.value,
      Calibration: this.calibrationForm.value,
      Dynamics: this.dynamicForm.value,
      //Tees: this.teesForm.value // Assuming you have a form for the tees
    };
    for (const [key, value] of Object.entries(challengeDetails)) {
      console.log(`${key}: `, value); // Verify the values are correct
      if (value) {
        formData.append(key, JSON.stringify(value));
      } else {
        console.error(`${key} is undefined.`);
      }
    }

    // Append properties of Challenge
    Object.entries(this.challengeForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        if (key === 'Distance') {
          // Convert Distance to an integer or float as needed
          const distanceValue = parseFloat(value.toString());
          formData.append(`Challenge.${key}`, distanceValue.toString());
        } else {
          formData.append(`Challenge.${key}`, value.toString());
        }
      } else if (value instanceof File) {
        formData.append(`Challenge.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.courseSettingForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`CourseSettings.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`CourseSettings.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.calibrationForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Calibration.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Calibration.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.dynamicForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Dynamics.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Dynamics.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.teesForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Tees.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Tees.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    this.service.postUpdateChallenge(formData).subscribe(x => {
      this._snackBar.open('Challenge updated.', '', {
        duration: 3000,
        verticalPosition: "bottom",
        horizontalPosition: "right",
        panelClass: "successNotice"
      });
      this.dialogRef.close(this.challengeForm.value);
    }, err => { console.log(err) }, () => { })
  }

  getOptionTextByIdAndValue(selectId: string, value: string | number): string | undefined {
    // Use nativeElement to access the DOM API
    const selectElement: HTMLSelectElement = document.getElementById(selectId) as HTMLSelectElement;
    if (!selectElement) return undefined;

    const optionElement = selectElement.querySelector(`option[value="${value}"]`) as HTMLOptionElement;
    return optionElement ? optionElement.text : undefined;
  }

  isImageFile(controlName: string): boolean {
    const file = this.challengeForm.get(controlName)?.value;
    return file instanceof File && file.type.startsWith('image/');
  }

  cleanupObjectURL(controlName: string): void {
    const existingUrl = this.imageSrcMap[controlName];
    if (existingUrl) {
      URL.revokeObjectURL(existingUrl);
      this.imageSrcMap[controlName] = null; // Reset the URL in the map
    }
  }

  getImageSrc(controlName: string): string | null {
    return this.imageSrcMap[controlName] || null;
  }

  showing10Challenge(): boolean {
    const selectedChallenge = this.courseSettingForm.get('challengeIdentifier')?.value;
    // Check if the selected option contains "Challenge_10_Shot" in its value
    return selectedChallenge.includes('Challenge_10_Shot');
  }


  ngOnDestroy(): void {
    // Cleanup all object URLs when the component is destroyed
    for (const key of Object.keys(this.imageSrcMap)) {
      this.cleanupObjectURL(key);
    }
  }

}
