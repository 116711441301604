<div *ngIf="!isLoading" class="container p-1" style="width:100vw;height: 100vh; overflow-y: scroll;overflow-x:hidden;">
  <div class="row">
    <div class="col-md-12 center">
      <h3 class="display-3">TEAM ROUND RECAP</h3>
    </div>
  </div>
  <div class="row row-cols-2">
    <div class="col">
      <div class="p-3">
        <h4>ROUND INFO:</h4>
        <p>
          EMAILS:
          <ng-container *ngFor="let email of details.emails; let isLast = last">
            <a (click)="openComms(details.email)">{{email}}</a><span *ngIf="!isLast">, </span>
          </ng-container>
        </p>
        <p>TEAM: <strong style="font-size:1.2em">{{details.teamName}}</strong></p>
        <p>DATE: <strong>{{details.created | date:'short'}}</strong></p>
        <p>LOCATION: <strong>{{details.location}}</strong> </p>
        <button *ngIf="details.scorecardImageURL != '' && (verifyAccess('TournamentAdmin') || verifyAccess('SystemAdmin'))" class="btn btn-sm btn-danger" (click)="discardRound()">DISCARD</button>
      </div>
      <div class="center p-3" *ngIf="details.scorecardImageURL != ''">
        <img src="{{details.scorecardImageURL}}" class="img-fluid" />
      </div>
    </div>

  </div>
  <div class="row" *ngIf="this.details.gameMode === 11 || this.details.gameMode === 0 ">
    <ng-container *ngIf="!details.eligibleForManual; else manualEntryBody">
      <div class="col">
        <div class="p-3">
          <h4 style="text-align:center;">{{details.course.title}}</h4>

          <div class="center">
            <div style="border-right: black solid 1px; border-top:1px solid black; border-left: black solid 1px">
              <div style="border-bottom: black solid 1px;text-align:center">
                Hole
              </div>
              <div style="border-bottom: black solid 1px;text-align:center">
                Par
              </div>
              <div style="text-align:center;border-bottom:1px solid black;">
                Score
              </div>
            </div>

            <ng-container *ngFor="let hole of sortHoleScores()">
              <div style="width: 50px; border-right: black solid 1px; border-top: 1px solid black; border-left: black solid 1px; display: inline-table; ">
                <div style="height: 50px; border-bottom: black solid 1px; text-align: center; padding: 6px">{{ hole.key }}</div>
                <div style="height: 50px; border-bottom: black solid 1px; text-align: center; padding: 9px">{{ getParForHole(hole.key) }}</div>
                <div style="height: 50px; text-align: center; border-bottom: 1px solid black; padding: 9px">
                  <ng-container *ngIf="hole.value < getParForHole(hole.key) - 1">
                    <div class="bird-double-circle">
                      <div class="bird-circle">
                        <div class="inner-circle">
                          {{ hole.value }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="hole.value === getParForHole(hole.key) - 1">
                    <span class="bird-circle">{{ hole.value }}</span>
                  </ng-container>
                  <ng-container *ngIf="hole.value === getParForHole(hole.key)">
                    <span>{{ hole.value }}</span>
                  </ng-container>
                  <ng-container *ngIf="hole.value === getParForHole(hole.key) + 1">
                    <span class="boggy-square">{{ hole.value }}</span>
                  </ng-container>
                  <ng-container *ngIf="hole.value === getParForHole(hole.key) + 2">
                    <div class="">
                      <div class="outter-square">
                        <div class="boggy-square">
                          {{ hole.value }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="hole.value > getParForHole(hole.key) + 2">
                    <span>{{ hole.value }}</span>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 6px;
          padding: 6px;
          font-weight: bold;
          font-size: 1.7em;
          border: 2px solid black;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          text-align: center;">
              {{ details.simProvidedScore }}
            </div>
          </div>

        </div>

        <div style="max-height:400px;overflow-y:scroll;overflow-x:hidden;border:2px solid black;padding:16px;">
          <div class="row sticky-top bg-white" style="font-size: .75em; top: 0; z-index: 1000; width:120px;text-align:left">
            <p style="background-color:yellow;"> * MULLIGAN </p>
          </div>
          <div class="row sticky-top bg-white" style="font-size: .75em; top: 0; z-index: 1000;">
            <div class="col-sm-1">
              <p class="">HOLE</p>
            </div>
            <div class="col-sm-1">
              <p class="">STROKE</p>
            </div>
            <div class="col-sm-2">
              <p class="">CLUB</p>
            </div>
            <div class="col-sm-3">
              <p class="">FROM - TO</p>
            </div>
            <div class="col-sm-2">
              <p class="">DISTANCE</p>
            </div>
            <div class="col-sm-2">
              <p class="">REMAINING TO FLAG</p>
            </div>
          </div>
          <div *ngFor="let item of roundData.allStrokes; let i = index" style="">
            <hr *ngIf="i > 0 && item.holeNumber > roundData.allStrokes[i - 1].holeNumber" />
            <div class="row" [ngStyle]="{'background-color': item.strokeType == 'MULLIGAN' ? 'yellow' : 'inherit'}">
              <ng-container *ngIf="i === 0 || item.holeNumber !== roundData.allStrokes[i - 1].holeNumber; else emptySpace">
                <div class="col-sm-1">
                  <p style="font-weight: bold; font-size: 1.5em">{{ item.holeNumber }}</p>
                </div>
              </ng-container>
              <ng-template #emptySpace>
                <div class="col-sm-1">

                </div>
              </ng-template>
              <div class="col-sm-1">
                {{ item.stroke }}
              </div>
              <div class="col-sm-2">
                {{ item.club }}
              </div>
              <div class="col-sm-3">
                <span style="font-size:.75em;">{{ item.from }}-{{ item.to }}</span>
              </div>
              <div class="col-sm-2">
                {{ item.shotDistance }}
              </div>
              <div class="col-sm-2">
                {{ item.distanceRemaining !== 'HOLE' ? item.distanceRemaining : '' }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-container>
    <ng-template #manualEntryBody>
      <span class="lead">SCORECARD MANUALLY UPLOADED</span>
    </ng-template>
  </div>

  <div *ngIf="this.details.gameMode === 3">
    <ng-container class="row" *ngFor="let player of bestBallRoundData?.teamResults">
      <div class="col-6">
        <h2>
          {{getEmailFromId(player.userId)}}
        </h2>
        <ng-container *ngFor="let hole of sortHoleScoresForTeamMember(player.scorecard)">
          <div style="width: 50px; border-right: black solid 1px; border-top: 1px solid black; border-left: black solid 1px; display: inline-table; ">
            <div style="height: 50px; border-bottom: black solid 1px; text-align: center; padding: 6px">{{ hole.key }}</div>
            <div style="height: 50px; border-bottom: black solid 1px; text-align: center; padding: 9px">{{ getParForHole(hole.key) }}</div>
            <div style="height: 50px; text-align: center; border-bottom: 1px solid black; padding: 9px">
              <ng-container *ngIf="hole.value < getParForHole(hole.key) - 1">
                <div class="bird-double-circle">
                  <div class="bird-circle">
                    <div class="inner-circle">
                      {{ hole.value }}
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="hole.value === getParForHole(hole.key) - 1">
                <span class="bird-circle">{{ hole.value }}</span>
              </ng-container>
              <ng-container *ngIf="hole.value === getParForHole(hole.key)">
                <span>{{ hole.value }}</span>
              </ng-container>
              <ng-container *ngIf="hole.value === getParForHole(hole.key) + 1">
                <span class="boggy-square">{{ hole.value }}</span>
              </ng-container>
              <ng-container *ngIf="hole.value === getParForHole(hole.key) + 2">
                <div class="">
                  <div class="outter-square">
                    <div class="boggy-square">
                      {{ hole.value }}
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="hole.value > getParForHole(hole.key) + 2">
                <span>{{ hole.value }}</span>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 6px;
          padding: 6px;
          font-weight: bold;
          font-size: 1.7em;
          border: 2px solid black;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          text-align: center;">
          {{ calculateCardScore(player.scorecard) }}
        </div>
      </div>
      <div class="col-6">
        <div style="max-height:400px;overflow-y:scroll;overflow-x:hidden;border:2px solid black;padding:16px;">
          <div class="row sticky-top bg-white" style="font-size: .75em; top: 0; z-index: 1000; width:120px;text-align:left">
            <p style="background-color:yellow;"> * MULLIGAN </p>
          </div>
          <div class="row sticky-top bg-white" style="font-size: .75em; top: 0; z-index: 1000;">
            <div class="col-sm-1">
              <p class="">HOLE</p>
            </div>
            <div class="col-sm-1">
              <p class="">STROKE</p>
            </div>
            <div class="col-sm-2">
              <p class="">CLUB</p>
            </div>
            <div class="col-sm-3">
              <p class="">FROM - TO</p>
            </div>
            <div class="col-sm-2">
              <p class="">DISTANCE</p>
            </div>
            <div class="col-sm-2">
              <p class="">REMAINING TO FLAG</p>
            </div>
          </div>
          <div *ngFor="let item of player?.allStrokes; let i = index" style="">
            <hr *ngIf="i > 0 && item.holeNumber > player.allStrokes[i - 1].holeNumber" />
            <div class="row" [ngStyle]="{'background-color': item.strokeType == 'MULLIGAN' ? 'yellow' : 'inherit'}">
              <ng-container *ngIf="i === 0 || item.holeNumber !== player.allStrokes[i - 1].holeNumber; else emptySpace">
                <div class="col-sm-1">
                  <p style="font-weight: bold; font-size: 1.5em">{{ item.holeNumber }}</p>
                </div>
              </ng-container>
              <ng-template #emptySpace>
                <div class="col-sm-1">

                </div>
              </ng-template>
              <div class="col-sm-1">
                {{ item.stroke }}
              </div>
              <div class="col-sm-2">
                {{ item.club }}
              </div>
              <div class="col-sm-3">
                <span style="font-size:.75em;">{{ item.from }}-{{ item.to }}</span>
              </div>
              <div class="col-sm-2">
                {{ item.shotDistance }}
              </div>
              <div class="col-sm-2">
                {{ item.distanceRemaining !== 'HOLE' ? item.distanceRemaining : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <hr/>
    <h2>COMBINED TOTAL</h2>
    <ng-container>
      <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 6px;
          padding: 6px;
          font-weight: bold;
          font-size: 1.7em;
          border: 2px solid black;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          text-align: center;">
        {{ calculateTotalBestBallScore() }}
      </div>
    </ng-container>
  </div>

  <div *ngIf="(verifyAccess('TournamentAdmin') || verifyAccess('SystemAdmin'))" class="row center">
    <form class="row" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group m-2">
        <ng-container *ngIf="details.eligibleForManual">
          <label for="simProvidedScore">ENTER GROSS ROUND GROSS SCORE</label>
        </ng-container>
        <ng-container *ngIf="!details.eligibleForManual">
          <label for="simProvidedScore">GROSS SCORE PROVIDED BY SIM</label>
        </ng-container>
        <input type="number"
               class="form-control form-control-sm"
               formControlName="simProvidedScore"
               style="width: 50px; display:inline;margin-left:16px" />
      </div>
      <div class="form-group m-2">
        <label for="validationInput">CONFIRM SCORE</label>
        <input type="number"
               class="form-control form-control-sm"
               formControlName="validationInput"
               style="width: 50px; display:inline;margin-left:16px" />
      </div>

      <div class="form-group m-2" *ngIf="!details.eligibleForManual">
        <label>Reason For Score Difference</label>
        <input class="form-control"
               formControlName="messageArea"
               placeholder="Reason for changing score." />
      </div>


      <button class="btn btn-sm xbtn-primary" type="submit" [disabled]="details.roundVerified || form.get('submitButton')?.value">Validate</button>
      <!--if details.netOnly we need to make sure the handicaps are entered before allowing submit-->
    </form>


  </div>
</div>


<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>

