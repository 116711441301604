import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { LeagueComponent } from './league/league.component';
import { SeriesComponent } from './series/series.component';
import { LocationComponent } from './location/location.component';
import { CreateLeagueComponent } from './league/create-league/create-league.component';
import { LeagueDetailsComponent } from './league/league-details/league-details.component';
import { CreateEventComponent } from './league/create-event/create-event.component';
import { TournamentComponent } from './series/tournament/tournament.component';
import { SeriesDetailsComponent } from './series/series-details/series-details.component';
import { RoundComponent } from './series/tournament/round/round.component';
import { EditLeagueComponent } from './league/edit-league/edit-league.component';
import { CreateLeagueTeamComponent } from './league/league-team/create-league-team/create-league-team.component';
import { AdvanceConfirmComponent } from './series/tournament/round/advance-confirm/advance-confirm.component';
import { RoundRecapComponent } from './series/tournament/round/round-recap/round-recap.component';
import { PlayersViewComponent } from './players-view/players-view.component';
import { PlayerStatsComponent } from './players-view/player-stats/player-stats.component';
import { PlayerPastRoundsComponent } from './players-view/player-past-rounds/player-past-rounds.component';
import { PlayerSeriesViewComponent } from './players-view/player-series-view/player-series-view.component';
import { CommunicationsComponent } from './communications/communications.component';
import { SimulatorsComponent } from './simulators/simulators.component';
import { RoundResultsComponent } from './series/tournament/round-results/round-results.component';
import { ChallengesComponent } from './challenges/challenges.component';
import { ChallengeDetailsComponent } from './challenges/challenge-details/challenge-details.component';
import { CommunicationsComposerComponent } from './communications/communications-composer/communications-composer.component';

const routes: Routes = [
  { path: '', component: CommunicationsComponent},
  { path: 'Simulators', component: SimulatorsComponent },
  { path: 'Communications', component: CommunicationsComponent },
  { path: 'Communications/Create', component: CommunicationsComposerComponent },
  { path: 'UserSearch', component: UserSearchComponent },
  { path: 'Series', component: SeriesComponent },
  { path: 'Locations', component: LocationComponent },
  { path: 'Leagues', component: LeagueComponent },
  { path: 'Leagues/Create', component: CreateLeagueComponent },
  { path: 'Leagues/Details/:id', component: LeagueDetailsComponent },
  { path: 'LeagueEvents/Create/:leagueId', component: CreateEventComponent },
  { path: 'Series/Details/:seriesId', component: SeriesDetailsComponent },
  { path: 'IndividualRoundRecap/:referenceId/:roundId', component: RoundRecapComponent },
  { path: 'Series/Tournament/:tournamentId', component: TournamentComponent },
  { path: 'Series/Tournament/Round/:roundId', component: RoundComponent },
  { path: 'Leagues/Team/Create/:leagueId', component: CreateLeagueTeamComponent },
  { path: 'Series/:seriesId/Tournament/:tournamentId/Round/:roundId', component: RoundResultsComponent },
  { path: 'Challenges', component: ChallengesComponent },
  { path: 'Challenge/:challengeId', component: ChallengeDetailsComponent}
  //{ path: 'MyLocker', component: PlayersViewComponent },
  //{ path: 'MyLocker/Stats', component: PlayerStatsComponent },
  //{ path: 'MyLocker/Rounds', component: PlayerPastRoundsComponent },
  //{ path: 'MyLocker/XCup', component: PlayerSeriesViewComponent, }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
