<div class="container p-5">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">PAST ROUNDS</h1>
    </div>
  </div>
  <div class="row" *ngFor="let pRound of playerPreviousRounds" style="background-color:#eee; border-bottom:solid black 2px">
    <div class="col-6" style="display:inline-block;margin:6px;padding:6px;font-weight:bold;font-size:1.2em">
      <h3 class="lead">DATE</h3>{{ getLocalTime(pRound.created) | date:"short" }}
      <h3 class="lead">COURSE</h3>{{ pRound.courseName }}
    </div>
    <div class="col-6" style="display:inline-block;margin:6px;padding:6px;font-weight:bold;font-size:1.7em">
      <h3 class="lead">SCORE</h3>{{ pRound.roundScoreTotal }}
    </div>
    <h3 class="lead">FRONT</h3>
    <div class="col-12" style="display:flex;margin:16px">
      <div style="border-right: black solid 1px; border-top:1px solid black; border-left: black solid 1px">
        <div style="border-bottom: black solid 1px;text-align:center">
          HOLE
        </div>
        <div style="border-bottom: black solid 1px;text-align:center">
          PAR
        </div>
        <div style="text-align:center;border-bottom:1px solid black;">
          SCORE
        </div>
      </div>
      <div *ngFor="let key of getSortedKeys(pRound.roundScores)" [ngClass]="{'last-hole': key == '9'}">
        <ng-container *ngIf="+key <= 9">
          <div style="border-right: black solid 1px; border-top:1px solid black; width:24px;">
            <div style="border-bottom: black solid 1px;text-align:center">
              {{ key }}
            </div>
            <div style="border-bottom: black solid 1px;text-align:center">
              {{ getParValue(pRound.course.courseHoles, key) }}
            </div>
            <div style="text-align:center;border-bottom:1px solid black;">
              {{ pRound.roundScores[key] }}
            </div>
          </div>
        </ng-container>

      </div>

    </div>
    <!-- Back 9 -->
    <h3 class="lead">BACK</h3>
    <div class="col-12" style="display:flex;margin:16px">
      <div style="border-right: black solid 1px; border-top:1px solid black; border-left: black solid 1px">
        <div style="border-bottom: black solid 1px;text-align:center">
          HOLE
        </div>
        <div style="border-bottom: black solid 1px;text-align:center">
          PAR
        </div>
        <div style="text-align:center;border-bottom:1px solid black;">
          SCORE
        </div>
      </div>
      <div *ngFor="let key of getSortedKeys(pRound.roundScores)" [ngClass]="{'last-hole': key == '18'}">
        <ng-container *ngIf="+key > 9">
          <div style="border-right: black solid 1px; border-top:1px solid black; width:24px;">
            <div style="border-bottom: black solid 1px;text-align:center">
              {{ key }}
            </div>
            <div style="border-bottom: black solid 1px;text-align:center">
              {{ getParValue(pRound.course.courseHoles, key) }}
            </div>
            <div style="text-align:center;border-bottom:1px solid black;">
              {{ pRound.roundScores[key] }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <hr />
</div>
