import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChallengesService {

  constructor(private http: HttpClient) { }


  getChallenges(): Observable<any> {
    return this.http.get<any>(`/api/challenges/get`)
  }

  getChallenge(id: number): Observable<any> {
    console.log("going to try and call the ")
    return this.http.get<any>(`/api/challenges/getChallenge`,
      {
        params: {
            challengeId: id
        }
      })
  }

  postCreateChallenge(challengeForm: FormData): Observable<any> {
    return this.http.post<any>(`/api/challenges/createChallenge`, challengeForm)
  }

  postUpdateChallenge(challengeForm: FormData): Observable<any> {
    return this.http.post<any>(`/api/challenges/updateChallenge`, challengeForm)
  }

  getChallengeLeaderboard(id: number, type: string): Observable<any> {

    return this.http.get<any>(`/api/challenges/leaderboard`,
      {
        params: {
          challengeId: id,
          type: type
        }
      })
  }

  getChallengeAnaliticalData(id: number): Observable<any> {
    return this.http.get<any>(`/api/challenges/stats`,
      {
        params: {
          challengeId: id.toString()
        }
      })
  }

}
