import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../user-service.service';

@Component({
  selector: 'app-players-view',
  templateUrl: './players-view.component.html',
  styleUrls: ['./players-view.component.css']
})
export class PlayersViewComponent implements OnInit {
  userProfile: any;
  constructor(private userService: UserServiceService) { }


    ngOnInit(): void {
      this.userService.getUsersCachedProfile().subscribe(x => {
          this.userProfile = x
      }, err => { console.log(err) }, () => { })
    }
}
