<div *ngIf="!isLoading"  class="container" style="height: auto; width: auto; overflow-x: hidden;">
  <div class="">
      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-12 center">
            <img src="{{selectedTournament.tournamentImageURL}}" class="card-img" alt="Golf Tournament Image" style="height:150px;width:auto">
          </div>
          <div class="col-md-12">
            <div class="card-body">
              <div style="display:flex;justify-content:flex-start">
                <h5 style="color:var(--xgolfBalck)">REGISTERING INDIVIDUAL FOR: {{selectedTournament.title}}</h5>
              </div>
              <form [formGroup]="form" (ngSubmit)="onSubmit($event)">
                <input type="hidden" formControlName="tournamentId" [value]="tourId" />
                <!-- ... existing form elements -->
                <div class="mb-3" *ngIf="showDropdown">
                  <label for="locationDropdown" class="form-label">Location</label>
                  <select class="form-control" id="locationDropdown" formControlName="locationId">
                    <option *ngFor="let location of locations" [value]="location.id">{{location.name}}</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">Email</label>
                  <input type="email" class="form-control" formControlName="email" placeholder="Email" />

                  <span *ngIf="playerFound" class="text-success lead">Found</span>
                  <ng-container *ngIf="form.get('email')?.value && wasEmailPreviouslySearchedAndNotFound(form.get('email')?.value) && !emailSearchHappening && !playerFound" class="text-danger lead">
                    <span class="text-danger">
                      The email entered is not associated with an X-Golf Mobile App account.
                      Please reenter or click <a (click)="sendAppInvite()" style="text-decoration:underline">Send Email</a> to invite the customer to download and create their X-Golf Mobile App account.
                    </span>
                  </ng-container>

                  <span *ngIf="emailSearchHappening" class="text-info lead">Searching...</span>
                </div>

                <!-- Search button; visible only when the email hasn't been searched or found -->
                <button class="btn btn-sm btn-warning" type="button" (click)="checkEmail()" *ngIf="!playerFound">SEARCH EMAIL</button>

                <!-- Submit button; visible only when the email has been searched and found -->
                <button class="btn btn-sm xbtn-primary" type="submit" *ngIf="playerFound">SUBMIT</button>
              </form>


            </div>
          </div>
        </div>
        <div class="row">
          <a (click)="closeDialog()" class="m-3">Cancel</a>
        </div>
      </div>


    
  </div>
</div>


<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
