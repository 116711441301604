<div style="padding:16px">
  <div class="row" style="width: 80vw;">
    <h2>Creating event for {{leagueTitle}}</h2>
    <h3>Week {{leagueWeek}}</h3>
  </div>
  <hr/>
  <div class="row">
    <form [formGroup]="leagueEventForm" (ngSubmit)="onSubmit()">

      <div class="form-group">
        <label for="title">Title<span class="text-danger">*</span></label>
        <div class="input-group">
          <input type="text" id="title" class="form-control" formControlName="title">
          <div style="margin:6px;" data-toggle="tooltip"
               data-placement="right" title="Enter the Title of the League. An example might be 'Fall Monday Night League.'">
            <div class="info-icon"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="description">Description</label>
        <div class="input-group">
          <textarea id="description" class="form-control" formControlName="description"></textarea>
          <div style="margin:6px;" data-toggle="tooltip" data-placement="right" title="Enter a description for the league."></div>
        </div>
      </div>

      <div class="form-group">
        <label for="eventDate">Open Date</label>
        <div class="input-group">
          <input type="datetime-local" id="eventDate" class="form-control" formControlName="eventDate">
          <div style="margin:6px;" data-toggle="tooltip" data-placement="right" title="Select the opening date for the league."></div>
        </div>
      </div>

      <div class="form-group">
        <label for="eventCutOffDate">Close Date</label>
        <div class="input-group">
          <input type="datetime-local" id="eventCutOffDate" class="form-control" formControlName="eventCutOffDate">
          <div style="margin:6px;" data-toggle="tooltip" data-placement="right" title="Select the closing date for the league."></div>
        </div>
      </div>

      <div class="form-group">
        <label for="eventCourseId">Course</label>
        <div class="input-group">
          <select id="eventCourseId" class="form-control" formControlName="eventCourseId">
            <option *ngFor="let course of courses" [value]="course.id">{{ course.title }}</option>
          </select>
          <div style="margin:6px;" data-toggle="tooltip" data-placement="right" title="Select the course for the league."></div>
        </div>
      </div>

      <div class="form-group">
        <label for="eventCourseHoleList">Holes</label>
        <div class="input-group">
          <select id="eventCourseHoleList" class="form-control" formControlName="eventCourseHoleList">
            <option [value]="[1,2,3,4,5,6,7,8,9].join(',')">FRONT 9</option>
            <option [value]="[10,11,12,13,14,15,16,17,18].join(',')">BACK 9</option>
            <option [value]="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].join(',')">18</option>
          </select>
          <div style="margin:6px;" data-toggle="tooltip" data-placement="right" title="Select the course for the league."></div>
        </div>
      </div>


      <div class="form-group">
        <div class="form-check">
          <input type="checkbox" id="isActive" class="form-check-input" formControlName="isActive">
          <label for="isActive" class="form-check-label">Is Active</label>
        </div>
      </div>

      <input type="hidden" [value]="leagueId" formControlName="leagueId">
      <input type="hidden" [value]="leagueWeek" formControlName="eventOrderOfPlay" />
      <button type="submit" class="btn btn-md xbtn-primary">Submit</button>

    </form>
  </div>
</div>


