import { Component, OnInit } from '@angular/core';
import { UserServiceService } from './user-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public userProfile: any;
  public userRoles: any;
  opened: boolean = true;

  constructor(private userService: UserServiceService) { }

  ngOnInit(): void {
    this.userService.getUsersRoles().subscribe(x => {
      this.userRoles = x
    }, err => { console.log(err) }, () => {
    });
    this.userService.getUserProfile().subscribe(x => {
      this.userProfile = x
    }, err => { console.log(err) }, () => {
    })
  }
  
  userAuthorized(item: string) {
    return this.userRoles && this.userRoles.includes(item)
  }

}
