
<div  class="container p-5" style="width:600px">
  <a class="m-2" style="float:right" (click)="closeWindow()">Cancel</a>
  <div class="row">
    <div class="col-12">
      <h5>SENDING COMMUNICATION TO:</h5>
      <h3>{{details.emailTo}}</h3>
    </div>
  </div>
  <div style="height:200px;" id="editor"></div>
  <button class="m-2 btn btn-sm xbtn-primary" (click)="sendCommunication()">Send</button>
</div>




