import { Component } from '@angular/core';

@Component({
  selector: 'app-league-event-results',
  templateUrl: './league-event-results.component.html',
  styleUrls: ['./league-event-results.component.css']
})
export class LeagueEventResultsComponent {

  constructor() { }



}
