import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input'
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { UserServiceService } from './user-service.service';
import { SeriesComponent } from './series/series.component';
import { TournamentComponent } from './series/tournament/tournament.component';
import { CardViewComponent } from './series/tournament/round/card-view/card-view.component';
import { RoundRecapComponent } from './series/tournament/round/round-recap/round-recap.component';
import { LeagueComponent } from './league/league.component';
import { CreateLeagueComponent } from './league/create-league/create-league.component';
import { LeagueDetailsComponent } from './league/league-details/league-details.component';
import { LocationComponent } from './location/location.component';
import { CreateLocationComponent } from './location/create-location/create-location.component';
import { LocationDetailsComponent } from './location/location-details/location-details.component';
import { ManageLocationComponent } from './location/manage-location/manage-location.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { LocationSelectorComponent } from './location-selector/location-selector.component';
import { LeagueEventComponent } from './league/league-event/league-event.component';
import { CreateEventComponent } from './league/create-event/create-event.component';
import { TeamComponent } from './team/team.component';
import { SeriesDetailsComponent } from './series/series-details/series-details.component';
import { RoundComponent } from './series/tournament/round/round.component';
import { EditLeagueComponent } from './league/edit-league/edit-league.component';
import { EditEventComponent } from './league/edit-event/edit-event.component';
import { LeagueTeamComponent } from './league/league-team/league-team.component';
import { CreateLeagueTeamComponent } from './league/league-team/create-league-team/create-league-team.component';
import { EditLeagueTeamComponent } from './league/league-team/edit-league-team/edit-league-team.component';
import { LeagueTeamListComponent } from './league/league-details/league-team-list/league-team-list.component';
import { LeagueEventResultsComponent } from './league/league-event/league-event-results/league-event-results.component';
import { LeagueEventDetailsComponent } from './league/league-event/league-event-details/league-event-details.component';
import { SeriesSignUpComponent } from './series/series-sign-up/series-sign-up.component';
import { ScorecardUploadComponent } from './series/tournament/round/scorecard-upload/scorecard-upload.component';
import { AdvanceConfirmComponent } from './series/tournament/round/advance-confirm/advance-confirm.component';
import { CardValidationComponent } from './series/tournament/round/card-validation/card-validation.component';
import { CommunicationComponent } from './communication/communication.component';
import { LocationManagementInviteComponent } from './location/location-management-invite/location-management-invite.component';
import { PlayersViewComponent } from './players-view/players-view.component';
import { PlayerStatsComponent } from './players-view/player-stats/player-stats.component';
import { PlayerPastRoundsComponent } from './players-view/player-past-rounds/player-past-rounds.component';
import { PlayerSeriesViewComponent } from './players-view/player-series-view/player-series-view.component';
import { TeamSignUpComponent } from './series/tournament/team-sign-up/team-sign-up.component';
import { CommunicationsComponent } from './communications/communications.component';
import { CommunicationsComposerComponent } from './communications/communications-composer/communications-composer.component';
import { TeamRoundRecapComponent } from './series/tournament/round/team-round-recap/team-round-recap.component';
import { TeamScorecardUploadComponent } from './series/tournament/round/team-scorecard-upload/team-scorecard-upload.component';
import { ManualRoundEntryComponent } from './series/tournament/round/manual-round-entry/manual-round-entry.component';
import { TeamAdvancementConfirmComponent } from './series/tournament/round/team-advancement-confirm/team-advancement-confirm.component';
import { SimulatorsComponent } from './simulators/simulators.component';
import { RoundResultsComponent } from './series/tournament/round-results/round-results.component';
import { TeamBestBallAdvancementConfirmComponent } from './series/tournament/round/team-best-ball-advancement-confirm/team-best-ball-advancement-confirm.component';
import { ChallengesComponent } from './challenges/challenges.component';
import { ChallengeDetailsComponent } from './challenges/challenge-details/challenge-details.component';
import { CreateChallengeComponent } from './challenges/create-challenge/create-challenge.component';
import { EditChallengeComponent } from './challenges/edit-challenge/edit-challenge.component';
import { MediaItemsComponent } from './media-items/media-items.component';
import { UpdateMediaItemComponent } from './media-items/update-media-item/update-media-item.component';
import { ChallengeLeaderboardComponent } from './challenges/challenge-leaderboard/challenge-leaderboard.component';
import { ChallengeAnalyticsComponent } from './challenges/challenge-analytics/challenge-analytics.component';
import { CreateTournamentComponent } from './series/tournament/create-tournament/create-tournament.component';
import { EditTournamentComponent } from './series/tournament/edit-tournament/edit-tournament.component';
import { CreateSeriesComponent } from './series/create-series/create-series.component';
import { CreateRoundComponent } from './series/tournament/round/create-round/create-round.component';
import { EditRoundComponent } from './series/tournament/round/edit-round/edit-round.component';



@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SeriesComponent,
    TournamentComponent,
    CardViewComponent,
    RoundRecapComponent,
    LeagueComponent,
    CreateLeagueComponent,
    LeagueDetailsComponent,
    LocationComponent,
    CreateLocationComponent,
    LocationDetailsComponent,
    ManageLocationComponent,
    UserSearchComponent,
    LocationSelectorComponent,
    LeagueEventComponent,
    CreateEventComponent,
    TeamComponent,
    SeriesDetailsComponent,
    RoundComponent,
    EditLeagueComponent,
    EditEventComponent,
    LeagueTeamComponent,
    CreateLeagueTeamComponent,
    EditLeagueTeamComponent,
    LeagueTeamListComponent,
    LeagueEventResultsComponent,
    LeagueEventDetailsComponent,
    SeriesSignUpComponent,
    ScorecardUploadComponent,
    AdvanceConfirmComponent,
    CardValidationComponent,
    LocationManagementInviteComponent,
    PlayersViewComponent,
    PlayerStatsComponent,
    PlayerPastRoundsComponent,
    PlayerSeriesViewComponent,
    TeamSignUpComponent,
    CommunicationsComponent,
    CommunicationsComposerComponent,
    CommunicationComponent,
    TeamRoundRecapComponent,
    TeamScorecardUploadComponent,
    ManualRoundEntryComponent,
    TeamAdvancementConfirmComponent,
    SimulatorsComponent,
    RoundResultsComponent,
    TeamBestBallAdvancementConfirmComponent,
    ChallengesComponent,
    ChallengeDetailsComponent,
    CreateChallengeComponent,
    EditChallengeComponent,
    MediaItemsComponent,
    UpdateMediaItemComponent,
    ChallengeLeaderboardComponent,
    ChallengeAnalyticsComponent,
    CreateTournamentComponent,
    EditTournamentComponent,
    CreateSeriesComponent,
    CreateRoundComponent,
    EditRoundComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatTabsModule,
    MatExpansionModule,
    MatSliderModule,
    MatSnackBarModule,
    HttpClientModule,
    DragDropModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatStepperModule,
    MatSlideToggleModule,
  ],
  providers: [
    UserServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
