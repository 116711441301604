
<div class="container p-2" *ngIf="!isLoading">
  <div class="row">
    <div class="col-md-12 center">
      <h1 class="display-1">XGA COMMUNICATIONS</h1>
    </div>
  </div>
  <div class="row">
    <a *ngIf="verifyAccess('SystemAdmin') || verifyAccess('TournamentAdmin')" routerLink="/Communications/Create">Create</a>
    <ng-container *ngFor="let post of communications">
      <div class="card mb-3">
        <div class="card-header">
          <div class="row">
            <div class="col-9">
              <h2>{{post.title}}</h2>
            </div>
            <div class="col-3" style="text-align:right">
              <p><strong>{{post.postedBy}}</strong></p>
              <p class="lead">{{ post.postedDate | date:'short' }}</p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div [innerHTML]="sanitizeHTML(post.content)"></div>
        </div>
        <div class="card-footer">
          <h6 class="mb-2">COMMENTS</h6>
          <div *ngFor="let comment of post.comments">
            <div style="font-size:1.25em" [innerHTML]="sanitizeHTML(comment.content)"></div>
            <div>
              <p>{{comment.postedBy}}</p>
            </div>
            <div>
              <span class="lead">{{ comment.postedOn | date:'short' }}</span>
            </div>
            <hr />
          </div>
          <div class="mt-3">
            <input type="text" class="form-control" placeholder="Add a comment..." [(ngModel)]="newCommentContent[post.communicationId]">
            <button class="btn-sm btn-outline-primary mt-2" (click)="addComment(post.communicationId)">POST</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

</div>

<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
