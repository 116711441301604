<div class="container" style="padding:16px;">
  <div class="row">
    <h5>{{data.leagueTitle}}</h5>
    <a class="btn btn-sm xbtn-primary" (click)="openCreateDialog()">CREATE TEAM</a>
  </div>
  <div class="row" style="width:80vw;">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Created</th>
          <th>Members</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="teamsList?.length === 0">
          <tr>
            <td>Currently No Teams Registered.</td>
          </tr>
        </ng-container>
        <ng-container>
          <tr *ngFor="let team of teamsList">
            <td>{{ team.teamName }}</td>
            <td>{{ team.createdOn | date:'short'}}</td>
            <td>
              <div *ngFor="let member of team.teamMembers">
                <span>{{member.email}}</span>
              </div>
            </td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>
