import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppInviteRequest, LocationDetails } from '../../interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserServiceService } from '../../user-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-location-management-invite',
  templateUrl: './location-management-invite.component.html',
  styleUrls: ['./location-management-invite.component.css']
})
export class LocationManagementInviteComponent implements OnInit {
  form: FormGroup;
  public details: any
  public isLoading: boolean
  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: LocationDetails, private dialogRef: MatDialogRef<LocationManagementInviteComponent>,
    public userService: UserServiceService, private _snackBar: MatSnackBar) {
    this.isLoading = false
    this.details = data
    this.form = this.fb.group({
      locationId:[''],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    })
  }

    ngOnInit(): void {
      this.form.controls["locationId"].setValue(this.details.id)
    }

  onSubmit() {
    this.isLoading = true
    var request: AppInviteRequest = {
        email: this.form.controls['email'].value,
        locationId: this.details.id,
        userMakingRequest: ''
    }
    this.userService.postSendLMInvite(request).subscribe(x => {
      if (x) {
        this.isLoading = false
        this._snackBar.open('Location Manager invite emailed.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.dialogRef.close();
      }

    }, err => {
      console.log(err)
      this.isLoading = false
      this._snackBar.open('Error ', '', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: "failureNotice"
      });

    }, () => { })

  }
}
