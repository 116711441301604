import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../../user-service.service';
import { PlayersService } from '../players.service';

@Component({
  selector: 'app-player-past-rounds',
  templateUrl: './player-past-rounds.component.html',
  styleUrls: ['./player-past-rounds.component.css']
})
export class PlayerPastRoundsComponent implements OnInit {
  userProfile: any;
  public playerPreviousRounds: any;
  constructor(private userService: UserServiceService, private playersService: PlayersService) { }

  ngOnInit(): void {
    this.userService.getUsersCachedProfile().subscribe(x => {
      this.userProfile = x
      if (this.userProfile) {
        this.playersService.getPlayersRounds(this.userProfile.syncId).subscribe(y => {
          if (y) {
            const filteredRounds = y.filter((round: any) => {
              const holesPlayed = Object.keys(round.roundScores).length;
              return holesPlayed === 9 || holesPlayed === 18;
            });
            this.playerPreviousRounds = filteredRounds
          }
        }, err => { }, () => { })
      }
    }, err => { console.log(err) }, () => { })
  }

  getParValue(courseHoles: any[], holeKey: any): string {
    const foundHole = courseHoles.find(x => x.holeNumber === +holeKey);
    return foundHole ? foundHole.par : '';
  }

  getSortedKeys(roundScores: any): string[] {
    return Object.keys(roundScores).sort((a, b) => +a - +b);
  }

  getLocalTime(created: string) {
    let utcDate = new Date(created.replace(' ', 'T') + 'Z');
    return utcDate.toLocaleString()
  }

}
