import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService } from '../../user-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChallengesService } from '../challenges.service';
import { EditChallengeComponent } from '../edit-challenge/edit-challenge.component';

@Component({
  selector: 'app-challenge-details',
  templateUrl: './challenge-details.component.html',
  styleUrls: ['./challenge-details.component.css']
})
export class ChallengeDetailsComponent implements OnInit{
  public isLoading: boolean;
  public challengeContainer: any

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserServiceService,
    public dialog: MatDialog, private cd: ChangeDetectorRef, private _snackBar: MatSnackBar,
  private service: ChallengesService) {
    this.isLoading = true
  }


  ngOnInit(): void {
    this.isLoading = true;
    this.route.paramMap.subscribe(params => {
      const challengeId = params.get('challengeId');
        console.log("id is ", challengeId)
        if (challengeId !== null) {
          this.service.getChallenge(Number(challengeId)).subscribe(
            x => {
              console.log("Fetched challenge data:", x);
              this.challengeContainer = x;
              this.cd.markForCheck();
              this.isLoading = false;
            },
            err => {
              console.log(err);
              this.isLoading = false;
            }
          );
        }
      
    });
  }


}
