import { Component, ElementRef, Inject, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { LeagueServiceService } from '../../league-service.service';
import { LeagueData, LocationLeagueEventMatchGroup, LocationLeagueEventMatches, LocationLeagueTeam, LocationLeagueTeamEventRound, LocationLeagueTeamMember, LocationLeagueTeamMemberEventRound, Player } from '../../../interfaces';
import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeagueDetailsComponent } from '../../league-details/league-details.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-league-event-details',
  templateUrl: './league-event-details.component.html',
  styleUrls: ['./league-event-details.component.css']
})
export class LeagueEventDetailsComponent implements OnInit {
  @ViewChildren('matchedTeamListStar') matchedTeamListStars!: QueryList<CdkDropList>;
  startingList: LocationLeagueTeamEventRound[] = [];
  teams: LocationLeagueTeam[] = [];
  teamsCount: number;
  matchedTeamList: LocationLeagueEventMatchGroup[] = [];
  final: LocationLeagueEventMatches[] = [];


  teamMatchHolder: any[] = [];

  playerTeamAHolder: any[] = []
  playerTeamBHolder: any[] = []
  playerMatchHolder: any[] = [];

  constructor(private sanitizer: DomSanitizer, private service: LeagueServiceService,
    private dialogRef: MatDialogRef<LeagueDetailsComponent>, private renderer: Renderer2, private el: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: LeagueData) {
    this.teamsCount = 0
  }

  ngOnInit(): void {
    this.teamMatchHolder = []
    if (this.data.eventRoundAssignment !== "") {
      //we have a previously created round assignemnts, thus populate.

      this.final = JSON.parse(this.data.eventRoundAssignment)
    } else {
      this.createStartList()
    }
    
  }

  createStartList() {
    this.service.getLeagueTeams(this.data.leagueId).subscribe(x => {
      this.teams = x
      for (let i = 0; i < this.teams.length; i += 1) {
        let team: LocationLeagueTeamEventRound = {
            id: this.teams[i].id,
            leagueEventId: this.data.eventId,
            teamId: 0,
            directCompetitorTeamId: 0,
            teamMembers: [],
            teamName: this.teams[i].teamName,
            selected: false
        }

        for (let t = 0; t < this.teams[i].teamMembers.length; t += 1) {
          let player: Player = {
              name: this.teams[i].teamMembers[t].email,
              email: this.teams[i].teamMembers[t].email,
              syncId: 0
          }

          let entry: LocationLeagueTeamMemberEventRound = {
              id: this.teams[i].teamMembers[t].id,
              leagueEventId: this.data.eventId,
              directCompetitorTeamMemberId: 0,
              playerInfo: player
          }
          team.teamMembers.push(entry)
        }
        //team obj finshed
        this.startingList.push(team)
      }

    }, err => { console.log(err) }, () => { })
  }

  checkMatchMax() {
    if (this.teamMatchHolder.length > 0 && this.teamMatchHolder.length < 3) {
      return false
    } else { return true }
  }

  isTeamIn(team: any) {
    return this.teamMatchHolder.filter(x => x.id === team.id).length > 0;
  }

  selectTeam(event: any, team: any) {
    if (event) {  // if the checkbox is checked
      if (this.teamMatchHolder.length < 2) {
        this.teamMatchHolder.push(team);
      } else {
        this.teamMatchHolder = this.teamMatchHolder.splice(1, 1);
        this.teamMatchHolder.push(team)
      }
    } else {  // if the checkbox is unchecked
      const index = this.teamMatchHolder.indexOf(team);
      if (index > -1) {
        this.teamMatchHolder = this.teamMatchHolder.splice(index, 1);
      }
    }

  }


  setTeamMatch() {
    let match: LocationLeagueEventMatchGroup = {
        teamA: this.teamMatchHolder[0],
        teamB: this.teamMatchHolder[1],
        competitors: []
    }
    this.matchedTeamList.push(match)
    this.teamMatchHolder.forEach(obj => {
      const index = this.startingList.findIndex(x => x.id === obj.id);
      if (index > -1) {
        this.startingList.splice(index, 1);
      }
    })

    this.teamMatchHolder = []
  }

  selectMatch(event: any, match: LocationLeagueEventMatchGroup) {
    if (this.playerMatchHolder != null && !event) {
      this.playerMatchHolder = []
      this.playerTeamAHolder = []
      this.playerTeamBHolder = []
    }
    if (event) {
      match.teamA.teamMembers.forEach(x => {
        this.playerTeamAHolder.push(x)
      })
      match.teamB.teamMembers.forEach(x => {
        this.playerTeamBHolder.push(x)
      })
    }
    

  }

}
