import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TournamentService } from '../tournament.service';

@Component({
  selector: 'app-create-tournament',
  templateUrl: './create-tournament.component.html',
  styleUrls: ['./create-tournament.component.css']
})
export class CreateTournamentComponent implements OnInit{
  tournamentForm: FormGroup;
  series: any
  public isLoading: boolean;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateTournamentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private service: TournamentService,
    private _snackBar: MatSnackBar) {
    this.isLoading = true
    this.series = this.data
    console.log(this.series)
    this.tournamentForm = this.fb.group({
      id: [0],
      title: [''],
      description: [''],
      seriesId: [this.series.id],
      isTeamEvent: [''],
      //tournament Icon
      formFile: [''],
      startDate: [''],
      endDate: [''],
      //rulesId: ['', Validators.required],
      tournamentType: [''],

      maxTeamMemberCount: [0],
      userMakingRequest:[''],
      gameMode: ['']

    });
  }
    ngOnInit(): void {
      this.isLoading = false;
    }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.tournamentForm.patchValue({
        formFile: file
      });
      this.cd.markForCheck();
    }
  }

  isTeamType(): boolean {
    return this.tournamentForm.get('tournamentType')?.value === 'TEAM';
  }

  typeIsSelected(): boolean {
    return (this.tournamentForm.get('tournamentType')?.value === 'TEAM' ||
      this.tournamentForm.get('tournamentType')?.value === 'INDIVIDUAL')
  }

  onSubmit() {
    console.log(this.tournamentForm.get('gameMode')?.value)
    if (this.tournamentForm.valid) {
      const formData = new FormData();
      formData.append('id', this.tournamentForm.get('id')?.value);
      formData.append('title', this.tournamentForm.get('title')?.value);
      formData.append('description', this.tournamentForm.get('description')?.value);
      formData.append('seriesId', this.tournamentForm.get('seriesId')?.value);
      formData.append('isTeamEvent', this.tournamentForm.get('tournamentType')?.value == 'TEAM' ? 'true' : 'false')
      formData.append('startDate', this.tournamentForm.get('startDate')?.value);
      formData.append('endDate', this.tournamentForm.get('endDate')?.value);
      formData.append('tournamentType', this.tournamentForm.get('tournamentType')?.value);
      formData.append('maxTeamMemberCount', this.tournamentForm.get('maxTeamMemberCount')?.value);
      formData.append('formFile', this.tournamentForm.get('formFile')?.value);
      formData.append('gameMode', this.tournamentForm.get('gameMode')?.value);

      this.service.createTournament(formData).subscribe(x => {
        this._snackBar.open('Round created successfully!', 'Close', { duration: 3000 });
        this.dialogRef.close(x);

      }, err => {
        console.log(err)
        this.dialogRef.close();
        this._snackBar.open('Error Occured!', 'Close', { duration: 3000 });
      }, () => { })
      


    }
  }

}
