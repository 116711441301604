import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommentRequest, MassCommunicationRequest, PublicCommunicationRequest } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CommunicationsService {

  constructor(private http: HttpClient) { }

  getAllPublicCommunications(): Observable<any> {
    return this.http.get<any>('/api/communications/public')
  }

  createPublicCommunication(communication: string, title: string): Observable<any> {
    const data : PublicCommunicationRequest = {
        content: communication,
        title: title
    }
    console.log("transmisting this data", data)
    return this.http.post<any>('/api/communications/createPublic', data)
  }

  addCommentToCommunication(request: CommentRequest): Observable<any> {
    return this.http.post<any>('/api/communications/comment', request);
  }


}
