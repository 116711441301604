<div *ngIf="isLoading" class="container-fluid">
  <mat-progress-bar style="color:#007041" mode="query"></mat-progress-bar>
</div>
<div [class.loading-opacity]="isLoading" class="container-fluid p-1">
  <div *ngIf="(verifyAccess('LocationManager') || verifyAccess('SystemAdmin'))">
    <div class="row">
      <div class="col-6 p-3">
        <div class="row" *ngIf="(verifyAccess('SystemAdmin') || verifyAccess('LocationManager')) && challengeDetails">
          <h1>{{challengeDetails?.name}}</h1>
          <h3>{{challengeDetails?.startDate | date:'shortDate'}} - {{ challengeDetails?.endDate |date:'shortDate' }}</h3>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(verifyAccess('LocationManager') || verifyAccess('SystemAdmin'))" class="container-fluid">
    <div style="display:flex;justify-content:flex-end">
      <button class="col-sm-1" mat-button (click)="exportToCSV()"> 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-cloud-download" viewBox="0 0 16 16">
          <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
          <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
        </svg>
      </button>
      <button class="col-sm-1" mat-button (click)="toggleView()" *ngIf="isGroupedView">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
        </svg>
      </button>
      <button class="col-sm-1" mat-button (click)="toggleView()" *ngIf="!isGroupedView">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-people-fill" viewBox="0 0 16 16">
          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
        </svg>
      </button>
    </div>

    <div class="row" *ngIf="!isGroupedView">
      <mat-paginator [length]="length"
                     [pageSize]="pageSize"
                     [pageIndex]="pageIndex"
                     [pageSizeOptions]="[5, 10, 25, 50]"
                     (page)="onPageChange($event)"
                     style="width:100%">
      </mat-paginator>

      <!-- Table View for Individual Player Scores -->
      <table class="table table-hover table-striped" matSort (matSortChange)="sortData($event)">
        <thead>
          <tr>
            <th class="lead" mat-sort-header="displayname">PLAYER</th>
            <th class="lead" mat-sort-header="email">EMAIL</th>
            <th class="lead" mat-sort-header="location">LOCATION</th>
            <th class="lead" mat-sort-header="date">PLAYED ON</th>
            <th *ngIf="challengeShots! == 0" class="lead" mat-sort-header="score">SCORE</th>
            <th *ngIf="challengeShots > 0" class="lead" mat-sort-header="round">DISTANCE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of paginatedResults">
            <td>{{ result.userName || result.anonymousDisplayName }}</td>
            <td *ngIf="result.userSyncId">{{ result.email }}</td>
            <td *ngIf="!result.userSyncId"></td>
            <td *ngIf="result.location">{{ result.location }}</td>
            <td *ngIf="!result.location"></td>
            <td>{{ result.gameSessionPlayed | date:'medium' }}</td>
            <td *ngIf="challengeShots == 0">{{ result.challengeScore }}</td>
            <td *ngIf="challengeShots > 0">{{ result.distanceToPin }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row" *ngIf="isGroupedView">
      <!-- Table View for Grouped Player Scores -->
      <mat-paginator [length]="groupedLength"
                     [pageSize]="groupedPageSize"
                     [pageIndex]="groupedPageIndex"
                     [pageSizeOptions]="[5, 10, 25, 50]"
                     (page)="onPageChange($event)"
                     style="width:100%">
      </mat-paginator>
      <table class="table table-hover" matSort (matSortChange)="sortData($event)">
        <thead>
          <tr>
            <th class="lead" mat-sort-header="displayname">PLAYER</th>
            <th class="lead">PLAYED ON</th>
            <th *ngIf="challengeShots! == 0" class="lead">SCORE</th>
            <th *ngIf="challengeShots > 0" class="lead">DISTANCE</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let group of paginatedGroupedResults">
            <tr class="table-dark">
              <td>{{ group.displayName }} <span style="font-size:1.25em">(</span> {{group.email}} <span style="font-size:1.25em">)</span></td>
              <td>{{group.location}}</td>
              <td></td>
            </tr>
            <tr *ngFor="let score of group.scores">
              <td>
                
              </td>
              <td>
                {{ score.gameSessionPlayed | date:'medium' }}
              </td>
              <td *ngIf="challengeShots! == 0">
                {{ score.challengeScore }}
              </td>
              <td *ngIf="challengeShots > 0">
                {{ score.distanceToPin }}
              </td>
            </tr>
          </ng-container>

        </tbody>
      </table>
    </div>



  </div>
  </div>

