<div class="container">
  <div class="row">
    <div class="col-md-4">
      <select (change)="onLocationChange($event)">
        <option *ngFor="let location of locations" [value]="location.id">{{ location.name }}</option>
      </select>

    </div>
  </div>

</div>
