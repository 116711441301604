import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoundService } from '../round.service';

@Component({
  selector: 'app-create-round',
  templateUrl: './create-round.component.html',
  styleUrls: ['./create-round.component.css']
})
export class CreateRoundComponent implements OnInit {
  public isLinear = false;
  roundForm: FormGroup;
  courseSettingForm: FormGroup;
  teesForm: FormGroup;
  calibrationForm: FormGroup;
  dynamicForm: FormGroup;
  tournament: any;
  public isLoading: boolean;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private service: RoundService,
    private dialogRef: MatDialogRef<CreateRoundComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar) {
    this.tournament = this.data
    this.isLoading = false
    this.roundForm = this.fb.group({
      roundId: [0],
      name: ['', Validators.required],
      courseCode: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      tournamentId: [this.tournament.id, Validators.required],
      //gross or net is roundType
      roundType: [0, Validators.required],
      // game type is play type, stroke, bestball, ...
      playMode: [0, Validators.required],
      courseHoleSelection: ['', Validators.required],
      tournamentType: ['', Validators.required]
    });
    this.courseSettingForm = this.fb.group({
      eventId: [0], // Default to empty string, replace with actual default value as needed
      fieldDistance: [3, [Validators.required]],
      greenDistance: [2, [Validators.required]],
      speedUnit: [3, [Validators.required]],
      swingView: [7, [Validators.required]],
      puttingView: [1, [Validators.required]],
      autoFlyBy: [1, [Validators.required]],
      xPlateGreen: [0, [Validators.required]],
      xPlateField: [0, [Validators.required]],
      autoBallFeed: [2, [Validators.required]],
      puttingPreview: [3, [Validators.required]],
      conceed: [3, [Validators.required]],
      obTee: [2, [Validators.required]],
      playableArea: [1, [Validators.required]],
      doublePar: [2, [Validators.required]],
      windOption: [1, [Validators.required]],
      clubSelectionInTheFridge: [2, [Validators.required]],
      swingInformation: [5, [Validators.required]],
      fieldTrajectory: [2, [Validators.required]],
      greenCondition: [1, [Validators.required]],
      puttingGrid: [2, [Validators.required]],
      rough: [90, [Validators.required]],
      greenSideBunker: [70, [Validators.required]],
      fairwayBunker: [90, [Validators.required]],
    });
    this.teesForm = this.fb.group({
      eventId: [0],
      maleTeeColor: [0, Validators.required],
      femaleTeeColor: [0, Validators.required],
      seniorTeeColor: [0, Validators.required]
    });
    this.calibrationForm = this.fb.group({
      eventId: [0], // Default to empty string, replace with actual default value as needed
      ballSpeed: [103, [Validators.required]], // Nullable int, default to null or ''
      puttingSpeed: [60, [Validators.required]], // Nullable int, default to null or ''
      backSpinOffset: [0, [Validators.required]], // Nullable int, default to null or ''
      sideSpinOffset: [0, [Validators.required]], // Nullable int, default to null or ''
      backSpinGain: [125, [Validators.required]], // Nullable int, default to null or ''
      sideSpinGain: [50, [Validators.required]], // Nullable int, default to null or ''
    });
    this.dynamicForm = this.fb.group({
      eventId: [0], // Validators can be adjusted as needed
      gravity: [100, [Validators.required]], // Assuming 0 is a sane default, adjust as necessary
      rollingResistance: [100, [Validators.required]],
      ballSpeedCompensation: [100, [Validators.required]],
      puttSpeedCompensation: [100, [Validators.required]],
      sliceSpinCompensation: [100, [Validators.required]],
      hookSpinCompensation: [100, [Validators.required]],
      backSpinCompensation: [100, [Validators.required]],
      forwardSpinCompensation: [100, [Validators.required]]
    });
  }



  ngOnInit(): void {
    //set data if you need to
    if (this.tournament) {
      if (this.tournament.isTeamEvent) {
        this.roundForm.controls["tournamentType"].setValue("TEAM")
      } else {
        this.roundForm.controls["tournamentType"].setValue("INDIVIDUAL")
      }
    }
    this.isLoading = false;
  }




  onConfirm() {

    this.isLoading = true
    this.cd.detectChanges();

    // Update the form value
    const formData = new FormData();

    const roundDetails = {
      Tournament: this.roundForm.value,
      CourseSettings: this.courseSettingForm.value,
      Calibration: this.calibrationForm.value,
      Dynamics: this.dynamicForm.value,
      Tees: this.teesForm.value // Assuming you have a form for the tees
    };
    for (const [key, value] of Object.entries(roundDetails)) {
      console.log(`${key}: `, value); // Verify the values are correct
      if (value) {
        formData.append(key, JSON.stringify(value));
      } else {
        console.error(`${key} is undefined.`);
      }
    }
    // Append properties of Challenge
    Object.entries(this.roundForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Tournament.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Tournament.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.courseSettingForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`CourseSettings.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`CourseSettings.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.calibrationForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Calibration.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Calibration.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.dynamicForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Dynamics.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Dynamics.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });

    Object.entries(this.teesForm.value).forEach(([key, value]) => {
      // Check if the value is a string or a number and append it to formData.
      // If it's a file, it should be appended without converting to string.
      if (typeof value === 'string' || typeof value === 'number') {
        formData.append(`Tees.${key}`, value.toString());
      } else if (value instanceof File) {
        formData.append(`Tees.${key}`, value);
      } else {
        // Handle other types or throw an error
        console.error(`The type of the value for the key "${key}" is not supported.`);
      }
    });
    console.log("trying to create the tournament now", formData)

    this.service.postCreateTournamentRound(formData).subscribe(x => {
      if (x) {
        this._snackBar.open('Round created.', '', {
          duration: 3000,
          verticalPosition: "bottom",
          horizontalPosition: "right",
          panelClass: "successNotice"
        });
        this.dialogRef.close(x)
      }      
    }, err => {
      console.log(err)
      this._snackBar.open('Error occured while trying to create round.', '', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "center",
        panelClass: "failureNotice"
      });
      this.dialogRef.close()
    }, () => { })


  }

  camelCaseToNormal(text: string): string {
    // First, use a regular expression to insert a space before all capital letters
    let result = text.replace(/([A-Z])/g, ' $1');
    // Then, capitalize the first letter of the resulting string
    result = result.charAt(0).toUpperCase() + result.slice(1);
    // Trim any leading spaces that might have been added by the regex replacement
    return result.trim();
  }

  getObjectKeys(obj: any): string[] {

    return Object.keys(obj);
  }

  getOptionTextByIdAndValue(selectId: string, value: string | number): string | undefined {
    // Use nativeElement to access the DOM API
    const selectElement: HTMLSelectElement = document.getElementById(selectId) as HTMLSelectElement;
    if (!selectElement) return undefined;

    const optionElement = selectElement.querySelector(`option[value="${value}"]`) as HTMLOptionElement;
    return optionElement ? optionElement.text : undefined;
  }

  isImageFile(controlName: string): boolean {
    const file = this.roundForm.get(controlName)?.value;
    return file instanceof File && file.type.startsWith('image/');
  }
}
