import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaItemsService {

  constructor(private http: HttpClient) { }

  postUpdateMediaItem(formData: FormData): Observable<any> {
    return this.http.post<any>(`/api/media/updateMediaItem`, formData);
  }

}
