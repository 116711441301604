import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { UserServiceService } from '../user-service.service';
import { MatDialog } from '@angular/material/dialog';
import { LocationDetails } from '../interfaces';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  myControl = new FormControl();
  public locations: any
  public isLoading: boolean

  filteredOptions: Observable<any[]> | undefined;
  paginatedLocations: any[] = [];
  filteredLocations: any[] | null = null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private cd: ChangeDetectorRef, public app: AppComponent, private userService: UserServiceService, public dialog: MatDialog) {
    this.isLoading = true
  }

  verifyAccess(roleRequired: string) {
    return this.userService.verifyUserRole(roleRequired);
  }

  ngOnInit(): void {
    this.userService.getLocations().subscribe(x => {
      this.locations = x
      this.paginatedLocations = x
      this.isLoading = false
    }, err => { console.log(err) }, () => { });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''), // Initialize the stream with an empty string
      map(value => this._filter(value)) // Apply the filtering logic
    );
  }

  private _filter(val: string): any[] {
    const filterValue = val.toLowerCase();

    // Ensure this.details.individualCompetitors is an array
    const locs = this.locations || [];

    if (!val) {
      return locs;
    } else {
      return locs.filter((individual: any) =>
        individual.name.toLowerCase().includes(filterValue)
      );
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.updatePagination();
      });
    }
  }

  openLocationDetails(location: LocationDetails) {
    const dialogRef = this.dialog.open(LocationDetailsComponent, {
      data: location
    })
    dialogRef.afterClosed().subscribe(result => {

    })
  }

  updatePagination() {
    const currentList = this.filteredLocations || this.locations;
    if (this.paginator && currentList) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const endIndex = startIndex + this.paginator.pageSize;
      this.paginatedLocations = currentList.slice(startIndex, endIndex);
      this.cd.markForCheck();
    }
  }

  filterAndPaginateCompetitors(name: string) {
    this.filteredLocations = this.locations.filter((loc: any) =>
      loc.name.toLowerCase() === name.toLowerCase()
    );
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.updatePagination();
  }

  resetSearch() {
    // Clear the search input
    this.myControl.setValue('');

    // Reset the filtered list
    this.filteredLocations = null;

    // Reset the pagination to show the full list
    this.updatePagination();

    // If using a paginator, reset it to the first page
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  onNameSelect(event: MatAutocompleteSelectedEvent) {
    const selectedEmail = event.option.value;
    this.filterAndPaginateCompetitors(selectedEmail);
  }

}
