import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.css']
})
export class LocationSelectorComponent implements OnInit {
  public locations: any;

  constructor(public app: AppComponent) { }


    ngOnInit(): void {
      this.locations = this.app.userProfile.assignedLocations
  }

  onLocationChange($event: any) {
    // Do something with the selected value
    // Call other functions or perform desired actions based on the selected value

  }

}
