import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private http: HttpClient) { }

  getLocalDateTime(utcDate: string): string {
    const date = new Date(utcDate); // Create a Date object from the UTC string
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000); // Convert to local time

    return localDate.toLocaleString(); // Convert to local time format
  }

  getCourses(): Observable<any> {
    return this.http.get<any>(`/api/course/getAllCourses`, { withCredentials: true })
  }

  uploadImageToBlob(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post('/api/utility/uploadimage', formData);
  }

}
