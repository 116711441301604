import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SeriesService } from './series.service';
import { UserServiceService } from '../user-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SeriesSignUpComponent } from './series-sign-up/series-sign-up.component';
import { TournamentRoundViewRequest, XCupSignUpInfo } from '../interfaces';
import { RoundComponent } from './tournament/round/round.component';
import { TeamSignUpComponent } from './tournament/team-sign-up/team-sign-up.component';
import { CreateSeriesComponent } from './create-series/create-series.component';
import { CreateTournamentComponent } from './tournament/create-tournament/create-tournament.component';
import { CreateRoundComponent } from './tournament/round/create-round/create-round.component';
import { EditTournamentComponent } from './tournament/edit-tournament/edit-tournament.component';
import { EditRoundComponent } from './tournament/round/edit-round/edit-round.component';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.css']
})
export class SeriesComponent implements OnInit {
  public isLoading: boolean;
  series: any;
  constructor(private service: SeriesService, private userService: UserServiceService,
    public dialog: MatDialog) {
    this.isLoading = true
  }

    ngOnInit(): void {
      this.service.getAllSeries().subscribe(x => {
        this.series = x
        this.isLoading = false
      }, err => { console.log(err) }, () => { })
  }

  verifyAccess(roleRequired: string) {
    return this.userService.verifyUserRole(roleRequired);
  }

  signUp(tournament: any, focused: number, seriesId: number): void {
    const data: XCupSignUpInfo = {
        seriesId: seriesId,
        focused: focused,
        isTeamevent: tournament.isTeamEvent,
        locationId: 0,
        selectedTournament: tournament
    }
    if (tournament.isTeamEvent) {
      const dialogRef = this.dialog.open(TeamSignUpComponent, {
        data: data
      })
      dialogRef.afterClosed().subscribe(result => {

      })
    } else {
      const dialogRef = this.dialog.open(SeriesSignUpComponent, {
        data: data
      })
      dialogRef.afterClosed().subscribe(result => {

      })
    }
  }

  getTwoRoundsExcluding(tournamentId: number, roundId: number) {
    // Find the specific tournament request using the provided tournamentId
    const tournament = this.series.find((seriesItem:any) => seriesItem.tournamentRequests.some((tr:any) => tr.id === tournamentId))
      .tournamentRequests.find((tr:any) => tr.id === tournamentId);

    if (!tournament) {
      return [];
    }
    // Filter rounds not equal to roundId and take the first two
    const filteredRounds = tournament.tournamentRounds.filter((round:any) => round.id !== roundId);
    return filteredRounds
  }



  openTournamentRoundInfo(round: any, seriesId: number, tournamentId: number, gameMode: number) {
    var nextRounds = this.getTwoRoundsExcluding(round.tournamentId, round.id)
    var request: TournamentRoundViewRequest = {
        round: round,
        nextRounds: nextRounds,
        seriesId: seriesId,
        tournamentId: tournamentId,
      gameMode: gameMode
    }
    const dialogRef = this.dialog.open(RoundComponent, {
      data: request
    })

    dialogRef.afterClosed().subscribe(result => {
      
    })
  }

  createSeries() {
    const dialogRef = this.dialog.open(CreateSeriesComponent)
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        window.location.reload();
      }
    })
  }



  createTournament(series: any) {
    const dialogRef = this.dialog.open(CreateTournamentComponent, {
      data: series
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const found = this.series.find((s: any) => s.id == series.id);
        if (found) {
          if (!found.tournamentRequests) {
            found.tournamentRequests = [];
          }
          found.tournamentRequests.push(result);
        }
      }
    })
  }

  editTournament(tour: any, seriesId: number) {
    const dialogRef = this.dialog.open(EditTournamentComponent, {
      data: tour
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return; // Exit if no result from dialog
      }

      const foundSeries = this.series.find((s: any) => s.id === seriesId);
      if (foundSeries) {
        if (!foundSeries.tournamentRequests) {
          foundSeries.tournamentRequests = [];
        }

        const existingRequestIndex = foundSeries.tournamentRequests.findIndex((req: any) => req.id === result.id);

        if (existingRequestIndex > -1) {
          // Update existing request
          foundSeries.tournamentRequests[existingRequestIndex] = result;
        } else {
          // Add new request
          foundSeries.tournamentRequests.push(result);
        }
      }
    });
  }

  editRound(round: any, seriesId: number, tournamentId: number) {
    console.log(round);
    const dialogRef = this.dialog.open(EditRoundComponent, {
      data: {round: round, seriesId: seriesId, tournamentId: tournamentId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return; // Exit if no result from dialog
      }

      const foundSeries = this.series.find((s: any) => s.id === seriesId);
      if (foundSeries) {
        if (!foundSeries.tournamentRequests) {
          foundSeries.tournamentRequests = [];
        }

        const existingRequestIndex = foundSeries.tournamentRequests.findIndex((req: any) => req.id === tournamentId);

        if (existingRequestIndex > -1) {
          // Update existing request
          console.log(foundSeries.tournamentRequests[existingRequestIndex])
        } else {
          // Add new request
          foundSeries.tournamentRequests.push(result);
        }
      }
    });
  }

  createRound(tournament: any) {
    const dialogRef = this.dialog.open(CreateRoundComponent, {
      data: tournament
    })
    dialogRef.afterClosed().subscribe(result => {
      //result is the new round created   so you need to add it to the tournament.
      const foundTournament = this.series.find((s: any) => s.tournamentRequests.some((tr: any) => tr.id === tournament.id));
      if (foundTournament) {
        const tournamentRequest = foundTournament.tournamentRequests.find((tr: any) => tr.id === tournament.id);
        if (tournamentRequest) {
          if (!tournamentRequest.tournamentRounds) {
            tournamentRequest.tournamentRounds = [];
          }
          tournamentRequest.tournamentRounds.push(result);
        }
      }
    })
  }

  isDateInPlay(startDate: Date | string, endDate: Date | string): boolean {
    if (typeof startDate === 'string') {
      startDate = new Date(startDate);
    }
    if (typeof endDate === 'string') {
      endDate = new Date(endDate);
    }

    if (!(startDate instanceof Date) || !(endDate instanceof Date) || isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error('Invalid date provided:', startDate, endDate);
      return false;
    }

    const currentDate = new Date();
    const strippedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const strippedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const strippedEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    return strippedCurrentDate >= strippedStartDate && strippedCurrentDate <= strippedEndDate;
  }

  
  displayTeamRegisterButton(tour: any) {
    if (Array.isArray(tour.tournamentRounds)) {
      var compRound = tour.tournamentRounds.find((x: any) =>
        x.isCompetitionRound === true && new Date(x.endDate).getTime() > Date.now()
      );
      return compRound !== undefined;
    }
    return false;
  }




}
