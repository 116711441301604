import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScoreValidationRequest, ScorecardViewRequest, SeriesCompetitorAdvancementRequst, TournamentAdvancementRequest } from '../../../../interfaces';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoundService } from '../round.service';
import { ActivatedRoute } from '@angular/router';
import { TournamentService } from '../../tournament.service';

@Component({
  selector: 'app-advance-confirm',
  templateUrl: './advance-confirm.component.html',
  styleUrls: ['./advance-confirm.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvanceConfirmComponent implements OnInit {
  public isLoading: boolean;
  form: FormGroup;
  constructor(private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: TournamentAdvancementRequest,
    public dialog: MatDialog, private dialogRef: MatDialogRef<AdvanceConfirmComponent>, private service: TournamentService) {
    this.isLoading = true
    this.form = this.fb.group({
      handicap: [''],
      selectedRound: [null, Validators.required]  // form control for selected round
    });
  }

  ngOnInit(): void {
    this.isLoading = false
  }

  onSubmit(): void {
    this.isLoading = true
    if (this.form.valid) {
      // Handle submission logic here, for example:
      var goingTo: number[] = []
      if (this.form.value.selectedRound === -1) {
        goingTo = this.data.nextRounds.map((nr: any) => nr.id);
      } else {
        goingTo.push(this.form.value.selectedRound)
      }
      const handicapValue = this.form.controls['handicap'].value != '' ? this.form.controls['handicap'].value : '';

      var request: SeriesCompetitorAdvancementRequst = {
          seriesTournamentId: this.data.tournamentId,
          seriesCompetitorId: this.data.individual.seriesCompetitorId,
          nextRounds: goingTo,
          approvingAdvance: '',
          handicap: handicapValue,
          pastRoundsLocation: this.data.pastRoundPlayedLocation
      }
      this.service.postAdvanceSeriesCompetitor(request).subscribe(x => {
        this.dialogRef.close(true); // Close the dialog
      }, err => {
        console.log(err)
      }, () => { })

      
    }
  }
}
