<div [style.display]="isLoading ? 'none' : 'block'"  class="container">
 
  <div class="row">
    <div class="col-md-4">
      <select (change)="onLocationChange($event)">
        <option *ngFor="let location of locations" [value]="location.id">{{ location.name }}</option>
      </select>

    </div>
  </div>



  <div class="row">
    <div class="col-md-12">
      <h1>Leagues</h1>
    </div>
    <div class="col-md-12">
      <a [routerLink]="['/Leagues/Create']" class="btn btn-sm xbtn-primary">Create New</a>
    </div>
  </div>
  <div class="row">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="lead">
            TITLE
          </th>
          <th class="lead">
            START
          </th>
          <th class="lead">
            # OF WEEKS
          </th>
          <th class="lead">
            STATUS
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let league of localLeaguesToDisplay">
          <td><a [routerLink]="['/Leagues/Details', league.id]">{{ league.title }}</a></td>
          <td>{{ getLocalTime(league.startDate) }}</td>
          <td>{{ league.totalNumberOfWeeks }}</td>
          <td>{{ league.isActive ? 'Active' : 'Inactive' }}</td>
          <td><a (click)="openLeagueTeamDirectory(league)">Teams</a></td>
          <td>
            <a (click)="openEditDialog(league)">Edit</a> |
            <a  [routerLink]="['/Leagues/Details', league.id]">Details</a>
          </td>
        </tr>

      </tbody>
    </table>
    <p *ngIf="!isLoading && localLeaguesToDisplay === null">Currenlty No Leages.</p>
  </div>
</div>

<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:33%">
    <div class="col-sm-12">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
