<div *ngIf="!isLoading" class="container">
  <div class="row">
    <div class="col-sm-2">
      <a routerLink="/Leagues" class="btn btn-sm xbtn-primary">Back</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10">
      <h1>{{locationLeague.title}}</h1>
      <h4>STARTS {{ getLocalTime(locationLeague.startDate)}}</h4>

    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-12">
      <h4>NUMBER OF WEEKS/EVENTS: {{locationLeague.totalNumberOfWeeks}}</h4>
    </div>
    <ol class="col-md-10 list-group list-group-numbered">
      <li *ngFor="let week of getWeeksArray(locationLeague.totalNumberOfWeeks)" class="list-group-item d-flex justify-content-between align-items-start">
        <div *ngIf="hasEventForWeek(week)">
          <!-- Display events for the week -->
          <div *ngFor="let event of getEventsForWeek(week)" class="row">
            <!-- Display event details -->
            <div class="fw-bold col-4">
              {{event.title}}
            </div>
            <div class="col-2">
              {{ getCourseTitle(event.eventCourseId)}} - <div *ngIf="event.eventCourseHoleList === '1,2,3,4,5,6,7,8,9'">
                FRONT 9
              </div>
              <div *ngIf="event.eventCourseHoleList == '10,11,12,13,14,15,16,17,18'">
                BACK 9
              </div>
              <div *ngIf="event.eventCourseHoleList == '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18'">
                18
              </div>
            </div>

            <div class="col-4">
              <span class="lead">{{ event.eventDate | date:'short'}} - {{ event.eventCutOffDate | date:'short'}}</span>
              <div class="col-2">
                <a (click)="openEditLeagueEventDialog(event)">Edit</a>

                <div>
                  <a (click)="openLeagueEventDetailsDialog(event)">Results</a>
                </div>


              </div>

            </div>
          </div>
        </div>
        <div *ngIf="!hasEventForWeek(week)">
          <!-- Display empty div with create button -->
          <div class="ms-2 me-auto">
            <div class="fw-light">EVENT NEEDS TO BE CREATED.</div>
            <button class="btn btn-sm xbtn-primary" (click)="openDialog(week)">Create Event</button>
          </div>
        </div>

      </li>
    </ol>
  </div>

</div>


<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:33%">
    <div class="col-sm-12">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
