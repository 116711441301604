import { Component, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UserServiceService } from '../user-service.service';



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})


export class MainComponent implements OnInit {
  public roles: any;
  opened: boolean = false;

  constructor(private userService: UserServiceService) { }

  ngOnInit(): void {
    this.userService.getUsersRoles().subscribe(x => {
      this.roles = x
    }, err => { console.log(err) }, () => {
    })
  }

  userAuthorized(item: string) {
    return this.roles.includes(item)
  }



}
