import { Component, Inject, OnInit } from '@angular/core';
import { LocationDetails } from '../../interfaces';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocationManagementInviteComponent } from '../location-management-invite/location-management-invite.component';

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.css']
})
export class LocationDetailsComponent implements OnInit{
  public details: any
  constructor(@Inject(MAT_DIALOG_DATA) public data: LocationDetails, private dialogRef: MatDialogRef<LocationDetailsComponent>, public dialog: MatDialog) {
    this.details = this.data
  }
    ngOnInit(): void {
        
    }

  openLMInvite() {
    const dialogRef = this.dialog.open(LocationManagementInviteComponent, {
      data: this.details
    })
    dialogRef.afterClosed().subscribe(result => {

    })
  }

}
