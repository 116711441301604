import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from '../../utility.service';
import { CreateLeagueEventDialogData } from '../../interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeagueServiceService } from '../league-service.service';
import { LeagueDetailsComponent } from '../league-details/league-details.component';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})
export class CreateEventComponent implements OnInit {
  leagueEventForm: FormGroup;
  courses: any;
  public leagueId: number;
  public leagueTitle: string;
  public leagueWeek: number;
  constructor(private route: ActivatedRoute,
    private leagueService: LeagueServiceService,
    private formBuilder: FormBuilder, private utilityService: UtilityService,
    private dialogRef: MatDialogRef<LeagueDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateLeagueEventDialogData) {
    this.leagueId = 0
    this.leagueEventForm = this.formBuilder.group({
      id: [0],
      leagueId: [0],
      title: [null],
      description: [null],
      eventCourseId: [null],
      eventCourseHoleList: [null],
      eventDate: [null],
      eventCutOffDate: [null],
      eventType: [0],
      eventOrderOfPlay: [0],
      isActive: [false]
    });
    this.leagueTitle = this.data.leagueTitle
    this.leagueWeek = this.data.eventOrder
  }


  ngOnInit() {
    this.utilityService.getCourses().subscribe(x => {
      this.courses = x


    }, err => { }, () => { })
    this.leagueEventForm.controls['leagueId'].setValue(this.data.leagueId)
    this.leagueEventForm.controls['eventOrderOfPlay'].setValue(this.data.eventOrder)
  }

  onSubmit() {
    this.leagueService.createLeagueEvent(this.leagueEventForm.value).subscribe(x => {
    }, err => {
      console.log(err)
    }, () => {
      
    })
    this.dialogRef.close()
  }




}
