import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LeagueServiceService } from '../league-service.service';
import { UtilityService } from '../../utility.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateEventComponent } from '../create-event/create-event.component';
import { CreateLeagueEventDialogData, EditLeagueDialogData, EditLeagueEventDialogData, LeagueData } from '../../interfaces';
import { EditLeagueComponent } from '../edit-league/edit-league.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LeagueEventDetailsComponent } from '../league-event/league-event-details/league-event-details.component';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-league-details',
  templateUrl: './league-details.component.html',
  styleUrls: ['./league-details.component.css']
})
export class LeagueDetailsComponent implements OnInit {
  public isLoading: boolean;
  public locationLeague: any;
  public courses: any;
  constructor(private _snackBar: MatSnackBar, private route: ActivatedRoute, private leagueService: LeagueServiceService,
    public utilityService: UtilityService, public dialog: MatDialog)
  {
    this.isLoading = true;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      var id = Number(params.get('id'))
      this.utilityService.getCourses().subscribe(x => { this.courses = x })
      this.leagueService.getLeague(id).subscribe(x => {
        this.locationLeague = x;
        this.isLoading = false;
      }, err => { }, () => { })

    }, err => { }, () => { });

  }

  hasEventForWeek(week: number): boolean {
    return this.locationLeague.events !== null && this.locationLeague.events.some((x: any) => x.eventOrderOfPlay === week);
  }

  getEventsForWeek(week: number): any[] {
    return this.locationLeague.events !== null ? this.locationLeague.events.filter((x: any) => x.eventOrderOfPlay === week) : [];
  }

  createEventForWeek(week: number): void {
    // Implement your logic to create a new event for the specified week
  }

  getWeeksArray(totalNumberOfWeeks: number): number[] {
    return Array.from({ length: totalNumberOfWeeks }, (_, i) => i + 1);
  }

  getLocalTime(utcDate: string): string {
    return this.utilityService.getLocalDateTime(utcDate);
  }

  getCourseTitle(courseId: number): string {
    const course = this.courses.find((c: any) => c.id === courseId);
    return course ? course.title : '';
  }

  openEditLeagueEventDialog(event: any): void {
    const editData: EditLeagueEventDialogData = {
        id: event.id,
        leagueId: this.locationLeague.id,
        description: event.description,
        eventDate: event.eventDate,
        eventCutOffDate: event.eventCutOffDate,
        title: event.title,
        eventCourseHoleList: event.eventCourseHoleList,
        eventType: 0,
        eventOrderOfPlay: 0,
        eventCourseId: 0
    }
    const dialogRef = this.dialog.open(EditLeagueComponent, {
      data: editData
    })

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = true;
      this.leagueService.getLeague(this.locationLeague.id).subscribe(x => {
        this.locationLeague = x
        this.isLoading = false
        this._snackBar.open('League updated.', '', {
          duration: 2000,
          verticalPosition: "top",
          horizontalPosition: "center",

        });
      }, err => { console.log(err), () => { } })
    })

  }


  openDialog(week: number,): void {
    const dialogData: CreateLeagueEventDialogData = {
        leagueId: this.locationLeague.id,
        locationId: 0,
        leagueTitle: '',
        eventOrder: week
    };
    const dialogRef = this.dialog.open(CreateEventComponent, {
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = true;
      this.leagueService.getLeague(this.locationLeague.id).subscribe(x => {
        this.locationLeague = x
        this.isLoading = false
      }, err => { console.log(err), () => { } })
    });
  }

  openLeagueEventDetailsDialog(event: any): void {
    const data: LeagueData  = {
        leagueId: this.locationLeague.id,
        leagueTitle: '',
        locationId: 0,
        eventId: event.id,
        eventRoundAssignment: ''
    }
    const dialogRef = this.dialog.open(LeagueEventDetailsComponent, {
      data: data
    })
    dialogRef.afterClosed().subscribe(result => {

    })
  }

}
