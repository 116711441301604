import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-league-team',
  templateUrl: './edit-league-team.component.html',
  styleUrls: ['./edit-league-team.component.css']
})
export class EditLeagueTeamComponent {

}
