<div class="container" style="width:90vw;padding:16px">
  <div class="row">
    <div class="col-sm-10">
      <h2>{{data.leagueTitle}}</h2>
      <h3>NEW TEAM</h3>
    </div>

  </div>
  <div class="row">
    <div class="col-sm-10">
      <form [formGroup]="teamForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <label for="teamName" class="form-label">Team Name:</label>
          <input placeholder="" type="text" id="teamName" class="form-control" formControlName="teamName" />
        </div>

        <div formArrayName="userEmails">
          <label class="form-label">Team Members:</label>
          <div *ngFor="let email of userEmailControls.controls; let i = index" class="mb-3">
            <input placeholder="EMAIL" type="email" class="form-control" formControlName="{{i}}" />
            <a *ngIf="i>0" (click)="removeUserEmail(i)">- REMOVE</a>
          </div>
        </div>

        <div style="margin:16px">
          <a (click)="addUserEmail()">+ PLAYER</a>
        </div>
       
        <button type="submit" class="btn xbtn-primary">REGISTER</button>
      </form>
    </div>
   

  </div>
</div>

