import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditLeagueDialogData, EditLeagueEventDialogData } from '../../interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../../app.component';
import { LeagueComponent } from '../league.component';
import { LeagueServiceService } from '../league-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-league',
  templateUrl: './edit-league.component.html',
  styleUrls: ['./edit-league.component.css']
})
export class EditLeagueComponent implements OnInit {
  locationLeagueForm: FormGroup;

  constructor(private _snackBar: MatSnackBar, private dialogRef: MatDialogRef<LeagueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditLeagueDialogData,
    private formBuilder: FormBuilder,
    private service: LeagueServiceService
    ) {

    this.locationLeagueForm = this.formBuilder.group({
      id: [this.data.id, Validators.required],
      locationId: [this.data.locationId, Validators.required],
      title: [this.data.title, Validators.required],
      description: [this.data.description, Validators.required],
      scoringDescription: [this.data.scoringDescription],
      totalNumberOfWeeks: [this.data.totalNumberOfWeeks, Validators.required],
      startDate: [this.data.startDate, Validators.required],
      isActive: [this.data.isActive, Validators.required],
    });
   
  }

    ngOnInit(): void {
        
    }

  onSubmit() {
    this.service.updateLocalLeague(this.locationLeagueForm.value).subscribe(x => {
      this._snackBar.open('League updated.', '', {
        duration: 2000,
        verticalPosition: "top",
        horizontalPosition: "center",

      });
    }, err => { console.log(err) }, () => { })
    this.dialogRef.close()
  }

}
