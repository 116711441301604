<div *ngIf="!isLoading" class="container" style="height: auto; width: auto; overflow-x: hidden;">
  <div class="">
    <div class="card mb-3">
      <div class="row no-gutters">
        <div class="col-md-12 center">
          <img src="{{selectedTournament.tournamentImageURL}}" class="card-img" alt="Golf Tournament Image" style="height:150px;width:auto">
        </div>
        <div class="col-md-12">
          <div class="card-body">
            <div style="display:flex;justify-content:flex-start">
              <h5 *ngIf="selectedTournament.isTeamEvent" style="color:var(--xgolfBalck)">CREATING TEAM FOR: {{selectedTournament.title}}</h5>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit($event)">
              <input type="hidden" formControlName="tournamentId" [value]="tourId" />
              <!-- ... existing form elements -->
              <div class="mb-3" *ngIf="showDropdown">
                <label for="locationDropdown" class="form-label">Location</label>
                <select class="form-control" id="locationDropdown" formControlName="locationId">
                  <option *ngFor="let location of locations" [value]="location.id">{{location.name}}</option>
                </select>
              </div>

              <div class="mb-3" >
                <label for="teamNameInput" class="form-label">Team Name</label>
                <input type="text" class="form-control" id="teamNameInput" formControlName="teamName" placeholder="Team Name" />
              </div>
              <div formArrayName="emails">
                <div *ngIf="emailsArray?.controls">
                  <div *ngFor="let email of emailsArray.controls; let i = index">
                    <div class="mb-3">
                      <label for="emailInput{{i}}" class="form-label">Email</label>
                      <input type="email" class="form-control" id="emailInput{{i}}" [formControlName]="i" placeholder="Email"/>
                      <button type="button" *ngIf="emailStatus[i] === undefined || emailStatus[i] === false" class="btn btn-warning" (click)="checkEmail(i)">SEARCH USER</button>

                      <div *ngIf="i >= selectedTournament.minTeamMemberCount && selectedTournament.isTeamEvent" class="form-text">Optional</div>
                      <div *ngIf="emailStatus[i] !== undefined">
                        <span *ngIf="emailStatus[i] === true" class="text-success lead">Found</span>
                        <ng-container *ngIf="emailStatus[i] === false" class="text-danger lead">
                          <span class="text-danger">The email entered is not associated with an X-Golf Mobile App account. Please reenter or click <a (click)="sendAppInvite(i)" style="text-decoration:underline">Send Email</a> to invite the customer to download and create their X-Golf Mobile App account.</span>
                        </ng-container>
                      </div>
                      <div *ngIf="emailSearchingStatus[i]" class="text-info lead">
                        <span>Searching...</span>
                        <mat-progress-bar mode="query"></mat-progress-bar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button class="btn btn-sm xbtn-primary" type="submit" >Submit</button>
            </form>

          </div>
        </div>
      </div>
      <div class="row">
        <a (click)="closeDialog()" class="m-3">Cancel</a>
      </div>
    </div>



  </div>
</div>


<div *ngIf="isLoading" class="container">
  <div class="row" style="display:flex;justify-content:center;margin-top:13%">
    <div class="col-sm-12" style="height: 200px; width: 200px;overflow:hidden;">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</div>
